import { CommunicationMethodTypes, Genders, Languages } from '../enums';

export type PatientBalanceInfo = {
  balance: number;
  creditBalance: number;
  payorBalance: number;
  patientBalance: number;
  unallocatedBalance: number;
};

export type PatientConditionReport = {
  ID: number;
  communicationUrl?: string;
  questionnaires?: string;
  summary?: string;
  releaseSummary?: string;
  lastUpdated?: string;
  interimSummaries?: any;
};

export type PatientExercise = {
  active: boolean;
  ID: number;
  nonCR: boolean;
  parentID: number;
  reports: number[];
  phase: number;
  order?: number;
};

export declare type Patient = {
  $avatarBase64?: string;
  accountNumber?: string;
  address1: string;
  address2: string;
  age: number;
  avatar?: string;
  birthday: Date;
  city: string;
  clinic: number;
  completedSurveys?: {
    [key: string]: {
      painLevel: number;
    };
  };
  conditionReports: PatientConditionReport[];
  country?: string;
  createdAt: number | string;
  createUserID: string;
  creationDate?: number | string;
  dateOfBirth: string;
  disabled?: boolean;
  email: string;
  emergencyContactName: string;
  emergencyContactPhone: string;
  employer?: string;
  exercises: PatientExercise[];
  exercisesLastUpdated?: number;
  fileAttachments: any[];
  finishedExercises?: string[];
  fname: string;
  gender: Genders; // BIOLOGICAL SEX
  genderIdentity?: string; // Not necessarily the same as gender.
  googleReviewSendDates?: number[];
  guardian: boolean;
  heardAboutUs?: string;
  heardAboutUsDetail?: string;
  ID: string;
  idNumber: string;
  insuranceCard?: string;
  insuranceCardBack?: string;
  isInCognito: boolean;
  language: Languages;
  lastFirst: string;
  lastPasswordChangeDate: string;
  legalGuardianName?: string;
  legalGuardianRelationship?: string;
  lname: string;
  mname: string;
  occupation: string;
  otherPronouns?: string;
  phone: string;
  photoId?: string;
  photoIdBack?: string;
  pname: string;
  prefers: CommunicationMethodTypes;
  prefix?: string;
  previouslyTreated: boolean;
  primaryCareProvider: string;
  primaryClinician?: string;
  primaryProvider: number;
  primaryProviderLastFirst?: string;
  primaryProviderText?: string;
  profilePhoto?: string | null;
  pronouns?: string;
  raceAndEthnicity?: string;
  searchStr: string;
  state: string;
  status?: string;
  suffix?: string;
  temporaryPassword: string;
  updatedAt?: number | string;
  username: string;
  validationCode?: string; // This is used to validate things once the user is logged in...
  validationCodes?: {
    // This is used to validate the login
    code: number | string;
    expiresAt: number;
  }[];
  unsubscribe?: 1 | 0;
  lastLoginDate?: number;
  updateToken?: string;
  location?: number;
  pending?: boolean;
  optOutSMS?: boolean;
  optOutEmail?: boolean;
  hasActiveInsurance?: boolean;
  pregnant?: boolean;
  carePlan?: any;
  ePayCustomerKey?: string;
  ePayCustomerId?: number;
  socialSecurityNumber?: string;
  lastFour?: number;
  zip: string;
  caseType?: number;
  legacyEHRId?: string;
  legacyEHRIdsMerged?: string[];
};

export const getPatientDisplayName = (patient: Patient) => {
  return `${patient.lname}, ${patient.fname}`;
};
