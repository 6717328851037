/**
 * [2023-10-09.1147 by Brian]
 *
 *                  --- AddCodingDetails ---
 *
 *
 */
import React, { useState } from 'react';
import { CodeSets, ReferenceCodeDisplay } from '@chiroup/core';
import { CodeAutocomplete } from './CodeAutocomplete';
import { Loading } from './Loading';

type Props = {
  value: Partial<any>;
  valueProp?: string;
  patchValue?: (val: Partial<any>) => void;
  onPromote?: (val: ReferenceCodeDisplay) => void;
  isPromoting?: boolean;
  className?: string;
  addClassName?: string;
  endpoint?: string;
  label?: string;
  noneText?: string;
  codeSet?: CodeSets | CodeSets[];
  noCodesMessage?: string;
  compact?: boolean;
  topGutter?: React.ReactNode;
  labelTooltip?: string;
  promoteTitle?: string;
  repeatTitle?: string;
  autoCompleteContainerClassName?: string;
  autoCompleteClassName?: string;
  promoteClassName?: string;
  promoteActiveClassName?: string;
  clinicId: number;
  isBillingStarted?: boolean;
};

export const AddCodingDetails: React.FC<Props> = ({
  value,
  valueProp = 'diagnoses',
  patchValue,
  endpoint,
  label,
  noneText = 'Type to search for available codes...',
  codeSet = [CodeSets.CPT, CodeSets.SERVICE],
  noCodesMessage = 'No codes are associated with this item.',
  compact = false,
  topGutter,
  onPromote,
  promoteTitle,
  repeatTitle,
  autoCompleteContainerClassName,
  autoCompleteClassName,
  promoteClassName,
  promoteActiveClassName,
  isPromoting = false,
  clinicId,
  isBillingStarted = false,
}) => {
  const [search, setSearch] = useState('');
  label =
    label ||
    (Array.isArray(codeSet)
      ? 'Associated Billing Codes'
      : 'Associated Diagnostic Codes');
  const [onDeck, setOnDeck] = useState<{
    codeSet: CodeSets | undefined;
    code: string;
    desc: string;
    repeat: boolean;
  }>({
    codeSet: undefined,
    code: '',
    desc: '',
    repeat: false,
  });

  const upArrow = () => {
    return (
      <div className="pt-2 pr-3">
        {isPromoting ? (
          <div>
            <Loading color="text-gray-400" className="animate-spin h-5 w-5" />
          </div>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        )}
      </div>
    );
  };

  /**
   * There are two use cases:
   *
   * 1) The value is a single object with an array of codes in it.
   * 2) The value is already an array with codes in it.
   *
   * @returns
   */
  const getCodeArray = () => {
    if (value && Array.isArray(value)) return value;
    if (value && value[valueProp]) return value[valueProp];
    return [];
  };

  const handleClick = (e: any) => {
    // const existingCodes = getCodeArray().reduce(
    //   (acc: any, curr: any) => ({ ...acc, [curr.code]: curr }),
    //   {},
    // );
    // const repeat = !!existingCodes[onDeck.code];

    // if (!repeat) {
    if (patchValue) {
      patchValue(onDeck);
    }

    if (onPromote) {
      onPromote({
        codeSet: onDeck.codeSet,
        code: onDeck.code,
        description: onDeck.desc,
      });
    }

    setOnDeck({
      codeSet: onDeck.codeSet,
      code: '',
      desc: '',
      repeat: false,
    });
    setSearch('');
  };

  return (
    <>
      {topGutter}
      <div className="w-full">
        <div className={compact ? 'py-3' : 'py-2'}>
          {getCodeArray().length === 0 && (
            <cite className={'text-sm text-gray-400 col-span-10 p-2 ml-2'}>
              {noCodesMessage}
            </cite>
          )}
        </div>
        <div
          className={
            autoCompleteContainerClassName ||
            'rounded-md flex flex-row space-x-4 bg-white w-full'
          }
        >
          <div className="grow w-24">
            <CodeAutocomplete
              endpoint={endpoint}
              search={search}
              setSearch={setSearch}
              value={onDeck.code as string}
              weighted={true}
              className={autoCompleteClassName || `ml-2 pb-2`}
              noneText={noneText}
              onChange={(val: string) => {
                // const existingCodes = getCodeArray().reduce(
                //     (acc: any, curr: any) => {
                //       return { ...acc, [curr.code]: curr };
                //     },
                //     {},
                //   ),
                // repeat =  false
                setOnDeck((p) => ({ ...p, code: val, repeat: false }));
              }}
              codeSet={codeSet}
              onChangeDescription={(val: any) => {
                const desc = val?.data?.description || '',
                  codeSet = val?.data?.codeSet || '';
                setOnDeck((p) => ({ ...p, desc, codeSet }));
              }}
              descriptionAsString={false}
              clinicId={clinicId}
            />
          </div>
          {onDeck.code ? (
            <div
              className={
                (onDeck.repeat
                  ? 'text-red-500'
                  : isBillingStarted
                    ? 'text-accent-500'
                    : 'text-primary-500') +
                ' ' +
                (promoteActiveClassName || 'cursor-pointer')
              }
              title={
                onDeck.repeat
                  ? repeatTitle || 'Code already associated with condition.'
                  : promoteTitle || 'Associate this code with the condition.'
              }
              onClick={handleClick}
            >
              {upArrow()}
            </div>
          ) : (
            <div
              className={promoteClassName || 'text-gray-300 cursor-pointer'}
              title="Select a code from the left to activate this button."
            >
              {upArrow()}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
