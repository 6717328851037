import React from 'react';
import Alert, { AlertIcons } from '../common/Alert';

type Props = {
  styles?: string;
};

const ValidEmailAlert: React.FC<Props> = ({ styles }) => {
  return (
    <Alert className={styles} icon={AlertIcons.info}>
      Hmm, this email doesn’t look right.{' '}
      <span role="img" aria-label="Thinking face">
        &#129300;
      </span>{' '}
      Please confirm you’ve entered the correct address.
    </Alert>
  );
};

export default ValidEmailAlert;
