import {
  AppointmentInsuranceType,
  PatientTransactionItemType,
} from '@chiroup/core';
import { MagicAction, MagicActionType } from './commonMagic';
import { serviceAutoPopulateMedicareModifier } from './autoPopulateMedicareModifier';

export enum ServiceMagicEvent {
  onAddSingleService = 0,
}

export type ServiceMagiceResponseType = {
  service: PatientTransactionItemType;
  actions: MagicActionType[];
  touched: boolean;
};

const serviceSpells = {
  [ServiceMagicEvent.onAddSingleService]: [serviceAutoPopulateMedicareModifier],
};

type baseServiceMagicType = {
  service: PatientTransactionItemType | undefined | null;
  insurances: AppointmentInsuranceType[];
};
export const serviceMagic = ({
  service,
  insurances,
  event,
  onEntry = null,
  onExit = null,
  trace = false,
}: baseServiceMagicType & {
  event: ServiceMagicEvent;
  onEntry?: (({ service, insurances }: baseServiceMagicType) => void) | null;
  onExit?: (({ service, insurances }: baseServiceMagicType) => void) | null;
  trace?: boolean;
}) => {
  if (!service) {
    return {
      actions: [
        {
          message: `No actions possible without a service.`,
          type: MagicAction.Error,
        },
      ],
      service: null,
    };
  }
  const actions: MagicActionType[] = [],
    magicResponses: {
      [key: string]: ServiceMagiceResponseType[];
    } = {};

  onEntry?.({ service, insurances });
  if (trace) {
    console.log({ service, insurances, event });
  }

  if (serviceSpells[event]) {
    magicResponses[event] = magicResponses[event] || [];
    serviceSpells[event].forEach((magic) => {
      try {
        magicResponses[event].push(magic({ service, insurances }));
      } catch (e: any) {
        console.error(e);
        actions.push({
          message: `Error: ${e.message}`,
          type: MagicAction.Error,
        });
      }
    });
  } else {
    actions.push({
      message: 'No available spells for this event.',
      type: MagicAction.Warning,
    });
  }

  let touched = false;
  Object.keys(magicResponses).forEach((key) => {
    magicResponses[key].forEach((response) => {
      if (response.touched) {
        touched = true;
      }
      actions.push(...response.actions);
    });
  });
  onExit?.({ service, insurances });
  console.log(actions);
  return { actions, service, touched };
};
