import { Form } from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';
import { CustomizationListItem } from '../components/clinical-skills/CustomizationListItem.type';

export type FormsObj = { [key: string]: Form[] };
export type FormHistory = {
  ID: number;
  exercises: string;
  name: string;
  userId: string;
};
export type Attachment = {
  url: string;
  name: string;
};

const formService = () => {
  const list = async () => {
    const res: Form[] = await ChiroUpAPI.get('api', `/form`, {});
    const tabNames: {
      [key: string]: string;
    } = {};
    const formsObj = res?.reduce((obj: FormsObj, item: Form) => {
      const type = item.type.toLowerCase().replace(' ', '-');
      obj[type] = obj[type] || [];
      obj[type].push(item);
      tabNames[type] = item.type;
      return obj;
    }, {});
    return { data: formsObj, tabNames };
  };

  const history = async (selectedClinic?: number): Promise<FormHistory[]> => {
    return ChiroUpAPI.get('api', `/${selectedClinic}/form/history`, {});
  };

  const generateExerciseMenu = async (
    selectedClinic?: number,
    exercises?: (CustomizationListItem | undefined)[],
    name?: string,
    save?: boolean,
  ) => {
    return ChiroUpAPI.post(
      'api',
      `/${selectedClinic}/form/custom/exercise-menu`,
      { body: { exercises, name, save } },
    );
  };

  const generateConditionMenu = async (
    selectedClinic?: number,
    regions?: string[],
  ) => {
    return ChiroUpAPI.get(
      'api',
      `/${selectedClinic}/form/condition-menu?regions=${regions?.join(',')}`,
      {},
    );
  };

  const generateConditionMenuFull = async (selectedClinic?: number) => {
    return ChiroUpAPI.get('api', `/form?conditionFullMenu=1`, {});
  };

  const getAttachments = async (
    selectedClinic?: number,
    userId?: string,
  ): Promise<Attachment[]> => {
    return ChiroUpAPI.get('api', `/${selectedClinic}/attachments`, {});
  };

  const initiateUploadAttachment = async (selectedClinic?: number) => {
    return ChiroUpAPI.post('api', `/settings/${selectedClinic}/file/pdf`, {
      body: { contentType: 'application/pdf' },
    });
  };

  const updateAttachments = async (
    selectedClinic?: number,
    crAttachments?: Attachment[],
  ) => {
    return ChiroUpAPI.put('api', `/settings/clinics/${selectedClinic}`, {
      body: {
        ID: selectedClinic,
        crAttachments: JSON.stringify(crAttachments),
      },
    });
  };

  const deleteAttachment = async (selectedClinic: number, url: string) => {
    return ChiroUpAPI.post('api', `/${selectedClinic}/attachments/delete`, {
      body: { url },
    });
  };

  const addConsent = async (
    selectedClinic?: number,
    title?: string,
    url?: string,
  ) => {
    return ChiroUpAPI.post('api', `/${selectedClinic}/consent`, {
      body: {
        title,
        url,
      },
    });
  };

  const removeConsent = async (selectedClinic: number, url: string) => {
    return ChiroUpAPI.post('api', `/${selectedClinic}/consent/delete`, {
      body: { url },
    });
  };

  const listConsents = async (selectedClinic?: number) => {
    return ChiroUpAPI.get('api', `/${selectedClinic}/consents`, {});
  };

  const updateConsent = async (value?: any) => {
    return ChiroUpAPI.post('api', `/consents/update`, { body: value });
  };

  return {
    list,
    history,
    generateExerciseMenu,
    generateConditionMenuFull,
    generateConditionMenu,
    initiateUploadAttachment,
    updateAttachments,
    getAttachments,
    deleteAttachment,
    addConsent,
    removeConsent,
    listConsents,
    updateConsent,
  };
};

export default formService();
