import React from 'react';
import Button, { ButtonColors } from '../common/Button';

type Props = {
  checkIn: () => void;
  disabled?: boolean;
  loading?: boolean;
};

const ScheduleAppointmentCheckIn: React.FC<Props> = ({
  checkIn,
  disabled = false,
  loading = false,
}) => {
  return (
    <Button
      color={ButtonColors.primary}
      text="Check In"
      onClick={checkIn}
      disabled={disabled}
      loading={loading}
    />
  );
};

export default ScheduleAppointmentCheckIn;
