import { Select } from '@chiroup/components';
import { FormError } from '@chiroup/core';
import React, { useContext, useMemo } from 'react';
import { MeContext } from '../../../../contexts/me.context';

type Props = {
  name: string;
  value?: any;
  onChange: (val: any) => void;
  errors?: FormError;
  className?: string;
  label?: string;
  limit?: number;
  hint?: string;
  style?: React.CSSProperties;
  clearable?: boolean;
  onClear?: () => void;
  disabled?: boolean;
  tooltip?: string;
  removeOptions?: string[];
};

const ClinicianSelect: React.FC<Props> = ({
  name,
  value,
  onChange,
  errors,
  className = 'col-span-2 sm:col-span-1',
  label,
  limit,
  hint,
  style,
  clearable = false,
  onClear,
  disabled,
  tooltip,
  removeOptions,
}) => {
  const { me } = useContext(MeContext);

  const options = useMemo(() => {
    const optionsToReturn = me.selectedClinic?.clinicians?.map((clinician) => ({
      text: clinician.name,
      value: clinician.ID,
    }));
    return optionsToReturn?.filter(
      (option) => !removeOptions?.includes(option.value),
    );
  }, [me.selectedClinic?.clinicians, removeOptions]);

  return (
    <Select
      name={name}
      className={className}
      label={label}
      showLabel={!!label}
      value={value}
      onChange={onChange}
      errors={errors}
      options={options}
      limit={limit}
      hint={hint}
      style={style}
      clearable={clearable}
      onClear={onClear}
      disabled={disabled}
      tooltip={tooltip}
    />
  );
};

export default ClinicianSelect;
