export const SuffixOptions: {
  text: string;
  value: string;
}[] = [
  {
    text: 'I',
    value: 'I',
  },
  {
    text: 'II',
    value: 'II',
  },
  {
    text: 'III',
    value: 'III',
  },
  {
    text: 'IV',
    value: 'IV',
  },
  {
    text: 'Jr',
    value: 'Jr',
  },
  {
    text: 'Sr',
    value: 'Sr',
  },
];
