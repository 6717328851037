import { ClinicBillingSettings } from '@chiroup/core';
import { useContext } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import settingsService from '../../../services/settings.service';

const getSettingsQuery = (clinicId: number) => {
  return async (context: QueryFunctionContext) => {
    return settingsService.getBillingSettings(clinicId);
  };
};

const useBillingSettings = () => {
  const { me } = useContext(MeContext);
  const queryClient = useQueryClient();

  const { data, error, isFetching } = useQuery<ClinicBillingSettings>(
    ['billingSettings', me.selectedClinic?.ID],
    getSettingsQuery(me.selectedClinic?.ID ?? -1),
    {
      refetchOnWindowFocus: false,
    },
  );

  const save = async (settings: Partial<ClinicBillingSettings>) => {
    const res = await settingsService.saveBillingSettings(
      settings,
      me.selectedClinic?.ID,
    );

    if (res) {
      queryClient.invalidateQueries(['billingSettings', me.selectedClinic?.ID]);
    }
  };

  return {
    data,
    error,
    isFetching,
    save,
  };
};

export default useBillingSettings;
