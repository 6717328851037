/**
 * [2023-08-29.1034 by Brian]
 *
 * This contains functions to cleanup input values in change events
 * associated with the useForm. Example:
 *
 *    onChange={(val: string) => {
 *       onChange('amount')(TidyInputValue.Currency(val))
 *    }}
 *
 * For the most part, InputMasked and InputMaskedOptions are the
 * way to go, but this may be of some use down-the-road and it
 * is already written.
 */
export const TidyInputValue = {
  /**
   * Behavior is just taking numbers. Everything else is stripped.
   * The string of numbers is split to parts. While typing, it
   * will look like:
   *
   *     0.01
   *     0.12
   *     1.23
   *    12.34
   *  1234.00
   *      etc...
   *
   * Assuming the user types a 1, 2, 3, 4, 0 then 0.
   *
   * @param val
   * @returns
   */
  Currency: (val: any) => {
    if (val) {
      if (typeof val === 'number') {
        val = val.toFixed(2);
      }
      const clean = val.replace(/[^0-9]/g, '').replace(/^0+/, '');
      const parts = clean.split('');
      const len = parts.length;

      if (len === 1) parts.unshift('0', '0');
      else if (len === 2) parts.unshift('0');

      parts.splice(-2, 0, '.');
      return clean ? parts.join('') : null;
    }
  },
  /**
   * Only takes the LAST TWO characters types. On the third
   * or more characters are added, only the last two are the
   * value.
   *
   *   A    => A
   *   AB   => AB
   *   ABC  => BC
   *   ABCD => CD
   *
   * @param val
   * @returns
   */
  BillingCodeModifier: (val: any) => {
    if (val) {
      const clean = val
        .toUpperCase()
        .replace(/[^0-9a-zA-Z]/g, '')
        .slice(-2);
      return clean ? clean : null;
    }
  },
  /**
   * Mostly just alpha-numeric, but also allows a few special characters.
   * It's a big keyboard and seems silly. It is 255 chars long as of this
   * comment.
   *
   * @param val
   * @returns
   */
  BillingCodeShortcut: (val: any) => {
    if (val) {
      const clean = val.replace(/[^0-9a-zA-Z._-]/g, '').slice(-255);
      return clean ? clean : null;
    }
  },
  /**
   * Similar to the reference codes, with the addition of a hyphen.
   * Field width is 50 chars.
   *
   * @param val
   * @returns
   */
  BillingCode: (val: any) => {
    if (val) {
      const clean = val
        .toUpperCase()
        .replace(/[^0-9A-Z-]/g, '')
        .slice(-50);
      return clean ? clean : null;
    }
  },
  /**
   * Anything, but limit it to 255 chars.
   *
   * @param val
   * @returns
   */
  Description: (val: any) => {
    if (val) {
      const clean = val.substr(0, 255);
      return clean ? clean : null;
    }
  },
};
