import { Macro } from '@chiroup/core';
import { useMemo } from 'react';
import sanitize from 'sanitize-html';

const useMacros = (value?: string, macros?: Partial<Macro>[]) => {
  const searchText = useMemo(() => {
    if (typeof value === 'string' && value.includes('/') && macros?.length) {
      const sanitizedValue = sanitize(value, { allowedTags: [] });
      const words = sanitizedValue.replace(/\n/g, ' ').split(' ');
      const lastSlashWord = words
        .reverse()
        .find((word) => word.startsWith('/'));

      if (lastSlashWord) {
        return lastSlashWord.slice(1);
      }
    }
    return null;
  }, [macros?.length, value]);

  const filteredMacros = useMemo(() => {
    if (
      typeof value === 'string' &&
      value.includes('/') &&
      macros?.length &&
      searchText
    ) {
      return macros?.filter(
        (macro) => macro.shortcut?.includes(searchText.trim()),
      );
    }
    return macros || [];
  }, [value, macros, searchText]);

  return {
    filteredMacros,
    searchText,
  };
};

export default useMacros;
