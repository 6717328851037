export const COUNTRIES = [
  { text: 'United States of America', value: 'USA', countryCode: '+1' },
  { text: 'Afghanistan', value: 'AFG', countryCode: '+93' },
  { text: 'Åland Islands', value: 'ALA', countryCode: '+358' },
  { text: 'Albania', value: 'ALB', countryCode: '+355' },
  { text: 'Algeria', value: 'DZA', countryCode: '+213' },
  { text: 'American Samoa', value: 'ASM', countryCode: '+1-684' },
  { text: 'Andorra', value: 'AND', countryCode: '+376' },
  { text: 'Angola', value: 'AGO', countryCode: '+244' },
  { text: 'Anguilla', value: 'AIA', countryCode: '+1-264' },
  { text: 'Antarctica', value: 'ATA', countryCode: '+672' },
  { text: 'Antigua and Barbuda', value: 'ATG', countryCode: '+1-268' },
  { text: 'Argentina', value: 'ARG', countryCode: '+54' },
  { text: 'Armenia', value: 'ARM', countryCode: '+374' },
  { text: 'Aruba', value: 'ABW', countryCode: '+297' },
  { text: 'Australia', value: 'AUS', countryCode: '+61' },
  { text: 'Austria', value: 'AUT', countryCode: '+43' },
  { text: 'Azerbaijan', value: 'AZE', countryCode: '+994' },
  { text: 'Bahamas', value: 'BHS', countryCode: '+1-242' },
  { text: 'Bahrain', value: 'BHR', countryCode: '+973' },
  { text: 'Bangladesh', value: 'BGD', countryCode: '+880' },
  { text: 'Barbados', value: 'BRB', countryCode: '+1-246' },
  { text: 'Belarus', value: 'BLR', countryCode: '+375' },
  { text: 'Belgium', value: 'BEL', countryCode: '+32' },
  { text: 'Belize', value: 'BLZ', countryCode: '+501' },
  { text: 'Benin', value: 'BEN', countryCode: '+229' },
  { text: 'Bermuda', value: 'BMU', countryCode: '+1-441' },
  { text: 'Bhutan', value: 'BTN', countryCode: '+975' },
  { text: 'Bolivia', value: 'BOL', countryCode: '+591' },
  {
    text: 'Bonaire, Sint Eustatius and Saba',
    value: 'BES',
    countryCode: '+599',
  },
  { text: 'Bosnia and Herzegovina', value: 'BIH', countryCode: '+387' },
  { text: 'Botswana', value: 'BWA', countryCode: '+267' },
  { text: 'Bouvet Island', value: 'BVT', countryCode: '+47' },
  { text: 'Brazil', value: 'BRA', countryCode: '+55' },
  { text: 'British Indian Ocean Territory', value: 'IOT', countryCode: '+246' },
  { text: 'Brunei Darussalam', value: 'BRN', countryCode: '+673' },
  { text: 'Bulgaria', value: 'BGR', countryCode: '+359' },
  { text: 'Burkina Faso', value: 'BFA', countryCode: '+226' },
  { text: 'Burundi', value: 'BDI', countryCode: '+257' },
  { text: 'Cabo Verde', value: 'CPV', countryCode: '+238' },
  { text: 'Cambodia', value: 'KHM', countryCode: '+855' },
  { text: 'Cameroon', value: 'CMR', countryCode: '+237' },
  { text: 'Canada', value: 'CAN', countryCode: '+1' },
  { text: 'Cayman Islands', value: 'CYM', countryCode: '+1-345' },
  { text: 'Central African Republic', value: 'CAF', countryCode: '+236' },
  { text: 'Chad', value: 'TCD', countryCode: '+235' },
  { text: 'Chile', value: 'CHL', countryCode: '+56' },
  { text: 'China', value: 'CHN', countryCode: '+86' },
  { text: 'Christmas Island', value: 'CXR', countryCode: '+61' },
  { text: 'Cocos (Keeling) Islands', value: 'CCK', countryCode: '+61' },
  { text: 'Colombia', value: 'COL', countryCode: '+57' },
  { text: 'Comoros', value: 'COM', countryCode: '+269' },
  { text: 'Congo', value: 'COG', countryCode: '+242' },
  {
    text: 'Congo (Democratic Republic of the)',
    value: 'COD',
    countryCode: '+243',
  },
  { text: 'Cook Islands', value: 'COK', countryCode: '+682' },
  { text: 'Costa Rica', value: 'CRI', countryCode: '+506' },
  { text: "Côte d'Ivoire", value: 'CIV', countryCode: '+225' },
  { text: 'Croatia', value: 'HRV', countryCode: '+385' },
  { text: 'Cuba', value: 'CUB', countryCode: '+53' },
  { text: 'Curaçao', value: 'CUW', countryCode: '+599' },
  { text: 'Cyprus', value: 'CYP', countryCode: '+357' },
  { text: 'Czechia', value: 'CZE', countryCode: '+420' },
  { text: 'Denmark', value: 'DNK', countryCode: '+45' },
  { text: 'Djibouti', value: 'DJI', countryCode: '+253' },
  { text: 'Dominica', value: 'DMA', countryCode: '+1-767' },
  { text: 'Dominican Republic', value: 'DOM', countryCode: '+1-809' }, // and +1-829, +1-849
  { text: 'Ecuador', value: 'ECU', countryCode: '+593' },
  { text: 'Egypt', value: 'EGY', countryCode: '+20' },
  { text: 'El Salvador', value: 'SLV', countryCode: '+503' },
  { text: 'Equatorial Guinea', value: 'GNQ', countryCode: '+240' },
  { text: 'Eritrea', value: 'ERI', countryCode: '+291' },
  { text: 'Estonia', value: 'EST', countryCode: '+372' },
  { text: 'Ethiopia', value: 'ETH', countryCode: '+251' },
  { text: 'Falkland Islands (Malvinas)', value: 'FLK', countryCode: '+500' },
  { text: 'Faroe Islands', value: 'FRO', countryCode: '+298' },
  { text: 'Fiji', value: 'FJI', countryCode: '+679' },
  { text: 'Finland', value: 'FIN', countryCode: '+358' },
  { text: 'France', value: 'FRA', countryCode: '+33' },
  { text: 'French Guiana', value: 'GUF', countryCode: '+594' },
  { text: 'French Polynesia', value: 'PYF', countryCode: '+689' },
  { text: 'French Southern Territories', value: 'ATF', countryCode: '+262' },
  { text: 'Gabon', value: 'GAB', countryCode: '+241' },
  { text: 'Gambia', value: 'GMB', countryCode: '+220' },
  { text: 'Georgia', value: 'GEO', countryCode: '+995' },
  { text: 'Germany', value: 'DEU', countryCode: '+49' },
  { text: 'Ghana', value: 'GHA', countryCode: '+233' },
  { text: 'Gibraltar', value: 'GIB', countryCode: '+350' },
  { text: 'Greece', value: 'GRC', countryCode: '+30' },
  { text: 'Greenland', value: 'GRL', countryCode: '+299' },
  { text: 'Grenada', value: 'GRD', countryCode: '+1-473' },
  { text: 'Guadeloupe', value: 'GLP', countryCode: '+590' },
  { text: 'Guam', value: 'GUM', countryCode: '+1-671' },
  { text: 'Guatemala', value: 'GTM', countryCode: '+502' },
  { text: 'Guernsey', value: 'GGY', countryCode: '+44-1481' },
  { text: 'Guinea', value: 'GIN', countryCode: '+224' },
  { text: 'Guinea-Bissau', value: 'GNB', countryCode: '+245' },
  { text: 'Guyana', value: 'GUY', countryCode: '+592' },
  { text: 'Haiti', value: 'HTI', countryCode: '+509' },
  { text: 'Honduras', value: 'HND', countryCode: '+504' },
  { text: 'Hong Kong', value: 'HKG', countryCode: '+852' },
  { text: 'Hungary', value: 'HUN', countryCode: '+36' },
  { text: 'Iceland', value: 'ISL', countryCode: '+354' },
  { text: 'India', value: 'IND', countryCode: '+91' },
  { text: 'Indonesia', value: 'IDN', countryCode: '+62' },
  { text: 'Iran (Islamic Republic of)', value: 'IRN', countryCode: '+98' },
  { text: 'Iraq', value: 'IRQ', countryCode: '+964' },
  { text: 'Ireland', value: 'IRL', countryCode: '+353' },
  { text: 'Isle of Man', value: 'IMN', countryCode: '+44-1624' },
  { text: 'Israel', value: 'ISR', countryCode: '+972' },
  { text: 'Italy', value: 'ITA', countryCode: '+39' },
  { text: 'Jamaica', value: 'JAM', countryCode: '+1-876' },
  { text: 'Japan', value: 'JPN', countryCode: '+81' },
  { text: 'Jersey', value: 'JEY', countryCode: '+44-1534' },
  { text: 'Jordan', value: 'JOR', countryCode: '+962' },
  { text: 'Kazakhstan', value: 'KAZ', countryCode: '+7' },
  { text: 'Kenya', value: 'KEN', countryCode: '+254' },
  { text: 'Kiribati', value: 'KIR', countryCode: '+686' },
  {
    text: "Korea (Democratic People's Republic of)",
    value: 'PRK',
    countryCode: '+850',
  },
  { text: 'Korea (Republic of)', value: 'KOR', countryCode: '+82' },
  { text: 'Kosovo', value: 'XKX', countryCode: '+383' },
  { text: 'Kuwait', value: 'KWT', countryCode: '+965' },
  { text: 'Kyrgyzstan', value: 'KGZ', countryCode: '+996' },
  {
    text: "Lao People's Democratic Republic",
    value: 'LAO',
    countryCode: '+856',
  },
  { text: 'Latvia', value: 'LVA', countryCode: '+371' },
  { text: 'Lebanon', value: 'LBN', countryCode: '+961' },
  { text: 'Lesotho', value: 'LSO', countryCode: '+266' },
  { text: 'Liberia', value: 'LBR', countryCode: '+231' },
  { text: 'Libya', value: 'LBY', countryCode: '+218' },
  { text: 'Liechtenstein', value: 'LIE', countryCode: '+423' },
  { text: 'Lithuania', value: 'LTU', countryCode: '+370' },
  { text: 'Luxembourg', value: 'LUX', countryCode: '+352' },
  { text: 'Macau', value: 'MAC', countryCode: '+853' },
  {
    text: 'Macedonia (the former Yugoslav Republic of)',
    value: 'MKD',
    countryCode: '+389',
  },
  { text: 'Madagascar', value: 'MDG', countryCode: '+261' },
  { text: 'Malawi', value: 'MWI', countryCode: '+265' },
  { text: 'Malaysia', value: 'MYS', countryCode: '+60' },
  { text: 'Maldives', value: 'MDV', countryCode: '+960' },
  { text: 'Mali', value: 'MLI', countryCode: '+223' },
  { text: 'Malta', value: 'MLT', countryCode: '+356' },
  { text: 'Marshall Islands', value: 'MHL', countryCode: '+692' },
  { text: 'Martinique', value: 'MTQ', countryCode: '+596' },
  { text: 'Mauritania', value: 'MRT', countryCode: '+222' },
  { text: 'Mauritius', value: 'MUS', countryCode: '+230' },
  { text: 'Mayotte', value: 'MYT', countryCode: '+262' },
  { text: 'Mexico', value: 'MEX', countryCode: '+52' },
  {
    text: 'Micronesia (Federated States of)',
    value: 'FSM',
    countryCode: '+691',
  },
  { text: 'Moldova (Republic of)', value: 'MDA', countryCode: '+373' },
  { text: 'Monaco', value: 'MCO', countryCode: '+377' },
  { text: 'Mongolia', value: 'MNG', countryCode: '+976' },
  { text: 'Montenegro', value: 'MNE', countryCode: '+382' },
  { text: 'Montserrat', value: 'MSR', countryCode: '+1-664' },
  { text: 'Morocco', value: 'MAR', countryCode: '+212' },
  { text: 'Mozambique', value: 'MOZ', countryCode: '+258' },
  { text: 'Myanmar', value: 'MMR', countryCode: '+95' },
  { text: 'Namibia', value: 'NAM', countryCode: '+264' },
  { text: 'Nauru', value: 'NRU', countryCode: '+674' },
  { text: 'Nepal', value: 'NPL', countryCode: '+977' },
  { text: 'Netherlands', value: 'NLD', countryCode: '+31' },
  { text: 'New Caledonia', value: 'NCL', countryCode: '+687' },
  { text: 'New Zealand', value: 'NZL', countryCode: '+64' },
  { text: 'Nicaragua', value: 'NIC', countryCode: '+505' },
  { text: 'Niger', value: 'NER', countryCode: '+227' },
  { text: 'Nigeria', value: 'NGA', countryCode: '+234' },
  { text: 'Niue', value: 'NIU', countryCode: '+683' },
  { text: 'Norfolk Island', value: 'NFK', countryCode: '+672' },
  { text: 'Northern Mariana Islands', value: 'MNP', countryCode: '+1-670' },
  { text: 'Norway', value: 'NOR', countryCode: '+47' },
  { text: 'Oman', value: 'OMN', countryCode: '+968' },
  { text: 'Pakistan', value: 'PAK', countryCode: '+92' },
  { text: 'Palau', value: 'PLW', countryCode: '+680' },
  { text: 'Palestine, State of', value: 'PSE', countryCode: '+970' },
  { text: 'Panama', value: 'PAN', countryCode: '+507' },
  { text: 'Papua New Guinea', value: 'PNG', countryCode: '+675' },
  { text: 'Paraguay', value: 'PRY', countryCode: '+595' },
  { text: 'Peru', value: 'PER', countryCode: '+51' },
  { text: 'Philippines', value: 'PHL', countryCode: '+63' },
  { text: 'Pitcairn', value: 'PCN', countryCode: '+64' },
  { text: 'Poland', value: 'POL', countryCode: '+48' },
  { text: 'Portugal', value: 'PRT', countryCode: '+351' },
  { text: 'Puerto Rico', value: 'PRI', countryCode: '+1-787' }, // and +1-939
  { text: 'Qatar', value: 'QAT', countryCode: '+974' },
  { text: 'Réunion', value: 'REU', countryCode: '+262' },
  { text: 'Romania', value: 'ROU', countryCode: '+40' },
  { text: 'Russia', value: 'RUS', countryCode: '+7' },
  { text: 'Rwanda', value: 'RWA', countryCode: '+250' },
  { text: 'Saint Barthélemy', value: 'BLM', countryCode: '+590' },
  {
    text: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SHN',
    countryCode: '+290',
  },
  { text: 'Saint Kitts and Nevis', value: 'KNA', countryCode: '+1-869' },
  { text: 'Saint Lucia', value: 'LCA', countryCode: '+1-758' },
  { text: 'Saint Martin (French part)', value: 'MAF', countryCode: '+590' },
  { text: 'Saint Pierre and Miquelon', value: 'SPM', countryCode: '+508' },
  {
    text: 'Saint Vincent and the Grenadines',
    value: 'VCT',
    countryCode: '+1-784',
  },
  { text: 'Samoa', value: 'WSM', countryCode: '+685' },
  { text: 'San Marino', value: 'SMR', countryCode: '+378' },
  { text: 'Sao Tome and Principe', value: 'STP', countryCode: '+239' },
  { text: 'Saudi Arabia', value: 'SAU', countryCode: '+966' },
  { text: 'Senegal', value: 'SEN', countryCode: '+221' },
  { text: 'Serbia', value: 'SRB', countryCode: '+381' },
  { text: 'Seychelles', value: 'SYC', countryCode: '+248' },
  { text: 'Sierra Leone', value: 'SLE', countryCode: '+232' },
  { text: 'Singapore', value: 'SGP', countryCode: '+65' },
  { text: 'Sint Maarten (Dutch part)', value: 'SXM', countryCode: '+1-721' },
  { text: 'Slovakia', value: 'SVK', countryCode: '+421' },
  { text: 'Slovenia', value: 'SVN', countryCode: '+386' },
  { text: 'Solomon Islands', value: 'SLB', countryCode: '+677' },
  { text: 'Somalia', value: 'SOM', countryCode: '+252' },
  { text: 'South Africa', value: 'ZAF', countryCode: '+27' },
  {
    text: 'South Georgia and the South Sandwich Islands',
    value: 'SGS',
    countryCode: '+500',
  },
  { text: 'South Sudan', value: 'SSD', countryCode: '+211' },
  { text: 'Spain', value: 'ESP', countryCode: '+34' },
  { text: 'Sri Lanka', value: 'LKA', countryCode: '+94' },
  { text: 'Sudan', value: 'SDN', countryCode: '+249' },
  { text: 'Suriname', value: 'SUR', countryCode: '+597' },
  { text: 'Svalbard and Jan Mayen', value: 'SJM', countryCode: '+47' },
  { text: 'Sweden', value: 'SWE', countryCode: '+46' },
  { text: 'Swaziland', value: 'SWZ', countryCode: '+268' },
  { text: 'Switzerland', value: 'CHE', countryCode: '+41' },
  { text: 'Syrian Arab Republic', value: 'SYR', countryCode: '+963' },
  { text: 'Taiwan, Province of China', value: 'TWN', countryCode: '+886' },
  { text: 'Tajikistan', value: 'TJK', countryCode: '+992' },
  { text: 'Tanzania, United Republic of', value: 'TZA', countryCode: '+255' },
  { text: 'Thailand', value: 'THA', countryCode: '+66' },
  { text: 'Timor-Leste', value: 'TLS', countryCode: '+670' },
  { text: 'Togo', value: 'TGO', countryCode: '+228' },
  { text: 'Tokelau', value: 'TKL', countryCode: '+690' },
  { text: 'Tonga', value: 'TON', countryCode: '+676' },
  { text: 'Trinidad and Tobago', value: 'TTO', countryCode: '+1-868' },
  { text: 'Tunisia', value: 'TUN', countryCode: '+216' },
  { text: 'Turkey', value: 'TUR', countryCode: '+90' },
  { text: 'Turkmenistan', value: 'TKM', countryCode: '+993' },
  { text: 'Turks and Caicos Islands', value: 'TCA', countryCode: '+1-649' },
  { text: 'Tuvalu', value: 'TUV', countryCode: '+688' },
  { text: 'Uganda', value: 'UGA', countryCode: '+256' },
  { text: 'Ukraine', value: 'UKR', countryCode: '+380' },
  { text: 'United Arab Emirates', value: 'ARE', countryCode: '+971' },
  {
    text: 'United Kingdom of Great Britain and Northern Ireland',
    value: 'GBR',
    countryCode: '+44',
  },
  {
    text: 'United States Minor Outlying Islands',
    value: 'UMI',
    countryCode: '+1',
  },
  { text: 'Uruguay', value: 'URY', countryCode: '+598' },
  { text: 'Uzbekistan', value: 'UZB', countryCode: '+998' },
  { text: 'Vanuatu', value: 'VUT', countryCode: '+678' },
  { text: 'Vatican City', value: 'VAT', countryCode: '+379' },
  {
    text: 'Venezuela (Bolivarian Republic of)',
    value: 'VEN',
    countryCode: '+58',
  },
  { text: 'Vietnam', value: 'VNM', countryCode: '+84' },
  { text: 'Virgin Islands (British)', value: 'VGB', countryCode: '+1-284' },
  { text: 'Virgin Islands (U.S.)', value: 'VIR', countryCode: '+1-340' },
  { text: 'Wallis and Futuna', value: 'WLF', countryCode: '+681' },
  { text: 'Western Sahara', value: 'ESH', countryCode: '+212' },
  { text: 'Yemen', value: 'YEM', countryCode: '+967' },
  { text: 'Zambia', value: 'ZMB', countryCode: '+260' },
  { text: 'Zimbabwe', value: 'ZWE', countryCode: '+263' },
];

export const COUNTRY_CODES = COUNTRIES.reduce((obj: any, country) => {
  obj[country.value] = country.countryCode;
  return obj;
}, {});
