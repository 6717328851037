import { titleCase } from '@chiroup/core';
import React from 'react';
import { Tooltip } from 'react-tooltip';

const CustomCaseBadge: React.FC<{
  tooltip: string;
  height?: number;
  width?: number;
}> = ({ tooltip, height = 28, width = 28 }) => {
  return (
    <>
      <div
        data-tooltip-content={`${titleCase(tooltip)}`}
        data-tooltip-id="custom-badge-tooltip"
        style={{ display: 'inline-block', position: 'relative' }}
      >
        <svg
          width={width}
          height={height}
          viewBox="0 0 4.2438703 4.2333174"
          version="1.1"
          id="svg1"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs id="defs1">
            <linearGradient id="swatch6">
              <stop
                style={{ stopColor: '#000000', stopOpacity: 1 }}
                offset="0"
                id="stop6"
              />
            </linearGradient>
          </defs>
          <g id="layer1" transform="translate(-114.25136,-112.62227)">
            <g
              style={{ fill: 'none', stroke: '#000000', strokeOpacity: 1 }}
              id="g1"
              transform="matrix(0.26458093,0.0011263,-0.0011263,0.26458093,113.2082,111.55044)"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M 12,4.75 4.75001,8 c 0,0 -0.75,11.25 7.24999,11.25 C 20,19.25 19.25,8 19.25,8 Z"
                id="path1"
                style={{
                  stroke: 'rgb(147 204 104)',
                  strokeOpacity: 1,
                }}
              />
            </g>
            <g
              style={{ fill: 'none' }}
              id="g10"
              transform="matrix(0.12842308,0,0,0.14060106,114.89555,112.95195)"
            >
              <path
                d="m 12.75,4.75 h -5 c -1.10457,0 -2,0.89543 -2,2 v 3.5 m 7,-5.5 v 3.5 c 0,1.10457 0.8954,2 2,2 h 3.5 m -5.5,-5.5 5.5,5.5 m 0,0 v 7 c 0,1.1046 -0.8954,2 -2,2 h -3.5"
                stroke="rgb(147 204 104)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                id="path1-1"
              />
              <path
                d="m 9.25,12.75 h -0.5 c -1.65685,0 -3,1.3431 -3,3 v 0.5 c 0,1.6569 1.34315,3 3,3 h 0.5"
                stroke="rgb(147 204 104)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                id="path2"
              />
              <path
                d="m 4.75,16 h 3.5"
                stroke="rgb(147 204 104)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                id="path3"
              />
            </g>
          </g>
        </svg>
      </div>
      <Tooltip id="custom-badge-tooltip" className="text-xs" />
    </>
  );
};

export default CustomCaseBadge;
