import { OpenClosedStates } from '@chiroup/components';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, TrashIcon } from '@heroicons/react/24/solid';
import React, { Fragment } from 'react';
import Button, { ButtonColors } from '../common/Button';

type Props = {
  state: OpenClosedStates;
  title?: string;
  description?: string;
  confirmText?: string;
  confirm: () => void;
  close: () => void;
  loading?: boolean;
  icon?: React.ReactNode;
};

const ConfirmModal: React.FC<Props> = ({
  title = 'Are you sure?',
  description,
  confirmText = 'Yes',
  confirm,
  close,
  state,
  loading = false,
  icon = <TrashIcon className="h-6 w-6 text-gray-600 dark:text-darkGray-400" />,
}) => {
  return (
    <Transition.Root show={state === OpenClosedStates.Open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-darkGray-800 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-primary-100">
                    <CheckIcon
                      className="h-6 w-6 text-primary-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-darkGray-50"
                    >
                      {title}
                    </Dialog.Title>
                    {!!description && (
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{description}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-6 grid grid-cols-2 gap-3 grid-flow-row-dense">
                  <Button
                    text="Cancel"
                    onClick={close}
                    fullWidth
                    color={ButtonColors.plain}
                    className="border border-gray-300 dark:border-darkGray-600"
                  />
                  <Button
                    text={confirmText}
                    onClick={confirm}
                    fullWidth
                    loading={loading}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmModal;
