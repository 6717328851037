import { classNames } from '@chiroup/core';
import React from 'react';

type Props = {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  leftSide?: React.ReactNode;
  rightSide?: React.ReactNode;
  onClick?: () => void;
  open?: boolean;
  accordionGroup?: boolean;
  backgroundColor?: string;
  className?: string;
  bottom?: React.ReactNode;
  top?: React.ReactNode;
  noDark?: boolean;
};

const SectionHeader: React.FC<Props> = ({
  title,
  subtitle,
  leftSide,
  rightSide,
  onClick,
  open = true,
  accordionGroup = false,
  backgroundColor = 'bg-white dark:bg-darkGray-700',
  className = '',
  bottom,
  top,
  noDark = false,
}) => {
  return (
    <div
      onClick={onClick}
      className={[
        'px-3 py-2 print:py-0 border-b print:border-b-0 border-gray-300',
        backgroundColor,
        onClick ? 'cursor-pointer' : '',
        open ? 'rounded-t-lg' : accordionGroup ? '' : 'rounded-lg',
        className,
        noDark ? '' : 'dark:border-darkGray-700',
      ].join(' ')}
    >
      {!!top && <div>{top}</div>}
      <div
        className={[
          'flex sm:items-center justify-between',
          accordionGroup ? '' : '',
        ].join(' ')}
      >
        <div
          className={['flex flex-row', rightSide ? 'gap-1' : 'w-full'].join(
            ' ',
          )}
        >
          {!!leftSide && <div style={{ marginTop: 2 }}>{leftSide}</div>}

          <div className="mb-6 sm:mb-0 w-full">
            <h3
              className={classNames(
                'text-lg print:text-md leading-6 font-medium text-gray-900',
                noDark ? '' : 'dark:text-darkGray-100',
              )}
            >
              {title}
            </h3>
            {subtitle && (
              <div
                className={classNames(
                  'mt-1 text-sm leading-5 text-gray-500',
                  noDark ? '' : 'dark:text-darkGray-400',
                )}
              >
                {subtitle}
              </div>
            )}
          </div>
        </div>
        {rightSide ? rightSide : null}
      </div>
      {!!bottom && <span>{bottom}</span>}
    </div>
  );
};

export default SectionHeader;
