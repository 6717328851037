import {
  ClinicBillingSettings,
  Insurance,
  PatientInsuranceTypes,
  PrimaryInsuredTypes,
} from '@chiroup/core';

export const getDefaultValues = (
  claimDefaults: ClinicBillingSettings | undefined,
): Partial<Insurance> => {
  return {
    type: PatientInsuranceTypes.Other,
    primaryInsuredType: PrimaryInsuredTypes.Patient,
    payor: undefined,
    internalName: undefined,
    memberId: undefined,
    planGroupId: undefined,
    insuranceBack: undefined,
    insuranceFront: undefined,
    primaryInsured: {
      addressSameAsPatient: false,
      country: 'USA',
    },
    disciplineBenefits: [],
    policyMaximum: undefined,
    policyEndDate: undefined,
    deductible: undefined,
    signatureOnFile: claimDefaults?.signatureOnFile ?? false,
    acceptAssignment: claimDefaults?.acceptAssignment ?? false,
    releaseOfPatientInformation: claimDefaults?.releaseInformation ?? false,
    adjustorInformation: {},
    active: 1,
  };
};
