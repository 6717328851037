import { ClinicBillingSettings, Macro } from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';

const settingsService = () => {
  const saveMacros = async (
    added: Partial<Macro>[],
    deleted: Partial<Macro>[],
    edited: Partial<Macro>[],
  ): Promise<{ statusCode: number; userMacros: Macro[] }> => {
    const body = {
      added,
      deleted,
      edited,
    };
    return ChiroUpAPI.post('api', `/settings/user/macro`, { body });
  };

  const saveBillingSettings = async (
    claim: Partial<ClinicBillingSettings>,
    clinicId?: number,
  ) => {
    const body = {
      ...claim,
    };
    return ChiroUpAPI.post('api', `/settings/${clinicId}/billing/`, {
      body,
    });
  };

  const getBillingSettings = async (clinicId?: number) => {
    return ChiroUpAPI.get('api', `/settings/${clinicId}/billing/`, {});
  };
  const getDisciplineTreatment = async (clinicId?: number, id?: number) => {
    return ChiroUpAPI.get(
      'api',
      `/settings/${clinicId}/discipline/treatment/${id}`,
      {},
    );
  };
  return {
    saveMacros,
    saveBillingSettings,
    getBillingSettings,
    getDisciplineTreatment,
  };
};

export default settingsService();
