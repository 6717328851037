export enum RehabOptionsEnum {
  TherapeuticExercise = 'Therapeutic Exercise',
  NeuromuscularReeducation = 'Neuromuscular Reeducation',
  TherapeuticActivity = 'Therapeutic Activity',
}

export const RehapOptionsServices = {
  [RehabOptionsEnum.TherapeuticExercise]: ['97110'],
  [RehabOptionsEnum.NeuromuscularReeducation]: ['97112'],
  [RehabOptionsEnum.TherapeuticActivity]: ['97530'],
};

export type RehabOptions = {
  ID: number;
  category: string;
  name: RehabOptionsEnum;
  units: number;
  services?: string[];
};

/**
 * Build the options needed for a SELECT in here so any changes
 * can all be in one file.
 */
const vals = Object.values(RehabOptionsEnum);
export const RehabSelectOptions: {
  ID: number;
  name: string;
  services: string[];
}[] = Object.keys(RehabOptionsEnum).map((_, idx) => {
  return {
    ID: idx + 1,
    name: vals[idx],
    services: RehapOptionsServices[vals[idx] as RehabOptionsEnum],
  };
});
