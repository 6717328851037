import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { TrivialTooltip } from '@chiroup/components';

type Props = {
  title: string;
  open: boolean;
  tooltip?: string;
  tooltipClassName?: string;
};

const DisclosureButton: React.FC<Props> = ({
  title,
  open,
  tooltip,
  tooltipClassName,
}) => {
  return (
    <dt>
      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900 dark:text-white">
        <span className="text-base font-semibold leading-7">
          {title}
          {tooltip ? (
            <TrivialTooltip
              text={tooltip}
              tipClassName={tooltipClassName || 'w-96 text-sm leading-normal'}
            />
          ) : null}
        </span>
        <span className="ml-6 flex h-7 items-center">
          {open ? (
            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
          ) : (
            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
          )}
        </span>
      </Disclosure.Button>
    </dt>
  );
};

export default DisclosureButton;
