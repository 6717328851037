import { FormError, Macro } from '@chiroup/core';
import React, {
  ChangeEvent,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { ErrorIcon } from './ErrorIcon';
import { FieldErrors } from './FieldErrors';
import MacrosPopover from './MacrosPopover';
import useMacros from './useMacros';
import { TrivialTooltip } from '../TrivialTooltip';

type Props = {
  name?: string;
  className?: string;
  icon?: React.ReactNode;
  label?: string;
  value?: string;
  onChange: (val: string) => void;
  onBlur?: (val: string) => void;
  errors?: FormError;
  disabled?: boolean;
  onFocus?: () => void;
  rows?: number;
  hint?: string;
  hintOnClick?: () => void;
  autoFocus?: boolean;
  placeHolder?: string;
  macros?: Partial<Macro>[];
  tooltip?: string;
  areaClassName?: string;
};

type TextAreaRef = {
  focus: () => void;
  blur: () => void;
  // Add any other methods or properties you want to expose
};

export const Textarea = forwardRef<TextAreaRef, Props>(
  (
    {
      name,
      label,
      value,
      onChange,
      onBlur,
      errors,
      className = '',
      icon,
      disabled = false,
      onFocus,
      rows,
      hint,
      hintOnClick,
      autoFocus,
      placeHolder = '',
      macros,
      tooltip,
      areaClassName = '',
    }: Props,
    ref,
  ) => {
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    useImperativeHandle(ref, () => ({
      blur: () => {
        if (textAreaRef.current) {
          textAreaRef.current.blur();
        }
      },
      focus: () => {
        if (textAreaRef.current) {
          textAreaRef.current.focus();
        }
      },
      // You can expose other properties or methods as needed
    }));
    const [showPopover, setShowPopover] = useState(false);
    const { filteredMacros } = useMacros(value, macros);

    const handleMacroClick = (phrase: string, cursorPlacement?: number) => {
      if (typeof value === 'string' && typeof cursorPlacement === 'number') {
        const cutString = value.slice(0, cursorPlacement);
        const lastSlashIndex = cutString.lastIndexOf('/');

        const newStr = `${value.slice(
          0,
          lastSlashIndex,
        )}${phrase} ${value.slice(cursorPlacement)}`;

        onChange?.(newStr);
        setShowPopover(false);

        setTimeout(() => {
          if (textAreaRef.current) {
            textAreaRef.current.selectionStart =
              lastSlashIndex + phrase.length + 1;
            textAreaRef.current.selectionEnd =
              lastSlashIndex + phrase.length + 1;
          }
        }, 0);
      }
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (macros?.length) {
        if (e.code === 'Slash') {
          setShowPopover(true);
        } else if (e.code === 'Space') {
          setShowPopover(false);
        }
      }
    };

    const onChangeValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const val = e.target.value;
      onChange(val);
    };

    const onBlurValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const val = e.target.value;
      onBlur?.(val);
    };

    return (
      <div className={className}>
        {label && (
          <div>
            <label
              htmlFor={name}
              className="block text-sm font-medium leading-5 text-gray-700  dark:text-darkGray-400 sm:mt-px sm:pt-2"
            >
              {label}
              {tooltip && (
                <TrivialTooltip
                  text={tooltip}
                  type="info"
                  svgClassName="w-3 h-3 text-grey-400 ml-2"
                />
              )}
            </label>
          </div>
        )}
        <div>
          <div className="relative rounded-md shadow-sm">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              {icon && (
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  {icon}
                </div>
              )}
              <textarea
                id={name}
                name={name}
                className={[
                  areaClassName,
                  'block w-full border-gray-300 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200 focus:ring-2',
                  errors
                    ? 'border-red-500 text-red-900 placeholder-red-500 focus:border-red-500 focus:ring-red-500 outline-none'
                    : 'focus:border-primary-500 focus:ring-primary-500 outline-none',
                  disabled
                    ? 'text-gray-500 dark:text-darkGray-300 bg-gray-300 dark:bg-darkGray-500 cursor-not-allowed'
                    : '',
                  icon ? 'pl-10' : '',
                ].join(' ')}
                value={value || ''}
                onChange={onChangeValue}
                onFocus={onFocus}
                onBlur={onBlurValue}
                disabled={disabled}
                ref={textAreaRef}
                rows={rows}
                autoFocus={autoFocus}
                data-cy="text"
                placeholder={placeHolder}
                onKeyDown={onKeyDown}
              />
              {errors && <ErrorIcon />}
            </div>
          </div>
          <FieldErrors errors={errors} />
          {!!hint && (
            <p
              className={[
                'mt-2 text-sm',
                hintOnClick
                  ? 'text-primary-600 font-semibold hover:text-primary-500 cursor-pointer'
                  : 'text-gray-500',
              ].join(' ')}
              id={`${name}-hint`}
              onClick={hintOnClick}
            >
              {hint}
            </p>
          )}
          {!!(showPopover && macros?.length) && (
            <MacrosPopover
              showPopover={showPopover}
              setShowPopover={setShowPopover}
              filteredMacros={filteredMacros}
              handleMacroClick={handleMacroClick}
              cursorPosition={textAreaRef.current?.selectionStart ?? 0}
            />
          )}
        </div>
      </div>
    );
  },
);
