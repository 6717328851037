import { FormError, classNames } from '@chiroup/core';
import { useOnClickOutside } from '@chiroup/hooks';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import React, {
  ForwardedRef,
  Fragment,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import EndOfList from '../EndOfList';
import { FieldErrors } from './FieldErrors';
import { Input } from './Input';
import { Loading } from '../Loading';
import AlertBlock from '../../../../../apps/chiroup/src/components/common/AlertBlock';

export type Props = {
  name: string;
  className?: string;
  label?: string;
  value?: any;
  limit?: number;
  onChange: (val: any) => void;
  errors?: FormError;
  options?: any[];
  showLabel?: boolean;
  disabled?: boolean;
  alwaysReturnArray?: boolean;
  clearable?: boolean;
  valueProp?: string;
  textProp?: string;
  isFetchingNextPage?: boolean;
  isFetching?: boolean;
  fetchNextPage?: (options?: FetchNextPageOptions | undefined) => Promise<
    InfiniteQueryObserverResult<
      {
        data: any[];
        lastKey?: string;
        skip?: number;
      },
      unknown
    >
  >;
  hasNextPage?: boolean;
  onChangeSearch: (searchTerm: string) => void;
  onEmptyOptions?: () => void;
  searchTerm?: string;
  onAdd?: () => void;
  loading?: boolean;
  serverSide?: boolean;
  searchRef?: React.RefObject<HTMLInputElement>;
  buttonRef?: React.RefObject<HTMLInputElement>;
  placeholder?: string;
  initialValue?: string;
  initialDisplayValue?: string;
  hideEndOfList?: boolean;
  noneText?: string;
  addOptionTitles?: boolean;
  addTitle?: string;
  add?: () => void;
  autoFocus?: boolean;
  disabledClassName?: string;
  onChangeCallback?: ((obj: any) => void) | null;
  errorMessage?: string;
  errorCloseCallback?: () => void;
  xout?: (() => void) | null;
};

export const Autocomplete = forwardRef(
  (
    {
      name,
      className = '',
      label,
      value = '',
      limit,
      onChange,
      errors,
      options = [],
      showLabel = true,
      disabled = false,
      alwaysReturnArray = false,
      clearable = false,
      valueProp = 'value',
      textProp = 'text',
      isFetchingNextPage,
      isFetching,
      fetchNextPage,
      hasNextPage,
      onChangeSearch,
      searchTerm,
      onAdd,
      loading,
      serverSide,
      // TODO: Seems like there is a better way, but not looking to fix it right now.
      // eslint-disable-next-line react-hooks/rules-of-hooks
      searchRef = useRef<HTMLInputElement>(null),
      // eslint-disable-next-line react-hooks/rules-of-hooks
      buttonRef = useRef<HTMLInputElement>(null),
      placeholder,
      initialValue,
      initialDisplayValue,
      hideEndOfList = false,
      onEmptyOptions,
      noneText = 'None',
      addOptionTitles = false,
      addTitle,
      add,
      autoFocus,
      disabledClassName = '',
      onChangeCallback = null,
      errorMessage,
      errorCloseCallback,
      xout = null,
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [currentDisplay, setCurrentDisplay] = useState(
      initialValue === '' ? ' ' : noneText,
    );
    const [open, setOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(containerRef as any, () => {
      setOpen(false);
    });

    const clear = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      e.stopPropagation();
      onChange(alwaysReturnArray ? [] : '');
    };

    useEffect(() => {
      if (limit === 1 && value) {
        const valueDisplay = options?.find((opt) => opt[valueProp] === value);
        setCurrentDisplay(
          valueDisplay?.[textProp] || initialDisplayValue || '1 item selected',
        );
      } else if (value?.length) {
        setCurrentDisplay(`${value?.length} items selected`);
      } else {
        setCurrentDisplay(initialValue === '' ? ' ' : noneText);
      }
      if (!options?.length && onEmptyOptions) {
        onEmptyOptions();
      }
    }, [
      options,
      value,
      textProp,
      valueProp,
      limit,
      initialDisplayValue,
      initialValue,
      noneText,
      onEmptyOptions,
    ]);

    const onChangeSelections = (val: any) => {
      let hasAdd: boolean;
      if (Array.isArray(val)) {
        hasAdd = val.some((item: string) => item === '$add');
      } else {
        hasAdd = val === '$add';
      }
      if (hasAdd && onAdd) {
        onAdd();
        setOpen(false);
      } else {
        onChange(val);
      }

      !!onChangeCallback &&
        onChangeCallback(options?.find((opt) => opt[valueProp] === val));
    };

    const hasOptions = useMemo(() => {
      return options?.length > 0;
    }, [options]);

    useEffect(() => {
      if (autoFocus && !value) {
        setOpen(true);
      }
    }, [autoFocus, value]);

    return (
      <div className={className}>
        <AlertBlock
          level="error"
          message={errorMessage}
          closeCallback={errorCloseCallback}
        />
        <Listbox
          value={value}
          onChange={(val) => {
            if (limit === 1) {
              onChangeSelections(val);
              setOpen(false);
            } else {
              const isThere = value?.includes(val);
              const newValue = isThere
                ? value.filter((item: any) => item !== val)
                : [...value, val];
              onChangeSelections(newValue);
            }
          }}
        >
          <>
            {showLabel && (
              <Listbox.Label
                htmlFor={name}
                className="block text-sm font-medium leading-5 text-gray-900 dark:text-darkGray-200 sm:mt-px sm:pt-2"
              >
                <div className="inline-block">{label}</div>
              </Listbox.Label>
            )}
            <div
              className="relative flex flex-row w-full gap-2"
              ref={containerRef}
            >
              {open ? (
                <div className="flex flex-col gap-1 flex-1 relative">
                  <Input
                    name="search"
                    className={[
                      'w-full dark:text-gray-100',
                      open ? 'block' : 'hidden',
                    ].join(' ')}
                    ref={searchRef}
                    onChange={onChangeSearch}
                    value={searchTerm}
                    loading={isFetching || loading}
                    placeholder={placeholder}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    disabledClassName={disabledClassName}
                    xout={xout}
                  />
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      static
                      className={classNames('absolute z-10 mt-10 w-full')}
                    >
                      <div
                        className={classNames(
                          'bg-white dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm',
                          hasOptions ? 'block' : 'hidden',
                        )}
                      >
                        {options.map((option, i) => {
                          const isActive =
                            limit === 1
                              ? value === option[valueProp]
                              : value?.includes(option[valueProp]);
                          return (
                            <Listbox.Option
                              title={
                                addOptionTitles ? option[textProp] : undefined
                              }
                              key={i}
                              className={() =>
                                [
                                  isActive
                                    ? 'text-white bg-primary-500'
                                    : 'text-gray-900',
                                  'cursor-default select-none relative py-2 pl-3 pr-9 dark:text-darkGray-200',
                                ].join(' ')
                              }
                              value={option[valueProp]}
                            >
                              {() => (
                                <>
                                  <span
                                    className={[
                                      isActive
                                        ? 'font-semibold'
                                        : 'font-normal',
                                      'block truncate',
                                    ].join(' ')}
                                  >
                                    {option[textProp]}
                                  </span>

                                  {isActive ? (
                                    <span
                                      className={[
                                        'text-white',
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                      ].join(' ')}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          );
                        })}
                        {serverSide && !hideEndOfList && (
                          <EndOfList
                            isFetching={!!isFetching}
                            isFetchingNextPage={!!isFetchingNextPage}
                            fetchNextPage={fetchNextPage}
                            hasNextPage={hasNextPage}
                            small
                          />
                        )}
                      </div>
                    </Listbox.Options>
                  </Transition>
                </div>
              ) : (
                <div
                  ref={buttonRef}
                  className="flex flex-row gap-2 flex-1 w-full"
                >
                  <Listbox.Button
                    onClick={() => {
                      if (disabled) return;
                      setOpen(true);
                      setTimeout(() => {
                        searchRef.current?.focus();
                      }, 0);
                    }}
                    className={classNames(
                      'relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default sm:text-sm dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200',
                      disabled
                        ? disabledClassName || 'bg-gray-100'
                        : 'bg-white focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500',
                    )}
                  >
                    <span
                      className={classNames(
                        'block truncate',
                        disabled || currentDisplay === noneText
                          ? disabledClassName || 'text-gray-400'
                          : '',
                      )}
                    >
                      <div className="flex gap-4">
                        {currentDisplay}
                        {clearable &&
                          !!(Array.isArray(value) ? value.length : value) && (
                            <svg
                              className="h-4 w-4 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              onClick={clear}
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                      </div>
                    </span>

                    {loading ? (
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <Loading color="text-gray-400" size={6} />
                      </div>
                    ) : (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </Listbox.Button>
                </div>
              )}
              {add && (
                <div
                  className="flex items-center justify-center"
                  title={addTitle}
                >
                  <svg
                    className={[
                      'h-6 w-6 text-gray-300 hover:text-gray-400 cursor-pointer self-center dark:text-darkGray-600 dark:hover:text-darkGray-500',
                    ].join(' ')}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    onClick={add}
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}
            </div>
            <FieldErrors errors={errors} />
          </>
        </Listbox>
      </div>
    );
  },
);

export default Autocomplete;
