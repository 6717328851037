import Decimal from 'decimal.js';
import { createDecimal } from './createDecimal';

type CalculationInput = {
  totalServices: number | string;
  coPay?: number | string;
  coInsurance?: number | string;
  deductible?: number | string;
  billingPriority?: number;
};

const ZERO = createDecimal(0);
const ONE_HUNDRED = createDecimal(100);

export const calculateInsurancePayment = ({
  totalServices,
  coPay,
  coInsurance,
  deductible,
}: CalculationInput): {
  insurerTotal: string;
  coPay: Decimal;
  coInsurance: Decimal;
} => {
  const total = createDecimal(totalServices || 0);
  const coPayDecimal = createDecimal(coPay || 0);
  const coInsuranceDecimal = createDecimal(coInsurance || 0)
    .div(ONE_HUNDRED)
    .toDP(2);
  const deductibleDecimal = createDecimal(deductible || 0);

  // First, subtract the co-pay
  const balanceAfterCoPay = total.minus(coPayDecimal).toDP(2).lessThan(ZERO)
    ? ZERO
    : total.minus(coPayDecimal).toDP(2);

  // Then, apply deductible
  let balanceAfterDeductible = balanceAfterCoPay
    .minus(deductibleDecimal)
    .toDP(2);
  balanceAfterDeductible = balanceAfterDeductible.lessThan(ZERO)
    ? ZERO
    : balanceAfterDeductible;

  const coInsuranceAmount = balanceAfterDeductible.mul(coInsuranceDecimal);

  // Apply coInsurance to the remaining balance
  let insurerTotal = balanceAfterDeductible.minus(coInsuranceAmount).toDP(2);

  // Ensure that insurer total is not negative
  insurerTotal = insurerTotal.lessThan(ZERO) ? ZERO : insurerTotal;
  return {
    insurerTotal: insurerTotal.toFixed(2),
    coPay: coPayDecimal,
    coInsurance: coInsuranceAmount,
  };
};
