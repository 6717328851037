import { AppointmentActivityType, classNames } from '@chiroup/core';
import {
  ArrowPathIcon,
  CalendarDaysIcon,
  InformationCircleIcon,
  PencilIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import useAppointmentActivity from './hooks/useAppointmentActivity';
import { LoadingPage } from '@chiroup/components';

dayjs.extend(relativeTime);

const activityIcons: {
  [key in AppointmentActivityType]: React.ReactNode;
} = {
  [AppointmentActivityType.AppointmentCreated]: (
    <CalendarDaysIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
  ),
  [AppointmentActivityType.AppointmentUpdated]: (
    <PencilIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
  ),
  [AppointmentActivityType.AppointmentCanceled]: (
    <XMarkIcon className="h-4 w-4 text-red-400" aria-hidden="true" />
  ),
  [AppointmentActivityType.RecurringAppointmentCreated]: (
    <ArrowPathIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
  ),
  [AppointmentActivityType.RecurringAppointmentUpdated]: (
    <ArrowPathIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
  ),
  [AppointmentActivityType.InfoCompleted]: (
    <InformationCircleIcon
      className="h-4 w-4 text-gray-500"
      aria-hidden="true"
    />
  ),
};

type Props = {
  appointmentId: string;
};

const ScheduleAppointmentActivityLog: React.FC<Props> = ({ appointmentId }) => {
  const { data, isFetching } = useAppointmentActivity(appointmentId);

  return isFetching ? (
    <LoadingPage />
  ) : (
    <ul className="space-y-6">
      {data?.map((activityItem, activityItemIdx) => (
        <li key={activityItem.id} className="relative flex gap-x-4">
          <div
            className={classNames(
              activityItemIdx === data.length - 1 ? 'h-6' : '-bottom-6',
              'absolute left-0 top-0 flex w-6 justify-center',
            )}
          >
            <div className="w-px bg-gray-300 dark:bg-darkGray-700" />
          </div>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white dark:bg-darkGray-900">
            {activityIcons[activityItem.type] ? (
              <div className="flex justify-center items-center h-6 w-6 rounded-full bg-gray-300 dark:bg-darkGray-700">
                {activityIcons[activityItem.type]}
              </div>
            ) : (
              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
            )}
          </div>
          <div className="flex-auto flex flex-col gap-2">
            <div className="flex-auto flex">
              <p
                className="flex-auto py-0.5 text-xs leading-5 text-gray-500"
                dangerouslySetInnerHTML={{ __html: activityItem.description }}
              />
              <span
                className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                title={dayjs(activityItem.createdAt).format(
                  'MM/DD/YYYY [at] h:mm A',
                )}
              >
                {dayjs(activityItem.createdAt).fromNow()}
              </span>
            </div>
            {!!(
              activityItem.data?.strings?.length ||
              activityItem.data?.updates?.length
            ) && (
              <div className="w-full rounded-md p-3 ring-1 ring-inset ring-gray-300">
                <ul className="text-sm leading-6 text-gray-500">
                  {activityItem.data.strings?.map((str) => <li>{str}</li>)}
                  {activityItem.data.updates?.map((update) => (
                    <li>
                      <span className="font-medium text-gray-900">
                        {update.field}
                      </span>{' '}
                      changed{update.from && update.to ? ' from ' : ' '}
                      <span className="font-medium text-gray-900">
                        {update.from}
                      </span>
                      {update.from && ' '}
                      {update.to ? (
                        <>
                          to{' '}
                          <span className="font-medium text-gray-900">
                            {update.to}
                          </span>
                        </>
                      ) : (
                        ''
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ScheduleAppointmentActivityLog;
