import {
  AppointmentForUI,
  Appointments,
  AvailableSlotsResponse,
  DisciplineTreatment,
} from '@chiroup/core';
import { useTime, useWindowDimensions } from '@chiroup/hooks';
import {
  ClientRect,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MeasuringStrategy,
  Modifier,
  MouseSensor,
  TouchSensor,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Transform } from '@dnd-kit/utilities';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { createPortal } from 'react-dom';
import { AppointmentsContext } from '../../contexts/appointments.context';
import { MeContext } from '../../contexts/me.context';
import useLocalStorage, { LSType } from '../../hooks/useLocalStorage';
import AppointmentView from './AppointmentView';
import CurrentTime from './CurrentTime';
import CurrentTimeLine from './CurrentTimeLine';
import ScheduleEvents from './ScheduleEvents';
import ScheduleHours from './ScheduleHours';
import SchedulePaneDays from './SchedulePaneDays';

const DEFAULT_ZOOM = 2;
const ZOOM_STEP = 1;
const MAX_ZOOM = 5;
const MIN_ZOOM = 1;

const defaultColumnWidth = 150;

export function restrictToBoundingRect(
  transform: Transform,
  rect: ClientRect,
  boundingRect: ClientRect,
): Transform {
  const value = {
    ...transform,
  };
  if (rect.top + transform.y <= boundingRect.top) {
    value.y = boundingRect.top - rect.top;
  } else if (
    rect.bottom + transform.y >=
    boundingRect.top + boundingRect.height
  ) {
    value.y = boundingRect.top + boundingRect.height - rect.bottom;
  }
  if (rect.left + transform.x <= boundingRect.left) {
    value.x = boundingRect.left - rect.left;
  } else if (
    rect.right + transform.x >=
    boundingRect.left + boundingRect.width
  ) {
    value.x = boundingRect.left + boundingRect.width - rect.right;
  }
  return value;
}

const modifier: Modifier = (args: any) => {
  const { draggingNodeRect, transform, over, active } = args;
  if (!active || !draggingNodeRect || !over) {
    return transform;
  }
  return restrictToBoundingRect(transform, draggingNodeRect, args.over.rect);
};

type Props = {
  appointments: Appointments;
  updateAppointment: ({
    day,
    time,
    userId,
    previousDay,
    previousUserId,
    wholeAppointment,
    timezone,
  }: {
    day: string;
    time: number;
    userId: string;
    previousDay: string;
    previousUserId: string;
    wholeAppointment: AppointmentForUI;
    timezone: string;
  }) => Promise<any>;
  availableSlots: AvailableSlotsResponse | null;
  selectedTreatment: DisciplineTreatment | null;
  selectedUsers: {
    [key: string]: boolean;
  };
  minMaxTime: {
    minTime: number;
    maxTime: number;
  };
  viewOneDay: (
    { id, name }: { id: string; name: string },
    {
      day,
      dayName,
      fullDate,
      prop,
    }: { day: string; dayName: string; fullDate: string; prop: string },
  ) => void;
  scheduleApptFromDoubleClick: (
    clinician: string,
    startTime: number,
    date: string,
  ) => void;
  searchQuery: {
    startDate: string;
    endDate: string;
  };
  users: {
    id: string;
    name: string;
    fname: string;
    lname: string;
    profileImage: string | null;
  }[];
};

const SchedulePane: React.FC<Props> = ({
  appointments,
  updateAppointment,
  availableSlots,
  selectedTreatment,
  selectedUsers,
  minMaxTime,
  viewOneDay,
  scheduleApptFromDoubleClick,
  searchQuery,
  users,
}) => {
  const { setItem, getItem } = useLocalStorage();
  const { selectedLocationFull } = useContext(MeContext);
  const [appointmentDragging, setAppointmentDragging] =
    useState<AppointmentForUI | null>(null);
  const [width, setWidth] = useState(defaultColumnWidth);
  const { width: windowWidth } = useWindowDimensions();
  const [initialScrollComplete, setInitialScrollComplete] = useState(false);
  const scrollRef = useRef<Scrollbars>(null);
  const [zoom, setZoom] = useState<number>(
    getItem(LSType.user, 'scheduleZoom')
      ? Number(getItem(LSType.user, 'scheduleZoom'))
      : DEFAULT_ZOOM,
  );
  const { top, time, hour } = useTime(zoom, selectedLocationFull.timezone);
  // const [doneScrolling, setDoneScrolling] = useState(false);
  // useEffect(() => {
  //   if (scrollRef.current && top && !doneScrolling) {

  //     scrollRef.current.scrollTop(top - 20);
  //     setDoneScrolling(true);
  //   }
  // }, [top, doneScrolling]);

  useEffect(() => {
    setItem(LSType.user, 'scheduleZoom', zoom);
  }, [setItem, zoom]);

  useEffect(() => {
    if (windowWidth <= 640 && width !== windowWidth - 70) {
      setWidth(windowWidth - 70);
    } else if (windowWidth > 640) {
      const dayKeys = Object.keys(appointments || {});
      const numberOfDays = dayKeys?.length || 0;
      if (numberOfDays === 1) {
        const firstDay = dayKeys[0];
        const numberOfUsers =
          Object.keys(appointments[firstDay] || {})?.length || 0;
        const newWidth = (windowWidth - 312) / numberOfUsers;
        setWidth(newWidth > defaultColumnWidth ? newWidth : defaultColumnWidth);
      } else {
        setWidth(defaultColumnWidth);
      }
    }
  }, [windowWidth, width, appointments]);

  useEffect(() => {
    if (initialScrollComplete) return;
    const numUsers = users.length;
    const today = dayjs();
    const lsStartDate = searchQuery.startDate;
    const lsEndDate = searchQuery.endDate;
    const startDate = (lsStartDate ||
      today.startOf('week').format('YYYY-MM-DD')) as string;
    const startDateDayjs = dayjs(startDate);
    const endDate = (lsEndDate ||
      today.endOf('week').format('YYYY-MM-DD')) as string;
    const endDateDayjs = dayjs(endDate);
    const isTodayInRange =
      today.isAfter(startDateDayjs) && today.isBefore(endDateDayjs.add(1, 'd'));
    if (isTodayInRange && scrollRef.current !== null) {
      const howFarToScrollLeft =
        today.diff(startDateDayjs, 'day') * width * numUsers;
      scrollRef.current.scrollLeft(howFarToScrollLeft);
      if (hour > minMaxTime.minTime) {
        //subtracting 60 pixels so it doesnt land strictly on the exact hour and give a little cushion.
        const howFarToScrollDown = (hour - minMaxTime.minTime) * 120 - 60;
        scrollRef.current.scrollTop(howFarToScrollDown);
        setInitialScrollComplete(true);
      }
    }
  }, [
    users,
    width,
    hour,
    initialScrollComplete,
    minMaxTime.minTime,
    searchQuery.endDate,
    searchQuery.startDate,
  ]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragCancel = () => {
    setAppointmentDragging(null);
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    const color = active.data.current?.color;
    const appointment = active.data.current?.appointment;
    if (!appointment) return;
    setAppointmentDragging({ ...appointment, color });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { day, time, userId } = event.over?.data.current as {
      day: string;
      time: number;
      userId: string;
    };
    const timeAltered = time + minMaxTime.minTime * 60;
    const appointment = event.active.id;
    const {
      day: previousDay,
      userId: previousUserId,
      appointment: wholeAppointment,
    } = event.active?.data.current as {
      day: string;
      userId: string;
      appointment: AppointmentForUI;
    };
    setAppointmentDragging(null);
    if (!day || typeof time !== 'number' || !userId || !appointment) return;

    updateAppointment({
      day,
      time: timeAltered,
      userId,
      previousDay,
      previousUserId,
      wholeAppointment,
      timezone: selectedLocationFull.timezone || '',
    });
  };

  const days = useMemo(() => {
    const daysSent = Object.keys(appointments || {}).map((date) => {
      const day = dayjs(date);
      return {
        day: day.format('D'),
        dayName: day.format('ddd'),
        fullDate: day.format('YYYY-MM-DD'),
        prop: date,
      };
    });
    return daysSent || [];
  }, [appointments]);

  const zoomOut = () => {
    if (zoom > MIN_ZOOM) {
      setZoom((prev) => prev - ZOOM_STEP);
    }
  };

  const zoomIn = () => {
    if (zoom < MAX_ZOOM) {
      setZoom((prev) => prev + ZOOM_STEP);
    }
  };

  return (
    <AppointmentsContext.Provider
      value={{
        zoom,
      }}
    >
      <div className="relative w-full h-full">
        <Scrollbars
          ref={scrollRef}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                backgroundColor: '#000',
                width: '6px',
                opacity: '0.5',
              }}
            />
          )}
          renderThumbHorizontal={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                backgroundColor: '#000',
                height: '6px',
                opacity: '0.5',
              }}
            />
          )}
        >
          <div className="flex flex-auto flex-col bg-white w-fit dark:bg-darkGray-700 ">
            <SchedulePaneDays
              days={days}
              users={users}
              width={width}
              viewOneDay={viewOneDay}
            />
            <div className="flex flex-auto">
              <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100 dark:bg-darkGray-700">
                <CurrentTime
                  top={top}
                  time={time}
                  minTime={minMaxTime.minTime}
                  maxTime={minMaxTime.maxTime}
                />
                <ScheduleHours minMaxTime={minMaxTime} />
              </div>
              <div className="flex flex-auto grid-cols-1 grid-rows-1 relative">
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCorners}
                  measuring={{
                    droppable: {
                      strategy: MeasuringStrategy.Always,
                    },
                  }}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  onDragCancel={handleDragCancel}
                >
                  <div className="isolate">
                    <CurrentTimeLine
                      top={top}
                      minTime={minMaxTime.minTime}
                      maxTime={minMaxTime.maxTime}
                    />
                    <ScheduleEvents
                      days={days}
                      users={users}
                      appointments={appointments}
                      dragging={!!appointmentDragging}
                      width={width}
                      availableSlots={availableSlots}
                      selectedTreatment={selectedTreatment}
                      minMaxTime={minMaxTime}
                      scheduleApptFromDoubleClick={scheduleApptFromDoubleClick}
                    />
                    {createPortal(
                      <DragOverlay
                        transition={() => 'transform 250ms ease'}
                        modifiers={[modifier]}
                      >
                        {appointmentDragging ? (
                          <AppointmentView
                            patientName={
                              appointmentDragging.displayValues.patientName
                            }
                            start={appointmentDragging.startTime}
                            height={appointmentDragging.height}
                            duration={appointmentDragging.duration}
                            color={appointmentDragging.color}
                            className="drop-shadow-2xl"
                            isDragging
                            appointment={appointmentDragging}
                          />
                        ) : null}
                      </DragOverlay>,
                      document.body,
                    )}
                  </div>
                </DndContext>
              </div>
            </div>
          </div>
        </Scrollbars>
        <div className="absolute right-2 bottom-2 ring-gray-300 ring-1 rounded-md divide-x divide-gray-300 bg-white flex flex-row shadow-md">
          <button
            className={classNames(
              'dark:bg-darkGray-700 dark:text-gray-100 text-gray-800 h-8 w-8 flex items-center justify-center',
              zoom === MIN_ZOOM
                ? 'cursor-not-allowed bg-gray-100 rounded-l-md'
                : 'cursor-pointer',
            )}
            onClick={zoomOut}
          >
            -
          </button>
          <button
            className={classNames(
              'dark:bg-darkGray-700 dark:text-gray-100 text-gray-800 h-8 w-8 flex items-center justify-center',
              zoom === MAX_ZOOM
                ? 'cursor-not-allowed bg-gray-100 rounded-r-md'
                : 'cursor-pointer',
            )}
            onClick={zoomIn}
          >
            +
          </button>
        </div>
      </div>
    </AppointmentsContext.Provider>
  );
};

export default SchedulePane;
