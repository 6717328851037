import { FormError, GLOBAL_CALLING_CODES } from '@chiroup/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { ErrorIcon } from './ErrorIcon';
import { FieldErrors } from './FieldErrors';

type Props = {
  name: string;
  className?: string;
  label?: string;
  value?: string | null;
  onChange: (val: string) => void;
  errors?: FormError;
  disabled?: boolean;
  autoFocus?: boolean;
  international?: boolean;
  countryCode?: string;
};

export const Phone: React.FC<Props> = ({
  name,
  className = '',
  label,
  value,
  onChange,
  errors,
  disabled = false,
  autoFocus = false,
  international = true,
  countryCode = '+1',
}) => {
  const [number, setNumber] = useState<string>();
  const [phoneRegionCode, setPhoneRegionCode] = useState<string>(countryCode);

  useEffect(() => {
    if (value) {
      const [pr, num] = value.split(' ');
      setPhoneRegionCode(pr);
      setNumber(num);
    } else {
      setNumber('');
    }
  }, [value]);

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    const cleanedVal = val.replace(/[ ()]/g, '');
    if (phoneRegionCode && cleanedVal) {
      onChange(`${phoneRegionCode} ${cleanedVal}`);
    } else {
      onChange('');
    }
  };

  const onChangeCountry = (event: ChangeEvent<HTMLSelectElement>) => {
    const val = event.target.value;
    onChange(`${val} ${(number || '').replace(/ /g, '')}`);
    // setPhoneRegionCode(val);
  };

  return (
    <div className={className} data-cy="phone">
      {!!label && (
        <div>
          <label
            htmlFor={name}
            className="block text-sm font-medium leading-5 text-gray-900  dark:text-darkGray-200 sm:mt-px sm:pt-2"
          >
            {label}
          </label>
        </div>
      )}
      <div>
        <div className="relative rounded-md shadow-sm">
          {international && (
            <div className="absolute inset-y-0 left-0 flex items-center">
              <select
                aria-label="Country"
                className={[
                  'form-select h-full py-0 pl-3 pr-6 border-transparent bg-transparent text-gray-500 rounded-md sm:text-sm sm:leading-5 w-28 dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200 border-r-0 focus:',
                  errors
                    ? 'border-red-500 text-red-900 placeholder-red-500 focus:border-red-500 focus:ring-red'
                    : 'focus:border-primary-500 focus:ring-primary-500 outline-none',
                  disabled
                    ? 'text-gray-500 dark:text-darkGray-300 bg-gray-300 dark:bg-darkGray-500 cursor-not-allowed'
                    : '',
                ].join(' ')}
                onChange={onChangeCountry}
                value={phoneRegionCode}
                disabled={disabled}
              >
                {GLOBAL_CALLING_CODES.map((code) => (
                  <option value={code} key={code}>
                    {code}
                  </option>
                ))}
                <option value="">Other</option>
              </select>
            </div>
          )}
          <PatternFormat
            format={phoneRegionCode ? '(###) ### ####' : '###############'}
            allowEmptyFormatting
            mask="_"
            value={number}
            onChange={onChangeValue}
            // htmlRef={(hRef) => (ref.current = hRef)}
            key={phoneRegionCode}
            // options={{
            //   blocks: phoneRegionCode === '+1' ? [3, 3, 4] : [15],
            //   numericOnly: true,
            // }}
            className={[
              'block w-full border-gray-300 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200 focus:ring-2',
              errors
                ? 'border-red-500 text-red-900 placeholder-red-500 focus:border-red-500 focus:ring-red'
                : 'focus:border-primary-500 focus:ring-primary-500 outline-none',
              disabled
                ? 'text-gray-500 dark:text-darkGray-300 bg-gray-300 dark:bg-darkGray-500 cursor-not-allowed'
                : '',
            ].join(' ')}
            style={!international ? {} : { paddingLeft: '7rem' }}
            disabled={disabled}
            autoFocus={autoFocus}
            type="tel"
            inputMode="numeric"
          />
          {errors && <ErrorIcon />}
        </div>
        <FieldErrors errors={errors} />
      </div>
    </div>
  );
};
