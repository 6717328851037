import { LoadingPage } from '@chiroup/components';
import {
  AppointmentForUI,
  AppointmentStatuses,
  ChiroUpDayJsCommon,
  Me,
} from '@chiroup/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useMemo } from 'react';
import { v4 } from 'uuid';
import useAppointments from './hooks/useAppointments';

dayjs.extend(relativeTime);

type Props = {
  patientId: string;
  me: Me;
};

const SchedulePatientHistory: React.FC<Props> = ({ patientId, me }) => {
  const sessionId = useMemo(() => v4(), []);
  const { data, isFetching } = useAppointments(sessionId, patientId, true);
  const nowJs = dayjs();

  // const locationsById = (me?.selectedClinic?.locations || []).reduce(
  //   (obj: any, location: ClinicLocation) => {
  //     obj[location.ID] = location;
  //     return obj;
  //   },
  //   {},
  // );

  /**
   * The endpoint can return the object for the schedule in CUP with
   * the days and clinicians as keys in an objec -OR- and array of
   * appointment objects. This code will handle either.
   */
  const listofAppointments: AppointmentForUI[] = !Array.isArray(data)
    ? Object.values(data || {}).reduce(
        (arr: AppointmentForUI[], appointmentsByClinician) => {
          const levelTwo = Object.values(appointmentsByClinician || {});
          const levelTwoAppointments = levelTwo
            .map((a) => a.appointments)
            ?.flat() as AppointmentForUI[];
          return [...arr, ...levelTwoAppointments]
            .sort((a, b) =>
              a.startTime > b.startTime
                ? -1
                : b.startTime > a.startTime
                  ? 1
                  : 0,
            )
            .map((obj) => {
              return {
                ...obj,
                startTime:
                  typeof obj.startTime === 'number'
                    ? dayjs(obj.startTime)
                    : obj.startTime,
                startTimeFormatted: ChiroUpDayJsCommon.display.datetime(
                  obj.startTime as any,
                  obj.tz,
                ),
                inPast: obj.startTime < nowJs,
              };
            });
        },
        [],
      )
    : data.map((obj) => {
        return {
          ...obj,
          startTimeFormatted: ChiroUpDayJsCommon.display.datetime(
            obj.startTime as any,
            obj.tz,
          ),
          inPast: obj.startTime < nowJs,
        };
      });

  const simpleAppointmentFormat = (
    prefix: string,
    appointment: AppointmentForUI | null | undefined,
  ) => {
    return appointment ? (
      <div>
        {prefix || 'From'}{' '}
        {appointment.startTimeFormatted || '-- time not available --'} for{' '}
        {appointment.duration || '-- duration not available --'} minutes with{' '}
        {appointment.displayValues?.clinicianName ||
          '-- clinician not available --'}
        {appointment.status === AppointmentStatuses.Canceled
          ? ' was canceled'
          : ''}
        .
      </div>
    ) : null;
  };

  const getLastAppointment = () => {
    const last = (listofAppointments || []).find(
      (item: any) => item.inPast === true,
    );
    return simpleAppointmentFormat('Last:', last) || null;
  };

  const getNextAppointment = () => {
    const last = (listofAppointments || []).find(
      (item: any) =>
        item.inPast !== true && item.status === AppointmentStatuses.Scheduled,
    );
    return simpleAppointmentFormat('Next:', last) || null;
  };

  return isFetching ? (
    <LoadingPage />
  ) : (
    <div className="grid grid-cols-2 gap-4">
      <div className="col-span-2 justify-between rounded-2xl bg-gray-50 p-6">
        <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">
          {listofAppointments.length}
        </p>
        <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none flex flex-col gap-2">
          <p className="text-lg font-semibold tracking-tight text-gray-900">
            Total appointments
          </p>
          <div className="flex flex-col gap-1">
            {getLastAppointment() && (
              <p className="text-sm text-gray-600">{getLastAppointment()}</p>
            )}
            {getNextAppointment() && (
              <p className="text-sm text-gray-600">{getNextAppointment()}</p>
            )}
          </div>
        </div>
      </div>
      <div className="col-span-1 justify-between rounded-2xl bg-gray-900 dark:bg-darkGray-700 p-6">
        <p className="flex-none text-3xl font-bold tracking-tight text-white">
          {
            (
              listofAppointments.filter(
                (i: AppointmentForUI) =>
                  !i.inPast && i.status === AppointmentStatuses.Scheduled,
              ) || []
            ).length
          }
        </p>
        <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
          <p className="text-lg font-semibold tracking-tight text-white">
            Upcoming
          </p>
        </div>
      </div>
      <div className="col-span-1 justify-between rounded-2xl bg-primary-600 p-6">
        <p className="flex-none text-3xl font-bold tracking-tight text-white">
          {listofAppointments.reduce((acc: number, obj: AppointmentForUI) => {
            if (obj.status === AppointmentStatuses.Canceled) acc++;
            return acc;
          }, 0)}
        </p>
        <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
          <p className="text-lg font-semibold tracking-tight text-white">
            Canceled
          </p>
        </div>
      </div>
      <div className="col-span-1 justify-between rounded-2xl bg-primary-600 p-6">
        <p className="flex-none text-3xl font-bold tracking-tight text-white">
          {listofAppointments.reduce((acc: number, obj: AppointmentForUI) => {
            if (obj.status === AppointmentStatuses.NoShow) acc++;
            return acc;
          }, 0)}
        </p>
        <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
          <p className="text-lg font-semibold tracking-tight text-white">
            No shows
          </p>
        </div>
      </div>
    </div>
  );
};

export default SchedulePatientHistory;
