import {
  AppointmentForUI,
  Appointments,
  AvailableSlotsResponse,
  DisciplineTreatment,
} from '@chiroup/core';
import React from 'react';
import ScheduleEventsUser from './ScheduleEventsUser';

type Props = {
  appointments: Appointments;
  days: {
    day: string;
    dayName: string;
    fullDate: string;
    prop: string;
  }[];
  users: {
    id: string;
    name: string;
  }[];
  dragging: boolean;
  width: number;
  availableSlots: AvailableSlotsResponse | null;
  selectedTreatment: DisciplineTreatment | null;
  minMaxTime: {
    minTime: number;
    maxTime: number;
  };
  scheduleApptFromDoubleClick: (
    clinician: string,
    startTime: number,
    date: string,
  ) => void;
};

const ScheduleEvents: React.FC<Props> = ({
  days,
  users,
  appointments,
  dragging,
  width,
  availableSlots,
  selectedTreatment,
  minMaxTime,
  scheduleApptFromDoubleClick,
}) => {
  return (
    <div className="flex w-full">
      {days.map((day, dayIndex) => (
        <div key={`day-${dayIndex}`} className="flex flex-row">
          {users.map((user, userIndex) => (
            <ScheduleEventsUser
              key={`user-${userIndex}`}
              day={day.prop}
              userId={user.id}
              appointments={
                appointments[day.prop]?.[user.id as any]
                  ?.appointments as AppointmentForUI[]
              }
              available={appointments?.[day.prop]?.[user.id]?.hours}
              dragging={dragging}
              width={width}
              availableSlots={availableSlots?.[day.fullDate]?.[user.id] || null}
              selectedTreatment={selectedTreatment}
              disableEditing={!!availableSlots}
              minMaxTime={minMaxTime}
              scheduleApptFromDoubleClick={scheduleApptFromDoubleClick}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default ScheduleEvents;
