import { FormError } from '@chiroup/core';
import React from 'react';

type Props = {
  errors?: FormError;
  className?: string;
};

export const FieldErrors: React.FC<Props> = ({ errors, className = '' }) => {
  return errors ? (
    <p
      className={['mt-2 text-sm text-red-600', className].join(' ')}
      id="email-error"
    >
      {errors.message}
    </p>
  ) : null;
};
