import {
  Autocomplete,
  FieldErrors,
  Input,
  Select,
  Toggle,
} from '@chiroup/components';
import {
  FormErrors,
  InstanceKeys,
  Insurance,
  PatientInsuranceTypes,
  Payor,
  PrimaryInsuredTypes,
  enumToArrayOfOptions,
} from '@chiroup/core';
import { ValueOf } from '@visx/scale';
import { useEffect, useRef } from 'react';
import useDatabaseItem from '../../../../../components/settings/database/useDatabaseItem';
import { INSURANCE_TYPE_CODES } from '../../../../../constants';
import DatabaseAutocomplete from '../../../../settings/database/DatabaseAutocomplete';
import PrimaryInsuredFormFields from './PrimaryInsuredFormFields';

type Props = {
  value: Partial<Insurance>;
  onChange: (
    key: keyof Partial<Insurance>,
  ) => (val: ValueOf<Insurance>) => void;
  patchValue: (values: Partial<Insurance>) => void;
  errors: FormErrors;
};

const PatientInsuranceFormFields: React.FC<Props> = ({
  value,
  onChange,
  patchValue,
  errors,
}) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLInputElement>(null);
  const { data, isFetching } = useDatabaseItem<Payor>({
    instanceKey: 'payors',
    id: Number(value?.payor) || -1,
  });

  const showMedicareInsuranceTypeCode =
    data?.legalName === 'Medicare' ||
    data?.insuranceProgram === 'mcarea' ||
    data?.insuranceProgram === 'mcareb' ||
    data?.insuranceProgram === 'hmomedicare';

  useEffect(() => {
    if (!showMedicareInsuranceTypeCode && !isFetching) {
      patchValue({ insuranceTypeCode: '' });
    }
  }, [showMedicareInsuranceTypeCode, patchValue, isFetching]);

  const onPayorChange = (val: any) => {
    if (val?.text && !value?.name) {
      const chunks = val.text.split(/ - /);
      chunks.pop();
      onChange('name')(chunks.join(' - '));
    }
  };
  return (
    <>
      {isFetching ? (
        <Autocomplete
          label="Payor *"
          className="col-span-4"
          loading
          isFetching
          name="payor"
          onChange={() => {}}
          onChangeSearch={() => {}}
        />
      ) : (
        <DatabaseAutocomplete
          value={(value?.payor || '') as string}
          label="Payor *"
          onChange={onChange('payor')}
          className="col-span-4"
          instanceKey={InstanceKeys.payors}
          buttonRef={buttonRef}
          searchRef={searchRef}
          onChangeCallback={onPayorChange}
          error={errors?.fieldErrors?.payor}
          initialDisplayValue={
            data?.legalName ? `${data.legalName} - ${data.payorID}` : ''
          }
          disabled={!!value.id && value.payor !== null}
        />
      )}
      <Input
        name="name"
        value={value.name}
        onChange={onChange('name')}
        label="Insurance Plan/Program Name *"
        className="col-span-2"
        errors={errors?.fieldErrors?.name}
      />
      <Input
        name="internalName"
        value={value.internalName}
        onChange={onChange('internalName')}
        label="Internal Name"
        className="col-span-2"
        errors={errors?.fieldErrors?.internalName}
      />
      <Select
        name="type"
        value={value.type}
        onChange={onChange('type')}
        options={enumToArrayOfOptions(PatientInsuranceTypes)}
        label="Insurance Type"
        className="col-span-2"
        limit={1}
        errors={errors?.fieldErrors?.type}
      />
      <Input
        name="memberId"
        value={value.memberId}
        onChange={onChange('memberId')}
        label="Member ID Number *"
        className="col-span-2"
        errors={errors?.fieldErrors?.memberId}
      />
      {showMedicareInsuranceTypeCode && (
        <Select
          name="insuranceTypeCode"
          value={value.insuranceTypeCode}
          onChange={onChange('insuranceTypeCode')}
          options={INSURANCE_TYPE_CODES}
          label="Insurance Type Code"
          className="col-span-4"
          limit={1}
          selectedField="value"
          showTheWholeOptionText={true}
          errors={errors?.fieldErrors?.type}
        />
      )}
      <Input
        name="planGroupId"
        value={value.planGroupId}
        onChange={onChange('planGroupId')}
        label="Plan ID or Group ID"
        className="col-span-2"
        errors={errors?.fieldErrors?.planGroupId}
      />
      <Select
        name="primaryInsuredType"
        value={value.primaryInsuredType}
        onChange={onChange('primaryInsuredType')}
        options={enumToArrayOfOptions(PrimaryInsuredTypes)}
        label="Primary Insured *"
        className="col-span-2"
        limit={1}
        errors={errors?.fieldErrors?.primaryInsuredType}
      />
      {value.primaryInsuredType !== PrimaryInsuredTypes.Patient && (
        <PrimaryInsuredFormFields value={value} patchValue={patchValue} />
      )}
      <Toggle
        label="Active"
        value={!!value.active}
        onChange={(e) => {
          onChange('active')(e ? 1 : 0);
        }}
      />
      {errors?.fieldErrors?.active && (
        <FieldErrors
          errors={errors?.fieldErrors?.active}
          className="col-span-4"
        />
      )}
    </>
  );
};
export default PatientInsuranceFormFields;
