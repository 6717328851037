import {
  ErrorResponse,
  hasProperty,
  HasRoleOptions,
  ListClinic,
  Me,
  MeClinic,
  Plan,
  User,
  UserRoles,
  ValueOf,
} from '@chiroup/core';
import * as Sentry from '@sentry/react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { ToastContext } from '../contexts/toast.context';
import userService from '../services/user.service';

declare const chmln: any;

const getMeQuery = () => {
  return async (context: QueryFunctionContext) => {
    return userService.me();
  };
};

const useMe = (plan?: Plan) => {
  const queryClient = useQueryClient();
  const [meId, setMeId] = useState<string>();
  const { createToast } = useContext(ToastContext);
  const [downForMaintenance, setDownForMaintenance] = useState<{
    title: string;
    message: string;
  } | null>(null);
  const navigate = useNavigate();
  const { data: me } = useQuery<Me | undefined, ErrorResponse>(
    ['me'],
    getMeQuery(),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (err) => {
        if (err?.response?.data?.statusCode === 503) {
          setDownForMaintenance(err?.response?.data as any);
        }
      },
    },
  );

  const activeLocations = useMemo(() => {
    if (me?.selectedClinic?.locations?.length) {
      return me?.selectedClinic?.locations?.filter(
        (location) => location.active,
      );
    }
    return [];
  }, [me]);

  const clearSelectedClinic = useCallback(
    (user: User) => {
      if (user?.ID) {
        try {
          localStorage.setItem(`${user.ID}:selectedClinic`, 'none');
        } catch (err) {
          console.error(err);
        }
      }
      queryClient.setQueryData(['me'], (prev) => {
        return prev || user
          ? {
              ...(prev || user),
            }
          : undefined;
      });
      setMeId(user?.ID);
      navigate('/');
    },
    [navigate, queryClient],
  );

  useEffect(() => {
    if (!me) {
      return;
    }
    Sentry.setUser({ id: me?.ID, email: me?.email });
    let lsSelectedClinic: string | null = null,
      lsSelectedLocation: string | null = null;
    try {
      lsSelectedClinic = localStorage.getItem(`${me?.ID}:selectedClinic`);
      lsSelectedLocation = localStorage.getItem(
        `${me?.ID}:${lsSelectedClinic}:selectedLocation`,
      );
    } catch (err) {
      console.error(err);
    }
    if (lsSelectedClinic === 'none') {
      setMeId(me?.ID);
      return;
    } else if (!lsSelectedClinic) {
      setMeId(me?.ID);
      if (me?.clinics?.length === 1) {
        try {
          localStorage.setItem(
            `${me?.ID}:selectedClinic`,
            String(me?.clinics?.[0]?.ID),
          );
        } catch (err) {
          console.error(err);
        }
        queryClient.setQueryData(['me'], {
          ...me,
          supervisor: me.clinics[0].clinicians?.find((c) => c.ID === me.ID)
            ?.supervisor,
          encounterSupervision: me.clinics[0].clinicians?.find(
            (c) => c.ID === me.ID,
          )?.encounterSupervision,
          billingSupervision: me.clinics[0].clinicians?.find(
            (c) => c.ID === me.ID,
          )?.billingSupervision,
          selectedClinic: me.clinics[0],
        });
      }
      const alreadyOnRootOrCreateClinic =
        window.location.pathname === '/' ||
        window.location.pathname === '/create-clinic';
      if (!alreadyOnRootOrCreateClinic) {
        navigate(me?.clinics?.length ? '/' : '/create-clinic');
      }
    } else {
      let selectedClinic: number | null | ListClinic | MeClinic | undefined =
        lsSelectedClinic ? +lsSelectedClinic : null;
      const clinics: ListClinic[] = me?.clinics || [];
      if (!selectedClinic && clinics.length === 1) {
        selectedClinic = me?.clinics?.[0];
        try {
          localStorage.setItem(
            `${me?.ID}:selectedClinic`,
            String(me?.clinics?.[0]?.ID),
          );
        } catch (err) {
          console.error(err);
        }
      } else if (selectedClinic) {
        const selectedClinicObj = clinics.find(
          (clinic) => selectedClinic && clinic.ID === +selectedClinic,
        );
        if (selectedClinicObj) {
          selectedClinic = selectedClinicObj;
        } else {
          selectedClinic = null;
        }
      }
      if (selectedClinic) {
        const clinicObj =
          typeof selectedClinic === 'number'
            ? clinics.find(
                (clinic) => selectedClinic && clinic.ID === selectedClinic,
              )
            : selectedClinic;
        const activeLocs = clinicObj?.locations?.filter((loc) => loc.active);
        let locationToSelect =
          lsSelectedLocation &&
          activeLocs?.find(
            (location) =>
              lsSelectedLocation && location?.ID === +lsSelectedLocation,
          )
            ? Number(lsSelectedLocation)
            : null;
        if (!locationToSelect && typeof selectedClinic !== 'number') {
          const primaryLocationId = selectedClinic?.locations?.find(
            (location) => location?.primary,
          )?.ID;
          locationToSelect = primaryLocationId || null;
        }
        queryClient.setQueryData(['me'], {
          ...me,
          supervisor: isMeClinic(selectedClinic)
            ? selectedClinic.clinicians?.find((c) => c.ID === me.ID)?.supervisor
            : undefined,
          encounterSupervision: isMeClinic(selectedClinic)
            ? selectedClinic.clinicians?.find((c) => c.ID === me.ID)
                ?.encounterSupervision
            : undefined,
          billingSupervision: isMeClinic(selectedClinic)
            ? selectedClinic.clinicians?.find((c) => c.ID === me.ID)
                ?.billingSupervision
            : undefined,
          selectedClinic,
          selectedLocation: locationToSelect,
        });
        setMeId(me?.ID);
        if (
          typeof selectedClinic !== 'number' &&
          selectedClinic?.subscription?.status !== 'active' &&
          selectedClinic?.subscription?.status !== 'trialing' &&
          selectedClinic?.subscription?.status !== 'past_due'
        ) {
          return navigate('/settings/subscription');
        }
      } else {
        clearSelectedClinic(me as any);
      }
    }
    if (plan) {
      return navigate('/settings/subscription', { state: { plan } });
    }
  }, [clearSelectedClinic, createToast, me, navigate, plan, queryClient]);

  const unselectClinic = useCallback(() => {
    if (meId) {
      try {
        localStorage.setItem(`${meId}:selectedClinic`, 'none');
      } catch (err) {
        console.error(err);
      }
    }
    queryClient.setQueryData(['me'], (prev) => {
      return prev
        ? {
            ...prev,
            selectedClinic: undefined,
          }
        : undefined;
    });
    navigate('/');
  }, [meId, navigate, queryClient]);

  useEffect(() => {
    if (me?.ID && me?.selectedClinic) {
      chmln.identify(me?.ID, {
        email: me.email,
        name: me.name,
        title: me.title,
        degree: me.degree,
        fname: me.fname,
        lname: me.lname,
        phone: me.phone,
        surveyOptOut: me.surveyOptOut,
        company: {
          uid: me.selectedClinic?.ID,
          ...me.selectedClinic,
        },
      });
    }
  }, [me]);

  const updateMe = (key: keyof Me, value: ValueOf<Me>) => {
    queryClient.setQueryData(['me'], (prev) => {
      return prev
        ? {
            ...prev,
            [key]: value,
          }
        : undefined;
    });
  };

  const selectClinic = useCallback(
    (clinic: MeClinic) => {
      try {
        localStorage.setItem(`${meId}:selectedClinic`, String(clinic?.ID));
      } catch (err) {
        console.error(err);
      }
      const primaryLocationId = clinic?.locations?.find(
        (location) => location?.primary,
      )?.ID;
      try {
        localStorage.setItem(
          `${meId}:${me?.selectedClinic?.ID}:selectedLocation`,
          String(primaryLocationId),
        );
      } catch (err) {
        console.error(err);
      }
      queryClient.setQueryData(['me'], (prev) => {
        return prev
          ? {
              ...prev,
              selectedClinic: clinic,
              selectedLocation: primaryLocationId,
            }
          : undefined;
      });
      if (clinic?.subscription?.status !== 'active') {
        navigate('/settings/subscription');
      }
    },
    [meId, me?.selectedClinic?.ID, navigate, queryClient],
  );

  const selectLocation = useCallback(
    (location: number) => {
      if (!me?.selectedClinic) return;
      try {
        localStorage.setItem(
          `${meId}:${me?.selectedClinic?.ID}:selectedLocation`,
          String(location),
        );
      } catch (err) {
        console.error(err);
      }
      queryClient.setQueryData(['me'], (prev) => {
        return prev
          ? {
              ...prev,
              selectedLocation: location,
            }
          : undefined;
      });
    },
    [meId, me?.selectedClinic, queryClient],
  );

  const hasAccess = (flag: string) => {
    return me?.selectedClinic?.flags?.[flag] || false;
  };

  const hasRole = (role: UserRoles[], option?: HasRoleOptions) => {
    if (option === HasRoleOptions.HasAllRoles) {
      return role.every((r) => me?.selectedClinic?.role?.includes(r));
    }
    if (option === HasRoleOptions.HasSingleRole) {
      return (
        me?.selectedClinic?.role?.length === 1 &&
        me?.selectedClinic?.role[0] === role[0]
      );
    }
    return role.some((r) => me?.selectedClinic?.role?.includes(r));
  };

  const selectedLocationFull = useMemo(() => {
    const fullSelectedLocation = activeLocations?.find(
      (loc) => loc.ID === me?.selectedLocation,
    );
    const primaryLocation = activeLocations?.find((loc) => loc.primary);
    return {
      ID: fullSelectedLocation?.ID,
      name: fullSelectedLocation?.name,
      primaryName: primaryLocation?.name,
      address1: fullSelectedLocation?.address1,
      address2: fullSelectedLocation?.address2,
      city: fullSelectedLocation?.city,
      state: fullSelectedLocation?.state,
      country: fullSelectedLocation?.country,
      zip: fullSelectedLocation?.zip,
      phone: fullSelectedLocation?.phone,
      fax: fullSelectedLocation?.fax,
      logo: fullSelectedLocation?.logo || '',
      timezone: fullSelectedLocation?.timezone,
      email: fullSelectedLocation?.email,
      site: fullSelectedLocation?.site,
      primaryLogo: primaryLocation?.logo || '',
      serviceTaxRate: fullSelectedLocation?.serviceTaxRate,
      productTaxRate: fullSelectedLocation?.productTaxRate,
      allLocations: activeLocations,
    };
  }, [me?.selectedLocation, activeLocations]);

  return {
    me,
    updateMe,
    unselectClinic,
    selectClinic,
    hasAccess,
    selectLocation,
    activeLocations,
    selectedLocationFull,
    hasRole,
    downForMaintenance,
  };
};

export default useMe;

export const isMeClinic = (obj: any): obj is MeClinic => {
  return (
    //Probably is a me clinic
    obj !== null &&
    typeof obj === 'object' &&
    hasProperty(obj, 'ID') &&
    typeof obj.ID === 'number' &&
    hasProperty(obj, 'clinicians') &&
    Array.isArray(obj.clinicians) &&
    hasProperty(obj, 'locations') &&
    Array.isArray(obj.locations)
  );
};
