import { Autocomplete, SelectOption } from '@chiroup/components';
import { CodeSets, FormError } from '@chiroup/core';
import { useCodes } from '@chiroup/hooks';
import React, { useEffect, useMemo } from 'react';

type Props = {
  codeSet: CodeSets | CodeSets[];
  value: string;
  onChange: (value: string) => void;
  onChangeDescription: (option: any) => void;
  onEmptyOptions?: () => void;
  error?: FormError;
  searchRef?: React.RefObject<HTMLInputElement>;
  buttonRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  noneText?: string;
  weighted?: boolean;
  endpoint?: string;
  descriptionAsString?: boolean;
  initialOptions?: SelectOption[];
  disabled?: boolean;
  label?: string;
  clinicId?: number;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  autoFocus?: boolean;
  local?: boolean;
  clearable?: boolean;
  limit?: number;
};

export const CodeAutocomplete: React.FC<Props> = ({
  codeSet,
  value,
  onChange,
  onChangeDescription,
  error,
  searchRef,
  buttonRef,
  onEmptyOptions,
  className,
  noneText,
  weighted,
  endpoint,
  descriptionAsString = true,
  initialOptions,
  disabled,
  label,
  clinicId,
  search,
  setSearch,
  autoFocus,
  local = false,
  clearable = false,
  limit = 1,
}) => {
  const {
    options,
    setOptions,
    fetchNextPage,
    isFetching,
    hasNextPage,
    restError,
    setRestError,
  } = useCodes({ type: codeSet, clinicId, weighted, endpoint, search, local });

  const showLabel = typeof label === 'string' ? true : false;
  const onSelect = (code: string) => {
    onChange(code);
    const theOne = options.find((o) => o.value === code);
    onChangeDescription(
      descriptionAsString ? theOne?.data?.description : theOne,
    );
  };

  useEffect(() => {
    if (!!initialOptions && initialOptions.length !== 0) {
      setOptions(initialOptions);
    }
  }, [initialOptions, setOptions]);

  const onChangeSearch = (val: string) => {
    setSearch(val);
  };

  const errorMessage = useMemo(() => {
    if (!restError) return '';
    return (
      restError?.response?.data?.error?.message ??
      restError?.response?.data?.message
    );
  }, [restError]);

  return (
    <Autocomplete
      className={className || 'col-span-8 -mt-2'}
      // label={InstanceSchema.billingCodes.code.label}
      label={typeof label === 'string' ? label : ``}
      showLabel={showLabel}
      name="referenceCode"
      onChange={onSelect}
      value={value}
      errors={error}
      onChangeSearch={onChangeSearch}
      options={options}
      onEmptyOptions={onEmptyOptions}
      serverSide
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      hasNextPage={hasNextPage}
      searchTerm={search}
      noneText={noneText || 'Type to search...'}
      searchRef={searchRef}
      buttonRef={buttonRef}
      addOptionTitles={true}
      disabled={disabled}
      autoFocus={autoFocus}
      errorMessage={errorMessage}
      errorCloseCallback={() => setRestError(null)}
      clearable={clearable}
      limit={limit}
    />
  );
};
