/**
 * [2023-07-27.0834 by Brian] The purpose of this module is to encapsulate
 * all the settings that are saved in the ClinicExtendedSettings table.
 *
 * The idea is that all the specifics of the extended settings are here:
 * Types, default values, property names,... Of course, it could be over
 * engineered, but I hope not! Just didn't want the dev to have to make
 * a lot of changes in a lot of places if a new setting is added.
 */
import { CommunicationMethodTypes } from '../enums';
import {
  MeClinic,
  SelectOptionType,
  getExtendedSettingValueByKey,
} from '../types';

export type CommunicationDefaultsType = {
  defaultValue: string;
  property: string;
  preferenceType: string;
  input: {
    label: string;
    tooltip: string;
  };
  options: SelectOptionType[];
};

export const CommunicationConfig: CommunicationDefaultsType = {
  defaultValue: CommunicationMethodTypes.text,
  property: 'clinicCommunicationsDefault',
  preferenceType: 'ClinicExtendedSettings',
  input: {
    label: 'Default Communication Method',
    tooltip:
      'This sets the default communication method for patients without a preference.',
  },
  options: [
    {
      value: CommunicationMethodTypes.text,
      label: 'SMS',
      default: true,
    },
    { value: CommunicationMethodTypes.email, label: 'Email' },
  ],
};

/**
 * Hopefully, this can be extended to support multiple extended settings.
 */
export const ExtendedSettingsConfig = {
  [CommunicationConfig.property]: CommunicationConfig,
};

export type ClinicExtendedGeneralSettings = {
  clinicCommunicationsDefault?: string;
};

/**
 * This is reverse lookup hash so when you have a value, you can
 * get the human-readable label. Will probably be of use to HC
 * backend when it needs to honor some of these settings.
 */
export const CommunicationLabelByValue: { [key: string]: string } =
  CommunicationConfig.options.reduce((acc, obj) => {
    return { ...acc, [obj.value]: obj.label };
  }, {});

export const getClinicCommunicationsDefault = (
  clinic: MeClinic | undefined,
): string => {
  let retval = CommunicationConfig.defaultValue;

  if (clinic) {
    const val = getExtendedSettingValueByKey(
      clinic,
      CommunicationConfig.property,
    );
    if (typeof val !== 'undefined') retval = val;
  }

  return retval;
};

const extendedSettingsByName: { [key: string]: boolean } = {};
extendedSettingsByName[CommunicationConfig.property] = true;
extendedSettingsByName['ccFee'] = true;
extendedSettingsByName['invoiceStartNumber'] = true;
extendedSettingsByName['invoicePadding'] = true;
extendedSettingsByName['paymentStartNumber'] = true;
extendedSettingsByName['paymentPadding'] = true;
extendedSettingsByName['signedNoteTimestamp'] = true;

/**
 * This is how the clinic service detects extended settings so
 * they are saved separate from the column-based settings in the
 * main Clinic table. It is used so that one call to _save_ will
 * save both types of settings.
 *
 * @param key
 * @returns
 */
export const isExtendedClinicSetting = (key: string) => {
  return extendedSettingsByName[key];
};

export const ClinicExtendedSettings = {
  general: {
    setting: 'General',
    section: 'Extended Clinic Settings',
    properties: [
      CommunicationConfig.property,
      'invoiceStartNumber',
      'invoicePadding',
      'paymentStartNumber',
      'paymentPadding',
      'ccFee',
      'signedNoteTimestamp',
    ],
  },
  appointments: {
    setting: 'Appointment Settings',
    section: 'Scheduling Preferences',
    properties: ['sameDayScheduling', 'appointmentCancellationPolicy'],
  },
};

export const getExtendedFormFieldsByKey = (
  clinic: MeClinic | undefined,
  key: string,
) => {
  const retval: { [key: string]: any } = {};
  const whichSettings =
    ClinicExtendedSettings[key as keyof typeof ClinicExtendedSettings];
  if (whichSettings) {
    const props = whichSettings.properties || [];
    props.forEach((prop) => {
      retval[prop] = getExtendedValueOrDefaultByKey(clinic, prop);
    });
  }

  return retval;
};

export const getExtendedValueOrDefaultByKey = (
  clinic: MeClinic | undefined,
  key: string,
): string => {
  let retval = ExtendedSettingsConfig[key]?.defaultValue;

  if (clinic) {
    const val = getExtendedSettingValueByKey(
      clinic,
      ExtendedSettingsConfig[key]?.property || key,
    );
    if (typeof val !== 'undefined') retval = val;
  }

  return retval;
};
