import {
  Input,
  OpenClosedStates,
  Phone,
  RadioGroup,
  Select,
} from '@chiroup/components';
import {
  COUNTRY_CODES,
  Communication,
  CommunicationMethodTypes,
  FeatureFlags,
  Patient,
  surveyIds,
} from '@chiroup/core';
import { useForm } from '@chiroup/hooks';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MeContext } from '../../../contexts/me.context';
import { ToastContext, ToastTypes } from '../../../contexts/toast.context';
import useLocalStorage, { LSType } from '../../../hooks/useLocalStorage';
import useSurveys from '../../../hooks/useSurveys';
import userService from '../../../services/user.service';
import Button, { ButtonColors } from '../../common/Button';
import Modal from '../../common/Modal';
import ValidEmailAlert from '../../common/ValidEmailAlert';
import useConsents from '../../practice-resources/hooks/useConsents';
import { SendSurveysFormValues } from '../hooks/usePatientCommunications';
import Alert from '../../common/Alert';
import {
  ExclamationTriangleIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/24/outline';

const validation = {
  surveys: {
    required: {
      message: 'Survey is required.',
    },
    function: {
      value: (value: Partial<SendSurveysFormValues>) => {
        if (!value.surveys?.length) {
          return 'Must select at least one survey.';
        }
        return false;
      },
    },
  },
  method: {
    required: {
      message: 'Destination is required.',
    },
  },
  methodValue: {
    required: {
      // TODO: this should be fn
      message: 'Destination value is required.',
    },
  },
  // May add back for kiosk/EHR combo
  // dateOfBirth: {
  //   function: {
  //     value: (value: Partial<SendSurveysFormValues>) => {
  //       const { dateOfBirth, methodValue } = value;
  //       if (!dateOfBirth && methodValue === CommunicationMethodTypes.text) {
  // 				return 'Phone number must be 7 digits.';
  // 			}
  //     },
  //   },
  // },
  phone: {
    function: {
      value: (value: Partial<SendSurveysFormValues>) => {
        const { phone } = value;
        if (phone) {
          if (
            phone?.[1] === '1' &&
            phone?.split(' ')?.[0]?.length === 6 &&
            phone?.length < 13
          ) {
            return 'Phone number must be 7 digits.';
          } else if (phone?.[1] === '1' && phone?.length < 13) {
            return 'Phone number must be 10 digits.';
          }
        }
        return false;
      },
    },
  },
};

type Props = {
  state: OpenClosedStates;
  setState: React.Dispatch<React.SetStateAction<OpenClosedStates>>;
  patientId: string;
  patientPhone?: string;
  patientEmail?: string;
  patientDateOfBirth?: string;
  patientPrefers?: CommunicationMethodTypes;
  sendSurveys: any;
  updatePatientCache?: (newData: Partial<Patient>) => void;
  encounterId?: string;
  complaintStepper?: boolean;
  onProviderAddComplaint?: (survey: Communication) => void;
  ccsSurveyId?: string;
  updatePatientEncounterCommunicationCache?: (arg: Communication) => void;
  optOutSMS: boolean;
};

const PatientSendSurveyModal: React.FC<Props> = ({
  state,
  setState,
  patientId,
  patientPhone,
  patientEmail,
  patientDateOfBirth,
  patientPrefers,
  sendSurveys,
  updatePatientCache,
  encounterId,
  complaintStepper = false,
  onProviderAddComplaint,
  ccsSurveyId,
  updatePatientEncounterCommunicationCache,
  optOutSMS,
}) => {
  const { me, hasAccess } = useContext(MeContext);
  const { value, registerSubmit, errors, onChange, isSubmitting, patchValue } =
    useForm<SendSurveysFormValues, Communication>(
      {
        surveys: [],
        method:
          patientPrefers === CommunicationMethodTypes.email
            ? CommunicationMethodTypes.email
            : CommunicationMethodTypes.text,
        methodValue:
          patientPrefers === CommunicationMethodTypes.email
            ? patientEmail ?? ''
            : patientPhone ?? '',
        requestInsuranceCard: false,
        requestInsurance: false,
        requestPhotoId: false,
        requestInfo: false,
        phone: patientPhone || '',
        dateOfBirth: patientDateOfBirth || '',
        encounterId: encounterId,
      },
      validation,
    );
  const { getItem, setItem } = useLocalStorage();
  const { data } = useSurveys();
  const { data: consents } = useConsents();
  const { createToast } = useContext(ToastContext);
  const [needMethodValue, setNeedMethodValue] = useState(false);
  const [requestInfoItems, setRequestInfoItems] = useState<any[]>();
  const [notValidEmail, setNotValidEmail] = useState<boolean>(false);
  const [emailJustChecked, setEmailJustChecked] = useState<string>('');
  const emailInvalid = useRef<boolean>(false);
  const [providerSurvey, setProviderSurvey] = useState<boolean>(false);

  const patientInfo = useMemo(() => {
    const patientInfoArr = [
      { name: 'Basic intake group', group: 'Groups', id: 'basicIntakeGroup' },
      {
        name: 'Detailed intake group',
        group: 'Groups',
        id: 'detailedIntakeGroup',
      },
      {
        name: 'Photo ID',
        group: 'Intake & history surveys',
        id: 'requestPhotoId',
      },
      {
        name: 'Patient demographics',
        group: 'Intake & history surveys',
        id: 'requestInfo',
      },
    ];

    if (hasAccess(FeatureFlags.billingInsurance)) {
      patientInfoArr.push({
        name: 'Insurance',
        group: 'Intake & history surveys',
        id: 'requestInsurance',
      });
    } else {
      patientInfoArr.push({
        name: 'Insurance card',
        group: 'Intake & history surveys',
        id: 'requestInsuranceCard',
      });
    }

    return patientInfoArr;
  }, [hasAccess]);

  useEffect(() => {
    setNeedMethodValue(false);
    if (!patientEmail && !patientPhone) {
      setNeedMethodValue(true);
      patchValue({
        method: 'text',
        methodValue: undefined,
        phone: undefined,
      });
    } else if (patientPrefers === 'text' && patientPhone) {
      patchValue({
        method: 'text',
        methodValue: patientPhone,
        phone: patientPhone,
      });
    } else if (patientPrefers === 'email' && patientEmail) {
      patchValue({
        method: 'email',
        methodValue: patientEmail,
        phone: patientPhone,
      });
    } else {
      setNeedMethodValue(true);
    }
  }, [patientPrefers, patientPhone, patientEmail, state, patchValue]);

  useEffect(() => {
    if (data) {
      const hiddenSurveys: string[] =
        me?.selectedClinic?.settings?.find(
          (clinic) => clinic.setting === 'Hidden surveys',
        )?.jsonValue || [];

      const filteredData: {
        name: string;
        group: string;
        id: string;
      }[] = data?.data?.filter((item) => !hiddenSurveys?.includes(item.id));

      let items = [...(patientInfo || []), ...(filteredData || [])];
      if (consents?.length) {
        items = [
          ...items,
          ...(consents?.map((consent) => ({
            name: consent.name,
            group: 'Consents & Acknowledgement surveys',
            id: `consent::${consent.url}`,
          })) || []),
        ];
      }
      items.sort((a, b) => {
        if (a.group === 'Groups' && b.group !== 'Groups') {
          return -1;
        } else if (a.group !== 'Groups' && b.group === 'Groups') {
          return 1;
        }
        const groupComparison = a.group.localeCompare(b.group);
        if (groupComparison !== 0) {
          return groupComparison;
        }
        return a.name.localeCompare(b.name);
      });
      setRequestInfoItems(items);
    }
  }, [
    data,
    consents,
    me?.selectedClinic?.settings,
    patientInfo,
    patientPrefers,
  ]);

  const onSuccess = (val: any) => {
    try {
      let currentPatient;
      const recentPatients = getItem(LSType.clinic, 'recentPatients');
      const currentPatientIndex = recentPatients?.findIndex(
        (recentPatient: any) => recentPatient.ID === patientId,
      );

      if (currentPatientIndex > -1) {
        currentPatient = recentPatients[currentPatientIndex];
      }
      const params: any = {};

      if (
        val.method === CommunicationMethodTypes.text ||
        val.method === CommunicationMethodTypes.email
      ) {
        if (val.method === CommunicationMethodTypes.email) {
          params.email = val.methodValue;
          if (currentPatientIndex > -1) {
            currentPatient.email = val.methodValue;
          }
        } else {
          params.phone = val.methodValue;
          if (currentPatientIndex > -1) {
            currentPatient.phone = val.methodValue;
          }
        }
      }

      if (val.phone) {
        params.phone = val.phone;
        currentPatient.phone = val.phone;
        if (currentPatientIndex > -1) {
          currentPatient.phone = val.phone;
        }
      }
      if (val.dateOfBirth) {
        params.dateOfBirth = val.dateOfBirth;
        if (currentPatientIndex > -1) {
          currentPatient.age = dayjs().diff(dayjs(val.dateOfBirth), 'year');
        }
      }

      updatePatientCache?.(params);

      if (currentPatientIndex > -1) {
        recentPatients[currentPatientIndex] = currentPatient;
        setItem(LSType.clinic, 'recentPatients', recentPatients);
      }
    } catch (err) {
      // Not the end of the world if this fails.
      console.error({ err });
    }

    createToast({
      title: 'Success!',
      description: <>Successfully sent request!</>,
      type: ToastTypes.Success,
      duration: 5000,
    });
    closeModal();
  };

  useEffect(() => {
    const isOpenState = state === OpenClosedStates.Open;
    const shouldSetProviderSurvey = complaintStepper && isOpenState;

    const updateSurveysAndSetProviderSurvey = (surveyId: string) => {
      patchValue({ surveys: [surveyId] });
      setProviderSurvey(true);
    };

    if (shouldSetProviderSurvey) {
      if (!ccsSurveyId) {
        updateSurveysAndSetProviderSurvey(surveyIds.ccs);
      } else {
        updateSurveysAndSetProviderSurvey(surveyIds.additionalComplaintSurvey);
      }
    } else {
      setProviderSurvey(false);
    }
  }, [complaintStepper, state, ccsSurveyId, patchValue]);

  useEffect(() => {
    if (value?.surveys?.includes(surveyIds.ccs)) {
      const followupSettings = me.selectedClinic?.settings?.find(
        (setting) => setting.setting === 'New patient preferences',
      );
      if (followupSettings?.jsonValue) {
        const followups = Object.keys(followupSettings?.jsonValue)?.reduce(
          (arr: string[], setting: any) => {
            if (followupSettings?.jsonValue?.[setting]) {
              if (setting === 'ndi') {
                arr.push(surveyIds.ndi);
              }
              if (setting === 'lefs') {
                arr.push(surveyIds.lefs);
              }
              if (setting === 'quickDash') {
                arr.push(surveyIds.dash);
              }
              if (setting === 'oswestry') {
                arr.push(surveyIds.oswestry);
              }
            }
            return arr;
          },
          [],
        );
        patchValue({ ccsFollowups: followups });
      }
    }
  }, [value.surveys, patchValue, me.selectedClinic?.settings]);

  const onFail = (err: any) => {
    if (err?.message === 'invalid_email') return;
    const errorMessage =
      err.response?.data?.message || 'Failed to send request!';
    createToast({
      title: 'Error!',
      description: errorMessage,
      type: ToastTypes.Fail,
      duration: 5000,
    });
  };

  const onSubmit = async (
    val: Partial<SendSurveysFormValues>,
  ): Promise<Communication | undefined> => {
    emailInvalid.current = false;
    setNotValidEmail(false);
    if (
      val?.method === CommunicationMethodTypes.email &&
      val?.methodValue !== '' &&
      emailJustChecked !== val?.methodValue &&
      !patientEmail
    ) {
      setEmailJustChecked(val?.methodValue || '');
      const zbResult = await userService.checkIfValidEmail(
        val.methodValue || '',
      );
      if (zbResult?.data?.status?.toLowerCase() === 'invalid') {
        emailInvalid.current = true;
        setNotValidEmail(true);
        throw new Error('invalid_email');
      }
    }

    let surveysToSend = [...(val.surveys || [])];
    const specialRequests = {
      requestInsurance: false,
      requestInsuranceCard: false,
      requestInfo: false,
      requestPhotoId: false,
    };
    if (surveysToSend?.includes('requestInfo')) {
      specialRequests.requestInfo = true;
    }
    if (surveysToSend?.includes('requestInsuranceCard')) {
      specialRequests.requestInsuranceCard = true;
    }
    if (surveysToSend?.includes('requestPhotoId')) {
      specialRequests.requestPhotoId = true;
    }
    if (surveysToSend?.includes('requestInsurance')) {
      specialRequests.requestInsurance = true;
    }
    surveysToSend = surveysToSend?.filter((survey, i) => {
      switch (survey) {
        case 'requestInfo':
          return false;
        case 'requestInsurance':
          return false;
        case 'requestInsuranceCard':
          return false;
        case 'requestPhotoId':
          return false;
        case 'basicIntakeGroup':
          return false;
        case 'detailedIntakeGroup':
          return false;
        default:
          return (
            !survey.startsWith('consent::') &&
            surveysToSend.indexOf(survey) === i
          );
      }
    });
    const consents = val.surveys
      ?.filter((survey) => survey.startsWith('consent::'))
      .map((survey) => survey.replace('consent::', ''));

    const dataToSend = {
      ...val,
      ...specialRequests,
      surveys: surveysToSend,
      consents,
      patientId,
      providerSurvey,
    };
    if (patientPhone) {
      delete dataToSend.phone;
    }
    if (patientDateOfBirth) {
      delete dataToSend.dateOfBirth;
    }
    const survey = await sendSurveys(dataToSend);
    updatePatientEncounterCommunicationCache?.(survey);
    if (
      val.method === CommunicationMethodTypes.myScreen &&
      onProviderAddComplaint
    ) {
      onProviderAddComplaint(survey);
    }
    return survey;
  };

  const closeModal = () => {
    patchValue({ surveys: [] });
    setState(OpenClosedStates.Closed);
  };

  const destinationOptions = useMemo(() => {
    const options = [
      {
        value: CommunicationMethodTypes.email,
        text: patientEmail ? `Email (${patientEmail})` : 'Email',
      },
      {
        value: CommunicationMethodTypes.text,
        text: patientPhone ? `Text message (${patientPhone})` : 'Text message',
      },
      {
        value: CommunicationMethodTypes.kiosk,
        text: 'Kiosk',
      },
    ];
    if (hasAccess(FeatureFlags.ehr) && complaintStepper) {
      options.push({
        value: CommunicationMethodTypes.myScreen,
        text: 'My Screen',
      });
    }
    return { options };
  }, [patientEmail, patientPhone, hasAccess, complaintStepper]);

  const handleMethodChange = (val: any) => {
    patchValue({ method: val });
    setNeedMethodValue(
      (val === CommunicationMethodTypes.text && !patientPhone) ||
        (val === CommunicationMethodTypes.email && !patientEmail),
    );
    if (patientId) {
      if (val === CommunicationMethodTypes.email) {
        patchValue({ methodValue: patientEmail ?? '' });
      } else if (val === CommunicationMethodTypes.text) {
        patchValue({ methodValue: patientPhone ?? '' });
      } else {
        patchValue({ methodValue: 'kiosk' });
      }
    }
  };

  const customIntakes = useMemo(() => {
    const customSettings = me.selectedClinic?.settings?.find(
      (setting) => setting.setting === 'Custom intakes',
    );
    return {
      basicIntakeGroup: customSettings?.jsonValue?.basicIntakeSurveys,
      detailedIntakeGroup: customSettings?.jsonValue?.detailedIntakeSurveys,
    };
  }, [me.selectedClinic?.settings]);

  return (
    <Modal isOpen={state === OpenClosedStates.Open}>
      <form>
        <div>
          <div className="flex items-center justify-center w-12 h-12 mx-auto bg-gray-100 rounded-full dark:bg-darkGray-600">
            <PaperAirplaneIcon className="w-6 h-6 text-gray-600 dark:text-darkGray-400" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg font-medium leading-6 text-gray-900 dark:text-darkGray-100"
              id="modal-headline"
            >
              Request info
            </h3>
            <div className="mt-2">
              <p className="text-sm leading-5 text-gray-500 dark:text-darkGray-400">
                Select a survey to send to the patient, or a request for
                information, and it will be delivered immediately. Requests
                expire after 7 days.
              </p>
            </div>
            <div className="pt-6 text-left">
              <Select
                name="surveys"
                className="col-span-2"
                label="Request info"
                value={value.surveys}
                onChange={(val: string[]) => {
                  if (val?.includes('basicIntakeGroup')) {
                    if (customIntakes?.basicIntakeGroup) {
                      val = val.concat(customIntakes.basicIntakeGroup);
                    } else {
                      if (!hasAccess(FeatureFlags.ehrNotes)) {
                        val = val.concat([surveyIds.ccs]);
                      }
                      val = val.concat([
                        surveyIds.healthHistory,
                        surveyIds.reviewOfSystems,
                      ]);
                    }
                  }
                  if (val?.includes('detailedIntakeGroup')) {
                    if (customIntakes?.detailedIntakeGroup) {
                      val = val.concat(customIntakes.detailedIntakeGroup);
                    } else {
                      if (!hasAccess(FeatureFlags.ehrNotes)) {
                        val = val.concat([surveyIds.ccs]);
                      }
                      val = val.concat([
                        surveyIds.healthHistory,
                        surveyIds.reviewOfSystems,
                        surveyIds.lifestyleHistory,
                        surveyIds.familyHistory,
                        surveyIds.medicationHistory,
                        surveyIds.surgicalHistory,
                        'requestInfo',
                      ]);
                    }
                  }

                  val = val.filter(
                    (surveyId) =>
                      surveyId !== 'basicIntakeGroup' &&
                      surveyId !== 'detailedIntakeGroup',
                  );

                  onChange('surveys')(val);
                }}
                errors={errors.fieldErrors?.surveys}
                options={requestInfoItems?.map((survey) => ({
                  text: survey.name,
                  value: survey.id,
                  group: survey.group,
                }))}
                disabled={complaintStepper}
                limit={complaintStepper ? 1 : undefined}
                autocomplete
              />
              <RadioGroup
                name="method"
                className="col-span-2"
                label="Destination"
                value={value.method}
                onChange={handleMethodChange}
                errors={errors.fieldErrors?.method}
                options={destinationOptions.options}
              />
              {value.method === CommunicationMethodTypes.email ? (
                <>
                  <Input
                    name="methodValue"
                    className={[
                      'col-span-2',
                      needMethodValue ? 'block' : 'hidden',
                    ].join(' ')}
                    label="Email *"
                    value={value.methodValue}
                    onChange={onChange('methodValue')}
                    errors={errors?.fieldErrors?.methodValue}
                  />{' '}
                  {notValidEmail && <ValidEmailAlert styles={'col-span-2'} />}
                </>
              ) : (
                <Phone
                  name="methodValue"
                  className={[
                    'col-span-2',
                    needMethodValue ? 'block' : 'hidden',
                  ].join(' ')}
                  label="Mobile number *"
                  value={value.methodValue}
                  onChange={onChange('methodValue')}
                  errors={errors?.fieldErrors?.methodValue}
                  countryCode={
                    COUNTRY_CODES[me.selectedClinic?.country || 'USA']
                  }
                />
              )}
            </div>
          </div>
        </div>
        {!!(optOutSMS && value.method === CommunicationMethodTypes.text) && (
          <Alert
            icon={<ExclamationTriangleIcon className="w-5 h-5 text-blue-700" />}
            className="mt-4"
          >
            <h3 className="text-sm font-medium text-left text-blue-700">
              Opted out of SMS
            </h3>
            <div className="mt-2 text-sm text-left text-blue-700">
              This patient has opted out of SMS messages. Please send this
              request via email or verify and edit the patient file to opt them
              back in.
            </div>
          </Alert>
        )}
        <div className={'mt-6 grid gap-3 grid-flow-row-dense grid-cols-2'}>
          <Button
            text="Close"
            onClick={() => closeModal()}
            fullWidth
            color={ButtonColors.plain}
            className="border border-gray-300 dark:border-darkGray-600"
          />

          <Button
            text="Send now"
            onClick={registerSubmit(onSubmit, { onSuccess, onFail })}
            fullWidth
            loading={isSubmitting}
          />
        </div>
      </form>
    </Modal>
  );
};

export default PatientSendSurveyModal;
