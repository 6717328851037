import React from 'react';
import { WebSocketMessage } from 'react-use-websocket/dist/lib/types';

export const WSContext = React.createContext<{
  addSubscription: (
    subscription: string,
    callback: (message: WebSocketMessage) => void,
  ) => void;
  removeSubscription: (subscription: string) => void;
  connectionStatus: string;
}>({
  addSubscription: () => {},
  removeSubscription: () => {},
  connectionStatus: 'Uninstantiated',
});
