import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { convertToMS } from './convertToMS';
import { ChiroUpDayJsCommon } from '../constants';

dayjs.extend(utc);
dayjs.extend(tz);

export const timestamp = () => {
  return Date.now();
};

export const TIMEZONES = [
  { text: '(GMT-12:00) International Date Line West', value: 'Etc/GMT+12' },
  { text: '(GMT-11:00) American Samoa', value: 'Pacific/Pago_Pago' },
  { text: '(GMT-11:00) Midway Island', value: 'Pacific/Midway' },
  { text: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
  { text: '(GMT-09:00) Alaska', value: 'America/Anchorage' },
  {
    text: '(GMT-08:00) Pacific Time (US & Canada)',
    value: 'America/Los_Angeles',
  },
  { text: '(GMT-08:00) Tijuana', value: 'America/Tijuana' },
  { text: '(GMT-07:00) Arizona', value: 'America/Phoenix' },
  { text: '(GMT-07:00) Mazatlan', value: 'America/Mazatlan' },
  { text: '(GMT-07:00) Mountain Time (US & Canada)', value: 'America/Denver' },
  { text: '(GMT-06:00) Central America', value: 'America/Guatemala' },
  { text: '(GMT-06:00) Central Time (US & Canada)', value: 'America/Chicago' },
  { text: '(GMT-06:00) Chihuahua', value: 'America/Chihuahua' },
  { text: '(GMT-06:00) Guadalajara', value: 'America/Guadalajara' },
  { text: '(GMT-06:00) Mexico City', value: 'America/Mexico_City' },
  { text: '(GMT-06:00) Monterrey', value: 'America/Monterrey' },
  { text: '(GMT-06:00) Saskatchewan', value: 'America/Regina' },
  { text: '(GMT-05:00) Bogota', value: 'America/Bogota' },
  { text: '(GMT-05:00) Eastern Time (US & Canada)', value: 'America/New_York' },
  { text: '(GMT-05:00) Indiana (East)', value: 'America/Indiana/Indianapolis' },
  { text: '(GMT-05:00) Lima', value: 'America/Lima' },
  { text: '(GMT-05:00) Quito', value: 'America/Guayaquil' },
  { text: '(GMT-04:00) Atlantic Time (Canada)', value: 'America/Halifax' },
  { text: '(GMT-04:00) Caracas', value: 'America/Caracas' },
  { text: '(GMT-04:00) Georgetown', value: 'America/Guyana' },
  { text: '(GMT-04:00) La Paz', value: 'America/La_Paz' },
  { text: '(GMT-04:00) Puerto Rico', value: 'America/Puerto_Rico' },
  { text: '(GMT-04:00) Santiago', value: 'America/Santiago' },
  { text: '(GMT-03:30) Newfoundland', value: 'America/St_Johns' },
  { text: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
  { text: '(GMT-03:00) Buenos Aires', value: 'America/Argentina/Buenos_Aires' },
  { text: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
  { text: '(GMT-03:00) Santiago', value: 'America/Santiago' },
  { text: '(GMT-02:00) Brasilia', value: 'America/Sao_Paulo' },
  { text: '(GMT-02:00) Mid-Atlantic', value: 'Etc/GMT+2' },
  { text: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
  { text: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
  { text: '(GMT+00:00) Casablanca', value: 'Africa/Casablanca' },
  {
    text: '(GMT+00:00) Dublin, Edinburgh, Lisbon, London',
    value: 'Europe/London',
  },
  {
    text: '(GMT+00:00) Greenwich Mean Time : Monrovia, Reykjavik',
    value: 'Etc/GMT',
  },
  {
    text: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    value: 'Europe/Berlin',
  },
  {
    text: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    value: 'Europe/Budapest',
  },
  {
    text: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
    value: 'Europe/Paris',
  },
  {
    text: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    value: 'Europe/Warsaw',
  },
  { text: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos' },
  { text: '(GMT+02:00) Athens, Bucharest', value: 'Europe/Athens' },
  { text: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
  { text: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Johannesburg' },
  {
    text: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    value: 'Europe/Helsinki',
  },
  { text: '(GMT+02:00) Istanbul', value: 'Europe/Istanbul' },
  { text: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
  { text: '(GMT+03:00) Baghdad', value: 'Asia/Baghdad' },
  { text: '(GMT+03:00) Kuwait, Riyadh', value: 'Asia/Riyadh' },
  { text: '(GMT+03:00) Minsk', value: 'Europe/Minsk' },
  {
    text: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
    value: 'Europe/Moscow',
  },
  { text: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
  { text: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
  { text: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Dubai' },
  { text: '(GMT+04:00) Baku', value: 'Asia/Baku' },
  { text: '(GMT+04:00) Caucasus Standard Time', value: 'Asia/Yerevan' },
  { text: '(GMT+04:00) Port Louis', value: 'Indian/Mauritius' },
  { text: '(GMT+04:00) Tbilisi', value: 'Asia/Tbilisi' },
  { text: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
  { text: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
  { text: '(GMT+05:00) Ekaterinburg', value: 'Asia/Yekaterinburg' },
  { text: '(GMT+05:00) Islamabad, Karachi', value: 'Asia/Karachi' },
  { text: '(GMT+05:00) Tashkent', value: 'Asia/Tashkent' },
  {
    text: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    value: 'Asia/Kolkata',
  },
  { text: '(GMT+05:30) Sri Jayawardenepura', value: 'Asia/Colombo' },
  { text: '(GMT+05:45) Kathmandu', value: 'Asia/Kathmandu' },
  { text: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty' },
  { text: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka' },
  { text: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon' },
  { text: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
  { text: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
  {
    text: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    value: 'Asia/Shanghai',
  },
  { text: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk' },
  { text: '(GMT+08:00) Kuala Lumpur, Singapore', value: 'Asia/Singapore' },
  { text: '(GMT+08:00) Perth', value: 'Australia/Perth' },
  { text: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
  { text: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo' },
  { text: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
  { text: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
  { text: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
  { text: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
  { text: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
  {
    text: '(GMT+10:00) Canberra, Melbourne, Sydney',
    value: 'Australia/Sydney',
  },
  { text: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam' },
  { text: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
  { text: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
  {
    text: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
    value: 'Asia/Magadan',
  },
  { text: '(GMT+12:00) Auckland, Wellington', value: 'Pacific/Auckland' },
  { text: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', value: 'Pacific/Fiji' },
  { text: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu' },
  { text: '(GMT+13:00) Samoa', value: 'Pacific/Apia' },
];

export const createDayjs = ({
  datetime,
  timezone,
}: {
  datetime: string | number | Dayjs;
  timezone: string;
}): Dayjs | null => {
  if (!datetime || !timezone) return null;
  if (typeof datetime === 'object') return datetime;
  const dayjsDatetime = dayjs.tz(datetime, timezone);
  if (!dayjsDatetime.isValid()) return null;
  return dayjsDatetime;
};

export const createUTCDayjs = (date?: string | number): Dayjs => {
  return date ? dayjs.utc(date) : dayjs.utc();
};

export const createUTCTimestampForDB = (date?: string): number => {
  return dayjs.utc(date).valueOf();
};

export const convertDayjsToUTC = ({
  datetime,
}: {
  datetime: Dayjs | number;
}): number | null => {
  if (typeof datetime === 'number') {
    datetime = dayjs.utc(datetime);
  }
  if (!datetime?.isValid()) return null;
  // If it gets here, it should already be in the timezone we care about
  return datetime.utc().valueOf();
};

export const convertToUTC = ({
  datetime,
  timezone,
}: {
  datetime: string;
  timezone: string;
}): number | null => {
  if (!datetime || !timezone) return null;

  const dayjsDatetime = dayjs.tz(datetime, timezone);
  if (!dayjsDatetime.isValid()) return null;
  return dayjsDatetime.utc().valueOf();
};

export const convertFromUTC = ({
  datetime,
  timezone = 'UTC',
}: {
  datetime: number | string | Dayjs;
  timezone?: string;
}): Dayjs | null => {
  if (!datetime) return null;
  if (!timezone) return null;
  if (typeof datetime === 'number') {
    const convertedTime = convertToMS(datetime);
    datetime = dayjs.utc(convertedTime);
  } else if (typeof datetime === 'string') {
    datetime = dayjs.utc(datetime);
  }
  if (!datetime.isValid()) return null;
  return datetime.tz(timezone);
};

export const convertFromUTCToString = ({
  datetime,
  timezone,
}: {
  datetime: number | string | Dayjs;
  timezone: string;
}): string | null => {
  const converted = convertFromUTC({ datetime, timezone });
  if (!converted) return null;
  return converted.format('YYYY-MM-DDTHH:mm:ss');
};

export const convertUTCToFormattedDate = ({
  datetime,
  timezone,
}: {
  datetime: number | string | Dayjs;
  timezone: string;
}): string | null => {
  const converted = convertFromUTC({ datetime, timezone });
  if (!converted) return null;
  return converted.format(ChiroUpDayJsCommon.format.datetime);
};
