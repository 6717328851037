import { GLOBAL_MANY_IDS_SEP, Test } from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';
import { CustomizationListItem } from '../components/clinical-skills/CustomizationListItem.type';

const testService = () => {
  const list = async (
    clinicId = -1,
  ): Promise<{
    data: CustomizationListItem[];
    skip: number;
  }> => {
    return ChiroUpAPI.get('api', `/${clinicId}/tests`, {});
  };

  const add = async (clinicId: number, body: Partial<Test>): Promise<Test> => {
    return ChiroUpAPI.post('api', `/${clinicId}/tests`, {
      body,
    });
  };

  const findOne = async (clinicId = -1, id: string): Promise<Test> => {
    return ChiroUpAPI.get('api', `/${clinicId}/tests/${id}`, {});
  };

  const getMany = async (clinicId = -1, ids: number[]): Promise<Test> => {
    return ChiroUpAPI.get(
      'api',
      `/${clinicId}/tests/${ids.join(GLOBAL_MANY_IDS_SEP)}`,
      {},
    );
  };

  const save = async (
    clinicId = -1,
    id: string | number,
    body: Partial<Test>,
  ): Promise<Test> => {
    return await ChiroUpAPI.patch('api', `/${clinicId}/tests/${id}`, {
      body,
    });
  };

  return {
    list,
    add,
    findOne,
    getMany,
    save,
  };
};

export default testService();
