import { useContext } from 'react';
import { MeContext } from '../../contexts/me.context';
import PendingMessage from './PendingMessage';
import ExpirationMessage from './ExpirationMessage';

type Props = {
  pending?: boolean;
  showUntil?: Date | null;
  contentToShow?: string | null;
  currentPlan?: string;
};
const MarketingBanner: React.FC<Props> = ({
  pending,
  showUntil,
  contentToShow,
  currentPlan,
}) => {
  const { me } = useContext(MeContext);

  return (
    <div className="flex items-center justify-between gap-x-6 px-6 bg-primary-600 py-2.5 sm:pr-3.5 lg:pl-8">
      <p className="text-sm leading-6 text-white">
        {pending ? (
          <PendingMessage plan={me.selectedClinic?.plan ?? ''} />
        ) : showUntil ? (
          <ExpirationMessage
            pending={pending}
            showUntil={showUntil}
            contentToShow={contentToShow}
            currentPlan={currentPlan}
          />
        ) : null}
      </p>
    </div>
  );
};

export default MarketingBanner;
