import React, { useEffect, useMemo, useState } from 'react';
import CodeEncounterService from './CodeEncounterService';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  PatientTransactionItemType,
  ReferenceCodeDisplay,
} from '@chiroup/core';

type Props = {
  services: PatientTransactionItemType[];
  onChangeValue: (
    index: number,
    val: PatientTransactionItemType | null,
  ) => void;
  readonly: boolean;
  isBillingStarted?: boolean;
  onPromote: (
    e: ReferenceCodeDisplay,
    getRidOfService?: string,
  ) => Promise<void>;
  onChangeBillableStatus?: ({
    code,
    status,
  }: {
    code: string;
    status: boolean;
  }) => void;
  onChangeBilledAmount?: ({
    code,
    amount,
  }: {
    code: string;
    amount: string;
  }) => void;
};

const CodeEncounterServices: React.FC<Props> = ({
  services,
  onChangeValue,
  readonly,
  isBillingStarted = false,
  onPromote,
  onChangeBillableStatus,
  onChangeBilledAmount,
}) => {
  const [servicesWithId, setServicesWithId] = useState<any>([]);

  const duplicateFlags = useMemo(() => {
    const codeCounts: { [key: string]: number } = {};
    return services.map((service) => {
      const code = service.code as string;
      codeCounts[code] = (codeCounts[code] || 0) + 1;
      return codeCounts[code] > 1;
    });
  }, [services]);

  useEffect(() => {
    if (!services) return setServicesWithId([]);

    setServicesWithId(
      services
        .map((service) => {
          if (!service) return null;
          return {
            ...service,
            id: service.code as string,
          };
        })
        .filter((service) => !!service),
    );
  }, [services]);

  if (!services?.length) return null;
  const usableServices = services.filter((service) => !!service);
  if (!usableServices?.length || usableServices.length === 0) return null;

  return (
    <SortableContext
      id="services"
      items={servicesWithId}
      strategy={verticalListSortingStrategy}
    >
      {(() => {
        return services.map(
          (service: PatientTransactionItemType, i: number) => {
            return (
              <CodeEncounterService
                key={`${service.code}-${i}`}
                index={i}
                service={service}
                isDuplicate={duplicateFlags[i]}
                onChangeValue={onChangeValue}
                readonly={readonly}
                isBillingStarted={isBillingStarted}
                onPromote={onPromote}
                onChangeBillableStatus={onChangeBillableStatus}
                onChangeBilledAmount={onChangeBilledAmount}
              />
            );
          },
        );
      })()}
    </SortableContext>
  );
};

export default CodeEncounterServices;
