import React from 'react';
import { Tooltip } from 'react-tooltip';

const CHUSALabelBadge: React.FC<{
  height?: number;
  width?: number;
}> = ({ height = 28, width = 28 }) => (
  <>
    <div
      data-tooltip-content={`CHUSA`}
      data-tooltip-id="chusa-badge-tooltip"
      style={{ display: 'inline-block', position: 'relative' }}
    >
      <svg
        width={width}
        height={height}
        className="text-primary-500"
        viewBox="0 0 4.2438703 4.2333174"
        version="1.1"
        id="svg1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs id="defs1">
          <linearGradient id="swatch6">
            <stop
              style={{ stopColor: '#000000', stopOpacity: 1 }}
              offset="0"
              id="stop6"
            />
          </linearGradient>
        </defs>
        <g id="layer1" transform="translate(-114.25136,-112.62227)">
          <g
            style={{ fill: 'none', stroke: '#000000', strokeOpacity: 1 }}
            id="g1"
            transform="matrix(0.26458093,0.0011263,-0.0011263,0.26458093,113.2082,111.55044)"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M 12,4.75 4.75001,8 c 0,0 -0.75,11.25 7.24999,11.25 C 20,19.25 19.25,8 19.25,8 Z"
              id="path1"
              style={{
                stroke: 'rgb(147 204 104)',
                strokeOpacity: 1,
              }}
            />
          </g>
          <text
            style={{
              fontSize: '1.15875px',
              fill: '#000000',
              stroke: 'rgb(147 204 104)',
              strokeWidth: '0.0965623',
              strokeOpacity: 1,
            }}
            x="167.5605"
            y="78.948898"
            id="text1"
            transform="scale(0.68498212,1.4598921)"
          >
            <tspan
              id="tspan1"
              style={{
                stroke: 'rgb(147 204 104)',
                strokeWidth: '0.0965623',
                strokeOpacity: 1,
              }}
              x="167.5605"
              y="78.948898"
            >
              CHUSA
            </tspan>
          </text>
        </g>
      </svg>
    </div>
    <Tooltip id="chusa-badge-tooltip" className="text-xs" />
  </>
);

export default CHUSALabelBadge;
