import {
  CancelConfirmModal,
  ConfirmModal,
  Loading,
  OpenClosedStates,
  Select,
} from '@chiroup/components';
import {
  AppointmentForUI,
  AppointmentStatuses,
  ClinicCaseType,
  Discipline,
  InstanceKeys,
  NoShowCancelFees,
  Patient,
  PatientTransaction,
  RecurringAvailability,
  TransactionItemSubtypeEnum,
  UserRoles,
  classNames,
} from '@chiroup/core';
import { useForm } from '@chiroup/hooks';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  EllipsisVerticalIcon,
  EnvelopeIcon,
  ExclamationTriangleIcon,
  PhoneIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MeContext } from '../../contexts/me.context';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import { TransactionContext } from '../../contexts/transaction.context';
import { useQueryParams } from '../../hooks/useQueryParams';
import useResponsibilities from '../../hooks/useResponsibilities';
import patientService from '../../services/patient.service';
import DisclosureButton from '../common/DisclosureButton';
import ButtonGroup, { ButtonGroupType } from '../common/buttons/ButtonGroup';
import { getCaseTypeIcon } from '../common/icons/helpers';
import PatientSendSurveyModal from '../patients/detail/PatientSendSurveyModal';
import { useTransaction } from '../patients/detail/billing/hooks/useTransaction';
import EncounterBillingView from '../patients/detail/visits/notes/insurances/EncounterBillingView';
import CodeEncounterDiagnosisServices, {
  ClickRemoteControlType,
} from '../patients/detail/visits/notes/services/CodeEncounterDiagnosisServices';
import { SendSurveysFormValues } from '../patients/hooks/usePatientCommunications';
import useDatabase from '../settings/database/useDatabase';
import ScheduleAppointmentActivityLog from './ScheduleAppointmentActivityLog';
import ScheduleAppointmentCheckIn from './ScheduleAppointmentCheckIn';
import ScheduleAppointmentForm from './ScheduleAppointmentForm';
import SchedulePatientHistory from './SchedulePatientHistory';
import ScheduleRecurringAppointments from './ScheduleRecurringAppointments';
import useAppointment from './hooks/useAppointment';

interface InnerProps {
  selectedAppointmentId: string | null;
  sessionId: string;
  onClose: (afterClose?: () => void) => void;
  timezone: string;
  recurringAppointment?: boolean;
  recurringData: RecurringAvailability;
  setRecurringData: React.Dispatch<React.SetStateAction<RecurringAvailability>>;
  disciplines?: Discipline[];
  hide?: boolean;
  patient?: Patient;
  dontNavigate?: boolean;
}

const validation = {
  patientId: { required: { message: 'Patient is required' } },
  clinicianId: { required: { message: 'Clinician is required' } },
  treatmentId: { required: { message: 'Treatment is required' } },
  startTime: { required: { message: 'Start time is required' } },
  duration: { required: { message: 'Duration is required' } },
};

export const ScheduleAppointmentInner: React.FC<InnerProps> = ({
  onClose,
  sessionId,
  selectedAppointmentId,
  timezone,
  recurringAppointment,
  recurringData,
  setRecurringData,
  disciplines,
  hide = false,
  patient,
  dontNavigate = false,
}) => {
  const navigate = useNavigate();
  const { me, hasRole } = useContext(MeContext);
  const { data: caseTypes } = useDatabase<ClinicCaseType>({
    instanceKey: InstanceKeys.caseTypes,
    allCaseTypes: true,
  });

  const { addQueryParams } = useQueryParams();
  const [sendSurveyModalState, setSendSurveyModalState] =
    useState<OpenClosedStates>(OpenClosedStates.Closed);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [noShowModalOpen, setNoShowModalOpen] = useState(false);
  const [deleteRecurringModalOpen, setDeleteRecurringModalOpen] =
    useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { createToast } = useContext(ToastContext);

  const {
    data: appointment,
    isFetching,
    saveAppointment: hookSaveAppointment,
    checkRecurringAvailability,
    saveRecurringAppointments,
    checkIn,
    markAsScheduled,
    isSubmitting: isSavingAppointment,
    isChangingStatus,
    isCheckingIn,
    markAsNoShow,
    approvePatient,
    error,
    deleteRecurringAppointments,
    updateRecurringAppointments,
  } = useAppointment({
    appointmentId: selectedAppointmentId,
    sessionId,
  });

  const {
    value,
    onChange,
    errors,
    isDirty,
    isSubmitting,
    setValue,
    registerSubmit,
    patchValue,
    // setErrors,
  } = useForm<AppointmentForUI>({ customDuration: 0, surveys: [] }, validation);
  const {
    value: noShowValue,
    onChange: noShowOnChange,
    errors: noShowErrors,
    registerSubmit: noShowSubmit,
    patchValue: patchNoShowValue,
  } = useForm<{ ID: number }>(
    {},
    { ID: { required: { message: 'No show fee choice is required' } } },
  );
  const [selectedOption, setSelectedOption] = useState('deleteAppointment');
  const [editType, setEditType] = useState<string>('');
  const [showForm, setShowForm] = useState<boolean>(false);
  const {
    status,
    data: transaction,
    refetch: refetchTransaction,
    insurances,
    payors,
    snapshot,
    locked,
    services,
    setServices,
    setSnapshot,
    setInsurances,
    setLocked,
    setPayors,
    setCourtesyBilling,
    setSuperBill,
    allowBillingPriorityChange,
    courtesyBilling,
    superBill,
    transactionReadonly,
    listofServices,
    setListofServices,
  } = useTransaction(selectedAppointmentId || '');
  const [workingTransaction, setWorkingTransaction] =
    useState<PatientTransaction | null>(null);

  const {
    patientResponsibility,
    insurancesToUse,
    responsibilities,
    payorResponsibilityTotal,
    balanceAllocatedToPatient,
  } = useResponsibilities({
    insurances,
    services,
    workingTransaction,
    courtesyBilling,
    superBill,
  });

  const disabled =
    appointment?.status === AppointmentStatuses.Canceled ||
    isFetching ||
    isSubmitting ||
    isCanceling ||
    isCheckingIn ||
    isDeleting ||
    isChangingStatus ||
    isSavingAppointment;

  const [remoteControl, setRemoteControl] =
    React.useState<ClickRemoteControlType | null>(null);

  /**
   * A passthrough so we can also refetch the transaction when the appointment
   * is saved successfully.
   *
   * @param args
   */
  const saveAppointment = async ({
    appointment,
    notify,
    removeRecurringId,
    fee,
  }: {
    appointment: Partial<AppointmentForUI>;
    notify?: boolean | undefined;
    removeRecurringId?: boolean | undefined;
    fee?: {
      amount: number;
      subtype: string;
      description: string;
      name: string;
    };
  }) => {
    try {
      const res = await hookSaveAppointment({
        appointment,
        notify,
        removeRecurringId,
        fee,
      });
      refetchTransaction();
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (transaction) {
      setWorkingTransaction(transaction);
      setRemoteControl({
        button: 'refresh',
        e: null,
        callback: () => {},
        data: transaction,
      });
    }
  }, [transaction]);

  const tryCatchCheckIn = async () => {
    try {
      await checkIn();
      refetchTransaction();
    } catch (err) {
      createToast({
        title: 'Error!',
        description: <>The check-in failed.</>,
        type: ToastTypes.Fail,
        duration: 5000,
      });
      console.error(err);
    }
  };

  const sendSurveys = async (values: SendSurveysFormValues) => {
    if (!selectedAppointmentId) return;
    return patientService.sendSurvey(
      { ...values, appointmentId: selectedAppointmentId },
      me?.selectedClinic?.ID,
    );
  };

  const { data: noShowCancelFees } = useDatabase<NoShowCancelFees>({
    instanceKey: InstanceKeys.noShowCancelFees,
    limit: 9999,
  });

  const priceOfTreatment = useMemo(() => {
    if (!disciplines) return undefined;
    for (const discipline of disciplines) {
      const treatment = discipline.treatments.find(
        (treat) => treat?.ID === appointment?.treatmentId,
      );

      if (treatment) {
        return Number(treatment.priceToCalculateFees);
      }
    }
    return undefined;
  }, [disciplines, appointment?.treatmentId]);

  const noShowFeesOptions = noShowCancelFees?.reduce(
    (arr: { text: string; value?: number; onClick: boolean }[], fee) => {
      //@Jeremy I think the wrong fix was applied here, I think it should have been to make
      // Premiere's fees in database match the enum types below.
      if (fee.type === TransactionItemSubtypeEnum.NoShow) {
        arr.push({
          text: fee.name + ' ' + fee.value + ' ' + fee.structure,
          value: fee.ID,
          onClick:
            !!priceOfTreatment ||
            fee.structure.includes(TransactionItemSubtypeEnum.Override),
        });
      }
      return arr;
    },
    [],
  );

  const deleteAppointmentConfirm = async () => {
    if (!selectedAppointmentId) return;
    try {
      setIsDeleting(true);
      await saveAppointment({
        appointment: {
          id: selectedAppointmentId,
          patientId: appointment?.patientId,
          deleted: true,
        },
      });
      setIsDeleting(false);
      setDeleteModalOpen(false);
      onClose();
    } catch (err) {
      console.error(err);
      setIsDeleting(false);
    }
  };

  const deleteAllRecurringAppointmentsConfirm = async () => {
    if (
      !selectedAppointmentId ||
      !appointment?.recurringAppointmentId ||
      !appointment?.patientId ||
      !appointment?.startTime
    ) {
      return;
    }
    if (selectedOption === 'deleteAppointment') {
      return deleteAppointmentConfirm();
    }
    try {
      setIsDeleting(true);
      await deleteRecurringAppointments(appointment);
      setIsDeleting(false);
      setDeleteModalOpen(false);
      onClose();
    } catch (err) {
      console.error(err);
      setIsDeleting(false);
    }
  };

  const copyAppointment = () => {
    return onClose(() => {
      setValue({});
      const params: {
        [key: string]: string | number;
      } = {
        open: 'add',
      };
      if (appointment?.treatmentId) {
        params.treatment = appointment.treatmentId;
      }
      if (appointment?.patientId) {
        params.patient = appointment.patientId;
      }
      if (appointment?.clinicianId) {
        params.clinician = appointment.clinicianId;
      }
      if (appointment?.displayValues) {
        params.patientName = appointment.displayValues.patientName;
      }
      if (appointment?.duration) {
        params.duration = appointment.duration;
      }
      if (appointment?.startTime) {
        params.startTime = appointment.startTime.valueOf();
      }
      addQueryParams(params).navigate();
    });
  };

  const scheduleRecurringAppointment = () => {
    return onClose(() => {
      const params: {
        [key: string]: string | number;
      } = {
        mode: 'recurring',
      };
      if (appointment?.id) {
        params.open = appointment.id;
      }
      if (appointment?.treatmentId) {
        params.treatment = appointment.treatmentId;
      }
      if (appointment?.patientId) {
        params.patient = appointment.patientId;
      }
      if (appointment?.clinicianId) {
        params.clinician = appointment.clinicianId;
      }
      if (appointment?.duration) {
        params.duration = appointment.duration;
      }
      if (appointment?.startTime) {
        params.startTime = appointment.startTime.valueOf();
      }
      if (appointment?.displayValues?.patientName) {
        params.patientName = appointment.displayValues.patientName;
      }
      addQueryParams(params).navigate();
    });
  };

  const displayNoShowOrCancellationFee = useMemo(() => {
    return transaction?.items?.find(
      (item: { subtype: string }) =>
        item.subtype === TransactionItemSubtypeEnum.Canceled ||
        item.subtype === TransactionItemSubtypeEnum.NoShow,
    );
  }, [transaction]);

  const markAndApplyFees = async () => {
    setIsCanceling(true);
    const id = noShowValue?.ID;
    if (id === -1 || !id) {
      await markAsNoShow();
      setNoShowModalOpen(false);
      setIsCanceling(false);
      patchNoShowValue({});
      return;
    }
    const reason = 'no show';
    const fee = noShowCancelFees.find((fee) => fee.ID === id);
    const feeToSend = { amount: 0, name: '', description: '', subtype: '' };
    const displayValues = `${appointment?.displayValues?.treatmentName} with ${appointment?.displayValues?.clinicianName}`;
    if (fee) {
      if (fee.structure === '$ Override') {
        feeToSend.amount = Number(fee.value);
        feeToSend.name = fee.name;
        feeToSend.description = `An override of $${fee.value} for ${reason} fee was applied for ${displayValues}`;
        feeToSend.subtype = fee.type;
      } else if (priceOfTreatment && fee.structure === '$ Additional Fee') {
        feeToSend.amount = Number(fee.value) + Number(priceOfTreatment);
        feeToSend.name = fee.name;
        feeToSend.subtype = fee.type;
        feeToSend.description = `An addition of $${fee.value} to total price $${priceOfTreatment} for ${reason} fee of was applied for ${displayValues}. Total patient responsiblity of $${feeToSend.amount}.`;
      } else if (priceOfTreatment && fee.structure === '% Additional Fee') {
        feeToSend.amount =
          (Number(fee.value) / 100) * Number(priceOfTreatment) +
          Number(priceOfTreatment);
        feeToSend.name = fee.name;
        feeToSend.subtype = fee.type;
        feeToSend.description = `An addition of ${fee.value}% from total price $${priceOfTreatment} for ${reason} fee was applied for ${displayValues}. Total patient responsiblity of $${feeToSend.amount}.`;
      } else if (
        priceOfTreatment &&
        fee.structure === '$ Discount From Total'
      ) {
        feeToSend.amount = Number(priceOfTreatment) - Number(fee.value);
        feeToSend.name = fee.name;
        feeToSend.description = `A discount of $${fee.value} from total price $${priceOfTreatment} for ${reason} fee was applied for ${displayValues}. Total patient responsiblity of $${feeToSend.amount}.`;
        feeToSend.subtype = fee.type;
      } else if (
        priceOfTreatment &&
        fee.structure === '% Discount From Total'
      ) {
        feeToSend.amount =
          Number(priceOfTreatment) -
          (Number(fee.value) / 100) * Number(priceOfTreatment);
        feeToSend.name = fee.name;
        feeToSend.description = `A discount of ${fee.value}% from total price $${priceOfTreatment} for ${reason} fee was applied for ${displayValues}. Total patient responsiblity of $${feeToSend.amount}.`;
        feeToSend.subtype = fee.type;
      }
    }
    await markAsNoShow(feeToSend);
    setIsCanceling(false);
    setNoShowModalOpen(false);
    patchNoShowValue({});
    return;
  };

  const getButtons = ({
    status,
    patientId,
    billingKey,
  }: {
    status: AppointmentStatuses;
    patientId: string;
    billingKey: string;
  }): ButtonGroupType[] => {
    const buttons: ButtonGroupType[] = [
      {
        label: 'Purchase',
        to: `/patients/${patientId}/billing/${billingKey}`,
      },
    ];
    if (status !== AppointmentStatuses.Canceled) {
      buttons.push({
        label: 'Request Info',
        onClick: () => {
          setSendSurveyModalState(OpenClosedStates.Open);
        },
      });
    }

    if (status === AppointmentStatuses.CheckedIn) {
      buttons.unshift({
        label: 'Encounter',
        to: `/patients/${patientId}/encounters/${billingKey}`,
      });
    }
    return buttons;
  };

  const startTimeInterval = useMemo(() => {
    return (
      me?.selectedClinic?.settings?.find(
        (setting: { setting: string }) =>
          setting.setting === 'Appointment Settings',
      )?.jsonValue?.appointmentStartTimeInterval || 15
    );
  }, [me]);

  return (
    <TransactionContext.Provider
      value={{
        status,
        data: transaction,
        isFetching,
        refetch: refetchTransaction,
        insurances,
        payors,
        snapshot,
        locked,
        services,
        setServices,
        setSnapshot,
        setInsurances,
        setLocked,
        setPayors,
        setCourtesyBilling,
        setSuperBill,
        allowBillingPriorityChange,
        courtesyBilling,
        superBill,
        transactionReadonly,
        listofServices,
        setListofServices,
      }}
    >
      {recurringAppointment ? (
        <ScheduleRecurringAppointments
          appointment={appointment}
          onClose={onClose}
          checkRecurringAvailability={checkRecurringAvailability}
          saveRecurringAppointments={saveRecurringAppointments}
          recurringData={recurringData}
          setRecurringData={setRecurringData}
          sessionId={sessionId}
          timezone={timezone}
          passedDisciplines={disciplines}
          startTimeInterval={startTimeInterval}
        />
      ) : isFetching || (selectedAppointmentId && !appointment) ? (
        <div className="flex flex-row justify-center">
          {error ? (
            <div
              className="relative w-full px-4 py-3 text-sm text-red-700 bg-red-100 border border-red-400 rounded"
              role="alert"
            >
              <span className="block sm:inline">
                The appointment could not be found.
              </span>
              <span className="hidden">{selectedAppointmentId}</span>
            </div>
          ) : (
            <Loading color="text-gray-400" />
          )}
        </div>
      ) : selectedAppointmentId && !recurringAppointment ? (
        <>
          <div className="pb-1 sm:pb-6">
            <div className="sm:flex sm:items-end">
              <div className="sm:flex-1">
                <div>
                  <div className="flex items-center justify-between">
                    <Link
                      to={`/patients/${appointment?.patientId}`}
                      onClick={(e) => {
                        /**
                         * Admittedly, this is kinda weird. The link exists so that the user can open in a new tab.
                         * However, if we don't prevent the default behavior, navigating to the patient page will
                         * close the slide over, which navigates to the schedule. So, we do that ourselves , then prevent the default behavior
                         * and navigate manually.
                         */
                        e.preventDefault();
                        e.stopPropagation();
                        navigate('/schedule');
                        setTimeout(() => {
                          navigate(`/patients/${appointment?.patientId}`);
                        }, 333);
                      }}
                    >
                      <span
                        className="flex flex-row gap-2 text-xl font-bold text-gray-900 cursor-pointer dark:text-white sm:text-2xl hover:underline"
                        title={
                          appointment?.pendingPatient
                            ? 'Pending patient.'
                            : appointment?.deniedPatient
                              ? 'Denied patient.'
                              : ''
                        }
                      >
                        <span className="text-xl">
                          {appointment?.displayValues?.patientName}{' '}
                          {getCaseTypeIcon({
                            caseType:
                              appointment?.displayValues?.caseType || -1,
                            caseTypes:
                              (caseTypes as Partial<ClinicCaseType>[]) || [],
                            hasActiveInsurance:
                              appointment?.displayValues?.hasActiveInsurance ||
                              false,
                          })}
                        </span>
                        {appointment?.pendingPatient ? (
                          <ExclamationTriangleIcon className="w-5 h-5 text-yellow-500" />
                        ) : appointment?.deniedPatient ? (
                          <ExclamationTriangleIcon className="w-5 h-5 text-red-500" />
                        ) : null}
                      </span>
                    </Link>
                    {appointment?.status === AppointmentStatuses.Canceled ? (
                      <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-red-700 rounded-full bg-red-50 ring-1 ring-inset ring-red-600/50">
                        Canceled
                      </span>
                    ) : appointment?.status ===
                      AppointmentStatuses.CheckedIn ? (
                      <span className="inline-flex items-center px-2 py-1 text-xs font-medium rounded-full bg-primary-50 text-primary-700 ring-1 ring-inset ring-primary-600/50">
                        Checked In
                      </span>
                    ) : appointment?.status === AppointmentStatuses.NoShow ? (
                      <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-red-700 rounded-full bg-red-50 ring-1 ring-inset ring-red-600/50">
                        No Show
                      </span>
                    ) : appointment?.status ===
                      AppointmentStatuses.Scheduled ? (
                      <ScheduleAppointmentCheckIn
                        checkIn={tryCatchCheckIn}
                        disabled={disabled}
                        loading={isCheckingIn}
                      />
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-0 sm:flex-row sm:gap-6">
                    {appointment?.displayValues?.patientPhone && (
                      <a
                        href={`tel:${appointment.displayValues?.patientPhone}`}
                        className="flex flex-row items-center gap-2 text-sm text-gray-500"
                      >
                        <PhoneIcon className="w-4 h-4" />{' '}
                        {appointment.displayValues?.patientPhone}
                      </a>
                    )}
                    {appointment?.displayValues?.patientEmail && (
                      <a
                        href={`mailto:${appointment.displayValues?.patientEmail}`}
                        className="flex flex-row items-center gap-2 text-sm text-gray-500"
                      >
                        <EnvelopeIcon className="w-4 h-4" />{' '}
                        {appointment.displayValues?.patientEmail}
                      </a>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap justify-between mt-5 space-y-3 sm:space-x-3 sm:space-y-0">
                  <div>
                    <ButtonGroup
                      buttons={getButtons({
                        status: appointment?.status as AppointmentStatuses,
                        patientId: appointment?.patientId as string,
                        billingKey: appointment?.id as string,
                      })}
                      disabled={
                        workingTransaction?.items?.length ? false : disabled
                      }
                    />
                    <div className="flex flex-row gap-2">
                      {/* <Button
                        color={ButtonColors.plainWithBorder}
                        text="Request Info"
                        onClick={() => {
                          setSendSurveyModalState(OpenClosedStates.Open);
                        }}
                        disabled={disabled}
                      /> */}
                    </div>
                  </div>

                  {hasRole([
                    UserRoles.Admin,
                    UserRoles.Provider,
                    UserRoles.Staff,
                    UserRoles.ClinicalAssistant,
                  ]) && (
                    <div className="inline-flex ml-3 sm:ml-0">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <Menu.Button
                          className="inline-flex items-center p-2 text-gray-400 bg-white rounded-md shadow-sm dark:bg-darkGray-800 hover:dark:bg-darkGray-700 ring-1 ring-inset ring-gray-300 dark:ring-darkGray-600 hover:bg-gray-50"
                          disabled={disabled}
                        >
                          <span className="sr-only">Open options menu</span>
                          <EllipsisVerticalIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 w-48 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              {appointment?.pendingPatient && (
                                <Menu.Item>
                                  {({ active }) => (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer',
                                      )}
                                      onClick={approvePatient}
                                    >
                                      Approve patient
                                    </span>
                                  )}
                                </Menu.Item>
                              )}
                              <Menu.Item>
                                {({ active }) => (
                                  <span
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm cursor-pointer',
                                    )}
                                    onClick={copyAppointment}
                                  >
                                    Copy appointment
                                  </span>
                                )}
                              </Menu.Item>
                              {!appointment?.recurringAppointmentId && (
                                <Menu.Item>
                                  {({ active }) => (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer',
                                      )}
                                      onClick={scheduleRecurringAppointment}
                                    >
                                      Schedule recurring appointments
                                    </span>
                                  )}
                                </Menu.Item>
                              )}
                              {appointment?.status !==
                                AppointmentStatuses.CheckedIn && (
                                <Menu.Item>
                                  {({ active }) => (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer',
                                      )}
                                      onClick={() => setCancelModalOpen(true)}
                                    >
                                      Cancel appointment
                                    </span>
                                  )}
                                </Menu.Item>
                              )}
                              {appointment?.status ===
                              AppointmentStatuses.NoShow ? (
                                <Menu.Item>
                                  {({ active }) => (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer',
                                      )}
                                      onClick={markAsScheduled}
                                    >
                                      Cancel no show
                                    </span>
                                  )}
                                </Menu.Item>
                              ) : appointment?.status !==
                                AppointmentStatuses.CheckedIn ? (
                                <Menu.Item>
                                  {({ active }) => (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer',
                                      )}
                                      onClick={() => setNoShowModalOpen(true)}
                                    >
                                      No show
                                    </span>
                                  )}
                                </Menu.Item>
                              ) : null}

                              {appointment?.status ===
                              AppointmentStatuses.CheckedIn ? (
                                <Menu.Item>
                                  {({ active }) => (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer',
                                      )}
                                      onClick={markAsScheduled}
                                    >
                                      Cancel Check In
                                    </span>
                                  )}
                                </Menu.Item>
                              ) : (
                                <div>
                                  {appointment?.recurringAppointmentId ? (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <span
                                          className={classNames(
                                            active
                                              ? 'bg-gray-100 text-gray-900'
                                              : 'text-gray-700',
                                            'block px-4 py-2 text-sm cursor-pointer',
                                          )}
                                          onClick={() =>
                                            setDeleteRecurringModalOpen(true)
                                          }
                                        >
                                          Delete
                                        </span>
                                      )}
                                    </Menu.Item>
                                  ) : (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <span
                                          className={classNames(
                                            active
                                              ? 'bg-gray-100 text-gray-900'
                                              : 'text-gray-700',
                                            'block px-4 py-2 text-sm cursor-pointer',
                                          )}
                                          onClick={() =>
                                            setDeleteModalOpen(true)
                                          }
                                        >
                                          Delete
                                        </span>
                                      )}
                                    </Menu.Item>
                                  )}
                                </div>
                              )}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  )}
                </div>
                <div>
                  {appointment?.displayValues?.clinicianName?.length &&
                  !!appointment?.displayValues?.nextRequestedEncounterDate
                    ?.length ? (
                    <p className="text-sm gap-2 mt-2">
                      {appointment?.displayValues?.clinicianName} requests the
                      next appointment on{' '}
                      <p
                        onClick={() => {
                          setValue({});
                          const patientName =
                            appointment?.displayValues?.patientName;
                          onClose();
                          navigate(
                            `/schedule?endDate=${appointment?.displayValues.nextRequestedEncounterDate}&startDate=${appointment?.displayValues.nextRequestedEncounterDate}&patient=${appointment?.patientId}&patientName=${patientName}&clinician=${appointment?.clinicianId}&treatment=${appointment?.treatmentId}&open=add`,
                          );
                        }}
                      >
                        <span className="text-primary-500 hover:underline cursor-pointer underline-none">
                          {dayjs(
                            appointment?.displayValues
                              .nextRequestedEncounterDate,
                          ).format('M/DD/YYYY')}
                        </span>
                        .
                      </p>
                    </p>
                  ) : (
                    <p
                      className="text-sm gap-2 mt-2"
                      onClick={() => {
                        setValue({});
                        const patientName =
                          appointment?.displayValues?.patientName;
                        onClose();
                        navigate(
                          `/schedule?patient=${appointment?.patientId}&patientName=${patientName}&clinician=${appointment?.clinicianId}&treatment=${appointment?.treatmentId}&open=add`,
                        );
                      }}
                    >
                      <span className="text-primary-500 hover:underline cursor-pointer underline-none">
                        Schedule next appointment
                      </span>
                    </p>
                  )}
                </div>
                {(!!displayNoShowOrCancellationFee ||
                  appointment?.cancellationReason) && (
                  <p className="mt-8 inline-flex items-center px-2 py-1 text-xs font-medium text-red-700 rounded-md bg-red-50 ring-1 ring-inset ring-red-600/50">
                    <span>
                      {appointment?.cancellationReason && (
                        <p>
                          Reason for cancellation:{' '}
                          {appointment?.cancellationReason}
                        </p>
                      )}
                      {displayNoShowOrCancellationFee?.description && (
                        <p>
                          Fee applied:{' '}
                          {displayNoShowOrCancellationFee?.description}
                        </p>
                      )}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
          <PatientSendSurveyModal
            setState={setSendSurveyModalState}
            state={sendSurveyModalState}
            patientId={appointment?.patientId ?? ''}
            patientPhone={appointment?.displayValues?.patientPhone}
            patientEmail={appointment?.displayValues?.patientEmail}
            sendSurveys={sendSurveys}
            optOutSMS={!!appointment?.optOutSMS}
          />
          <CancelConfirmModal
            isOpen={cancelModalOpen}
            close={() => setCancelModalOpen(false)}
            title="Cancel appointment"
            description="Are you sure you want to cancel this appointment?"
            confirmText="Cancel Appointment"
            cancelText="Keep Appointment"
            loading={isCanceling}
            appointment={appointment}
            priceOfTreatment={priceOfTreatment}
            selectedAppointmentId={selectedAppointmentId}
            noShowCancelFees={noShowCancelFees}
            setCancelModalOpen={setCancelModalOpen}
            saveAppointment={saveAppointment}
            refetchTransaction={refetchTransaction}
            fromChiroUp
          />
          <ConfirmModal
            isOpen={deleteModalOpen}
            close={() => setDeleteModalOpen(false)}
            title="Delete appointment"
            description="Are you sure you want to delete this appointment? This cannot be undone."
            confirmText="Delete"
            confirm={deleteAppointmentConfirm}
            loading={isDeleting}
          />
          <ConfirmModal
            isOpen={noShowModalOpen}
            close={() => setNoShowModalOpen(false)}
            title="No show"
            description="Mark patient as a no show?"
            confirmText="Confirm"
            confirm={noShowSubmit(markAndApplyFees, {})}
            loading={isCanceling}
          >
            <form>
              <Select
                label="No show fee"
                name="ID"
                value={noShowValue?.ID}
                onChange={noShowOnChange('ID')}
                limit={1}
                errors={noShowErrors?.fieldErrors?.ID}
                options={[
                  {
                    text: 'No Show - No fee',
                    value: -1,
                    onClick: true,
                  },
                  ...noShowFeesOptions,
                ]}
              />
            </form>
          </ConfirmModal>
          <ConfirmModal
            isOpen={deleteRecurringModalOpen}
            close={() => setDeleteRecurringModalOpen(false)}
            title="Delete appointment"
            description="Do you want to delete this appointment or this appointment and all future recurring appointments? This cannot be undone."
            confirmText="Delete"
            confirm={deleteAllRecurringAppointmentsConfirm}
            loading={isDeleting}
          >
            <div className="m-2">
              <label>
                <input
                  type="radio"
                  value="deleteAppointment"
                  checked={selectedOption === 'deleteAppointment'}
                  onChange={() => setSelectedOption('deleteAppointment')}
                  className="form-radio h-4 w-4 text-sm text-primary-600 transition duration-150 ease-in-out m-2"
                />
                <span className="text-sm text-gray-500">
                  Delete this appointment
                </span>
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="deleteAllFutureRecurring"
                  checked={selectedOption === 'deleteAllFutureRecurring'}
                  onChange={() => setSelectedOption('deleteAllFutureRecurring')}
                  className="form-radio h-4 w-4 text-primary-600 transition duration-150 ease-in-out m-2"
                />
                <span className="text-sm text-gray-500">
                  Delete this appointment and all future recurring appointments
                </span>
              </label>
            </div>
          </ConfirmModal>
          <dl className="space-y-6 divide-y divide-gray/10 dark:divide-white/10">
            {appointment?.recurringAppointmentId ? (
              <Disclosure as="div" className="pt-6" defaultOpen>
                {({ open }) => (
                  <>
                    <DisclosureButton open={open} title="Summary" />
                    <Disclosure.Panel as="dd" className="mt-4 w-full">
                      {(editType === 'editAllFutureRecurring' ||
                        editType === '' ||
                        (!showForm &&
                          editType === 'editOnlyThisAppointment')) && (
                        <ScheduleRecurringAppointments
                          appointment={appointment}
                          onClose={onClose}
                          checkRecurringAvailability={
                            checkRecurringAvailability
                          }
                          saveRecurringAppointments={saveRecurringAppointments}
                          updateRecurringAppointments={
                            updateRecurringAppointments
                          }
                          recurringData={recurringData}
                          setRecurringData={setRecurringData}
                          sessionId={sessionId}
                          editType={editType}
                          setEditType={setEditType}
                          showForm={showForm}
                          setShowForm={setShowForm}
                          timezone={timezone}
                          passedDisciplines={disciplines}
                        />
                      )}
                      {showForm && editType === 'editOnlyThisAppointment' && (
                        <ScheduleAppointmentForm
                          appointment={appointment}
                          onClose={onClose}
                          saveAppointment={saveAppointment}
                          timezone={timezone}
                          disabled={disabled}
                          editingOneOfRecurring={true}
                          setEditType={setEditType}
                          setShowForm={setShowForm}
                          value={value}
                          onChange={onChange}
                          errors={errors}
                          isDirty={isDirty}
                          isSubmitting={isSubmitting}
                          setValue={setValue}
                          registerSubmit={registerSubmit}
                          patchValue={patchValue}
                          patient={patient}
                          passedDisciplines={disciplines}
                          startTimeInterval={startTimeInterval}
                          dontNavigate={dontNavigate}
                        />
                      )}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ) : (
              <Disclosure as="div" className="pt-6" defaultOpen>
                {({ open }) => (
                  <>
                    <DisclosureButton open={open} title="Summary" />
                    <Disclosure.Panel as="dd" className="mt-4 w-full">
                      <ScheduleAppointmentForm
                        appointment={appointment}
                        onClose={onClose}
                        saveAppointment={saveAppointment}
                        timezone={timezone}
                        disabled={disabled}
                        value={value}
                        onChange={onChange}
                        errors={errors}
                        isDirty={isDirty}
                        isSubmitting={isSubmitting}
                        setValue={setValue}
                        registerSubmit={registerSubmit}
                        patchValue={patchValue}
                        patient={patient}
                        passedDisciplines={disciplines}
                        startTimeInterval={startTimeInterval}
                        dontNavigate={dontNavigate}
                      />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )}
            {!hide && (
              <>
                <Disclosure as="div" className="pt-6">
                  {({ open }) => (
                    <>
                      <DisclosureButton
                        open={open}
                        title="Diagnoses and Services"
                        tooltip={`There may be any number of services. 
        A single service must have at least one diagnosis and may have up to four. 
        Add diagnoses in order: primary, secondary, tertiary, and quaternary.
        A maximum of 12 diagnoses may be associated with one appointment.
        The number of units and a billed amount are required.`}
                      />
                      <Disclosure.Panel
                        as="dd"
                        className="w-full mt-4 flex flex-col gap-4"
                      >
                        <CodeEncounterDiagnosisServices
                          assessmentCodes={appointment?.assessmentCodes}
                          caseType={appointment?.displayValues?.caseType}
                          caseTypes={caseTypes}
                          clickRemoteControl={remoteControl}
                          clinicId={appointment?.clinicId as number}
                          disciplineId={appointment?.disciplineId}
                          importDiagnosesAlways
                          lockButtonText="Lock"
                          omitDiagnosisHeader={true}
                          omitTitle={true}
                          parentId={appointment?.id}
                          parentIsa={`appointment`}
                          patientId={appointment?.patientId as string}
                          patientTransaction={workingTransaction}
                          persistDiagnosesList
                          providerId={appointment?.clinicianId as string}
                          saveButtonText="Save"
                          setPatientTransaction={setWorkingTransaction}
                        />
                        <EncounterBillingView
                          insurances={workingTransaction?.insurances}
                          items={workingTransaction?.items}
                          courtesyBilling={workingTransaction?.courtesyBilling}
                          superBill={workingTransaction?.superBill}
                          setWorkingTransaction={setWorkingTransaction}
                          workingTransaction={workingTransaction || undefined}
                          locationId={workingTransaction?.locationId as number}
                          disciplineId={
                            workingTransaction?.disciplineId as number
                          }
                          insurancesToUse={insurancesToUse}
                          responsibilities={responsibilities}
                          payorResponsibilityTotal={payorResponsibilityTotal}
                          patientResponsibility={patientResponsibility}
                          balanceAllocatedToPatient={balanceAllocatedToPatient}
                        />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="pt-6">
                  {({ open }) => (
                    <>
                      <DisclosureButton open={open} title="Activity Log" />
                      <Disclosure.Panel as="dd" className="w-full mt-4">
                        <ScheduleAppointmentActivityLog
                          appointmentId={selectedAppointmentId}
                        />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="pt-6">
                  {({ open }) => (
                    <>
                      <DisclosureButton
                        open={open}
                        title="Patient Appointment History"
                      />
                      <Disclosure.Panel as="dd" className="w-full mt-4">
                        <SchedulePatientHistory
                          patientId={appointment?.patientId || ''}
                          // locationsById={locationsById}
                          me={me}
                        />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>{' '}
              </>
            )}
          </dl>
        </>
      ) : (
        <ScheduleAppointmentForm
          appointment={appointment}
          onClose={onClose}
          saveAppointment={saveAppointment}
          timezone={timezone}
          newAppointment
          value={value}
          onChange={onChange}
          errors={errors}
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          setValue={setValue}
          registerSubmit={registerSubmit}
          patchValue={patchValue}
          patient={patient}
          passedDisciplines={disciplines}
          startTimeInterval={startTimeInterval}
          dontNavigate={dontNavigate}
        />
      )}
    </TransactionContext.Provider>
  );
};

interface Props extends InnerProps {
  state: OpenClosedStates;
  recurringAppointment?: boolean;
  recurringData: RecurringAvailability;
  setRecurringData: React.Dispatch<React.SetStateAction<RecurringAvailability>>;
  disciplines?: Discipline[];
  patient?: Patient;
  dontNavigate?: boolean;
}

const ScheduleAppointment: React.FC<Props> = ({
  state,
  onClose,
  sessionId,
  selectedAppointmentId,
  timezone,
  recurringAppointment,
  recurringData,
  setRecurringData,
  disciplines,
  hide,
  patient,
  dontNavigate = false,
}) => {
  return (
    <div className="inset-y-0 right-0 flex border-l border-gray-300 max-w-xl w-full fixed sm:relative z-30">
      <div className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-darkGray-900 shadow-xl w-full">
        <div className="py-6 px-4 sm:px-6 bg-gray-50 dark:bg-darkGray-700">
          <div className="flex items-start justify-between">
            <div className="flex flex-col">
              <div className="text-lg font-medium text-gray-900 dark:text-white">
                {recurringAppointment
                  ? 'Recurring appointments'
                  : 'Appointment'}
              </div>
            </div>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                onClick={() => {
                  onClose();
                }}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative my-6 flex-1 px-4 sm:px-6">
          <ScheduleAppointmentInner
            onClose={onClose}
            sessionId={sessionId}
            selectedAppointmentId={selectedAppointmentId}
            timezone={timezone}
            recurringAppointment={recurringAppointment}
            recurringData={recurringData}
            setRecurringData={setRecurringData}
            disciplines={disciplines}
            hide={hide}
            patient={patient}
            dontNavigate={dontNavigate}
          />
        </div>
      </div>
    </div>
  );
};

export default ScheduleAppointment;
