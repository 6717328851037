const paramsObj = (search: string) => {
  return search.split(/[?&]/).reduce((obj: { [key: string]: string }, item) => {
    const [prop, val] = item.split('=');
    if (prop && val) {
      obj[prop] = val;
    }
    return obj;
  }, {});
};

const paramsStr = (params: { [key: string]: string }) => {
  return Object.keys(params).reduce((str, param, i) => {
    if (i) {
      str += '&';
    }
    str += `${param}=${params[param]}`;
    return str;
  }, '?');
};

export const updateQueryParams = (
  search: string,
  newParams: { [key: string]: string },
) => {
  const oldParams = paramsObj(search);
  const params: { [key: string]: string } = { ...oldParams, ...newParams };
  const filteredParams = Object.entries(params).reduce(
    (obj: { [key: string]: string }, [prop, val]) => {
      if (val) {
        obj[prop] = val;
      }
      return obj;
    },
    {},
  );
  return paramsStr(filteredParams);
};

export const getLink = (
  path: string,
  search: string,
  newParams: { [key: string]: string },
) => {
  const newQueryParams = updateQueryParams(search, newParams);
  return `${newQueryParams}`;
};

export const removeQueryParams = (search: string, removeParams: string[]) => {
  const oldParams = paramsObj(search);
  removeParams.forEach((param) => {
    delete oldParams[param];
  });
  return paramsStr(oldParams);
};
