/**
 * This provides the capability of updaing the statusData of an
 * invoice when someone has been working the errors that came
 * back from the business partner or from the business rules.
 *
 * Once they are all resolved, the invoice and transition to the
 * next status.
 */

import {
  BadRequestError,
  ChiroUpJSON,
  IntegrationInvoice,
  STRING_ANY_HASH,
  clog,
} from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';

/**
 * Note: The invoice this receives should contain only the unresolved
 * issues or no issues at all. This may not be what is in the tables, but
 * it was the state the user put on the invoice. Basically, the state
 * of the invoice should look like what the user thinks it should be.
 * We make it match reality if the user is wrong.
 *
 * @param param0
 * @returns
 */
export const resolveInvoiceIssues = async ({
  clinicId = -1,
  invoice = null,
  promote = false,
  trace = false,
}: {
  clinicId: number;
  invoice: IntegrationInvoice | null;
  promote?: boolean;
  trace?: boolean;
}) => {
  const invoiceId = invoice?.id ?? -1,
    statusData =
      typeof invoice?.statusData === 'string'
        ? ChiroUpJSON.parse(invoice?.statusData)
        : invoice?.statusData,
    items = statusData?.items ?? [],
    latest = items[0] ?? null,
    unresolved = latest ? latest?.issues : [];

  if (!invoice) {
    throw new BadRequestError('An invoice id is required.');
  }
  if (!invoiceId || invoiceId === -1) {
    throw new BadRequestError('An invoice id is required.');
  }

  if (trace) {
    clog({
      resolveInvoiceIssues: { clinicId, invoiceId, promote },
    });
  }

  const res: STRING_ANY_HASH = await ChiroUpAPI.post(
    'api',
    `/transactions/${clinicId}/rules${trace ? '?trace=true' : ''}`,
    { body: { context: { invoiceId, promote, unresolved } } },
  );

  if (trace) clog({ businessRulesService_solve: { res } });
  return (res as IntegrationInvoice) ?? null;
};
