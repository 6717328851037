import { useEffect, useState } from 'react';

export const useNativeTheme = () => {
  const [nativeTheme, setNativeTheme] = useState('light');

  const updateNativeTheme = (dark: boolean) => {
    setNativeTheme(dark ? 'dark' : 'light');
  };

  useEffect(() => {
    const mq = window.matchMedia('(prefers-color-scheme: dark)');
    if (mq) {
      updateNativeTheme(mq.matches);
      if (mq.addEventListener) {
        mq.addEventListener('change', () => {
          updateNativeTheme(mq.matches);
        });
      } else if (mq.addListener) {
        // Yes, this is deprecated, but we should probably still support Safari 13 for now...
        mq.addListener(() => {
          updateNativeTheme(mq.matches);
        });
      }
    }
  });

  return nativeTheme;
};
