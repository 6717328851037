import { Button, ButtonColors } from '@chiroup/components';
import { DisciplineTreatment, UserRoles, classNames } from '@chiroup/core';
import { Menu, Transition } from '@headlessui/react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DateType } from 'react-tailwindcss-datepicker/dist/types';
import { MeContext } from '../../contexts/me.context';
import { ScheduleContext } from '../../contexts/schedule.context';
import { useQueryParams } from '../../hooks/useQueryParams';
import useLocalStorage, { LSType } from '../../hooks/useLocalStorage';
import DateRangePicker from '../common/DateRangePicker';

const now = dayjs();
const startOfLastWeek = now
  .subtract(1, 'week')
  .startOf('week')
  .format('YYYY-MM-DD');
const endOfLastWeek = now
  .subtract(1, 'week')
  .endOf('week')
  .format('YYYY-MM-DD');
const startOfThisWeek = now.startOf('week').format('YYYY-MM-DD');
const endOfThisWeek = now.endOf('week').format('YYYY-MM-DD');
const startOfNextWeek = now.add(1, 'week').startOf('week').format('YYYY-MM-DD');
const endOfNextWeek = now.add(1, 'week').endOf('week').format('YYYY-MM-DD');

const shortcuts = {
  lastWeek: {
    text: 'Last week',
    period: {
      start: startOfLastWeek,
      end: endOfLastWeek,
    },
  },
  yesterday: 'Yesterday',
  todayBecauseNormalTodayIsNotAlwaysRight: {
    text: 'Today',
    period: {
      start: dayjs().format('YYYY-MM-DD'),
      end: dayjs().format('YYYY-MM-DD'),
    },
  },
  thisWeek: {
    text: 'This week',
    period: {
      start: startOfThisWeek,
      end: endOfThisWeek,
    },
  },
  nextWeek: {
    text: 'Next week',
    period: {
      start: startOfNextWeek,
      end: endOfNextWeek,
    },
  },
};

type Props = {
  start: string;
  end: string;
  selectTreatment: (treatment: DisciplineTreatment | null) => Promise<void>;
  selectedTreatment: DisciplineTreatment | null;
  loadingAvailableSlots: boolean;
  selectedUsers: {
    [key: string]: boolean;
  };
  setSelectedUsers: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  goBack: () => void;
  users: {
    id: string;
    name: string;
    fname: string;
    lname: string;
    profileImage: string;
  }[];
};

const ScheduleHeader: React.FC<Props> = ({
  start,
  end,
  selectTreatment,
  selectedTreatment,
  loadingAvailableSlots,
  selectedUsers,
  setSelectedUsers,
  goBack,
  users,
}) => {
  const { setItem, getItem } = useLocalStorage();
  const { me, hasRole } = useContext(MeContext);
  const { disciplines } = useContext(ScheduleContext);
  const navigate = useNavigate();
  const { addQueryParams } = useQueryParams();

  const daysBetween = dayjs(end).diff(dayjs(start), 'day');

  useEffect(() => {
    const previousDates = getItem(LSType.both, 'previousScheduleDateRange');
    const currentDates = getItem(LSType.both, 'currentScheduleDateRange');
    if (!previousDates || !currentDates) {
      setItem(LSType.both, 'previousScheduleDateRange', {
        startDate: start,
        endDate: end,
      });
      setItem(LSType.both, 'currentScheduleDateRange', {
        startDate: start,
        endDate: end,
      });
    } else if (
      currentDates &&
      currentDates.startDate !== start &&
      currentDates.endDate !== end
    ) {
      setItem(LSType.both, 'previousScheduleDateRange', {
        startDate: currentDates.startDate,
        endDate: currentDates.endDate,
      });
      setItem(LSType.both, 'currentScheduleDateRange', {
        startDate: start,
        endDate: end,
      });
    }
  }, [end, start, setItem, getItem]);

  const handleValueChange = (
    newValue: {
      startDate: DateType;
      endDate: DateType;
    } | null,
  ) => {
    if (!newValue) return;
    const startDate = dayjs(newValue.startDate).format('YYYY-MM-DD');
    const endDate = dayjs(newValue.endDate).format('YYYY-MM-DD');

    return addQueryParams({
      startDate,
      endDate,
    }).navigate();
  };

  const next = () => {
    const startDate = dayjs(start)
      .add(daysBetween + 1, 'day')
      .format('YYYY-MM-DD');
    const endDate = dayjs(end)
      .add(daysBetween + 1, 'day')
      .format('YYYY-MM-DD');

    return addQueryParams({
      startDate,
      endDate,
    }).link;
  };

  const previous = () => {
    const startDate = dayjs(start)
      .subtract(daysBetween + 1, 'day')
      .format('YYYY-MM-DD');
    const endDate = dayjs(end)
      .subtract(daysBetween + 1, 'day')
      .format('YYYY-MM-DD');

    return addQueryParams({
      startDate,
      endDate,
    }).link;
  };

  // const showPrevious = () => {
  //   const previousDates = getItem(LSType.both, 'previousScheduleDateRange');
  //   const currentDates = getItem(LSType.both, 'currentScheduleDateRange');
  //   return (
  //     previousDates &&
  //     currentDates &&
  //     previousDates.startDate !== currentDates.startDate &&
  //     previousDates.endDate !== currentDates.endDate
  //   );
  // };

  const treatmentOptions = useMemo(() => {
    if (!disciplines) {
      return [];
    }
    return disciplines
      ?.reduce((arr: DisciplineTreatment[], discipline) => {
        if (discipline.treatments?.length) {
          arr.push(...discipline.treatments);
        }
        return arr;
      }, [])
      ?.map((treatment) => ({
        text: treatment.name,
        onClick: () => {
          selectTreatment(treatment);
        },
      }));
  }, [disciplines, selectTreatment]);

  const userInitials = (name: string) => {
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  };

  const toggleUser = (userId: string) => {
    const selectedUsersToSave = { ...selectedUsers };
    selectedUsersToSave[userId] = !selectedUsersToSave[userId];
    setItem(LSType.both, 'scheduleSelectedUsers', {
      selectedScheduleUsers: selectedUsersToSave,
    });
    setSelectedUsers((prev) => {
      const newSelectedUsers = { ...prev };
      newSelectedUsers[userId] = !newSelectedUsers[userId];
      return newSelectedUsers;
    });
  };

  const hasSelectedUsers = useMemo(() => {
    return Object.values(selectedUsers || {}).includes(true);
  }, [selectedUsers]);

  return (
    <header className="flex flex-none items-center justify-between border-b border-gray-300 hover:dark:border-gray-500 px-6 py-4">
      <div>
        <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
          <div
            className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300"
            aria-hidden="true"
          />
          <Link to={previous()}>
            <div className="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50 dark:hover:bg-darkGray-500 dark:bg-darkGray-600">
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon
                className="h-5 w-5 dark:text-gray-100"
                aria-hidden="true"
              />
            </div>
          </Link>
          <div className="text-sm font-semibold text-gray-900 focus:relative cursor-default flex items-center border-r border-l border-gray-300 dark:border-gray-500 px-2 hover:bg-gray-50 dark:hover:bg-darkGray-500 dark:bg-darkGray-600 dark:hover-bg-darkGray-500 dark:text-gray-100">
            <DateRangePicker
              start={start}
              end={end}
              onChange={handleValueChange}
              shortcuts={shortcuts}
            />
          </div>
          <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
          <Link to={next()}>
            <div className="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50 dark:hover:bg-darkGray-500 dark:bg-darkGray-600">
              <span className="sr-only">Next</span>
              <ChevronRightIcon
                className="h-5 w-5 dark:text-gray-100"
                aria-hidden="true"
              />
            </div>
          </Link>
        </div>
        <div
          onClick={() => {
            handleValueChange({
              startDate: dayjs().format('YYYY-MM-DD'),
              endDate: dayjs().format('YYYY-MM-DD'),
            });
          }}
          className="cursor-pointer text-primary-500 hover:text-primary-400 text-xs text-center -mb-4"
        >
          Today
        </div>
        {/* {showPrevious() && (
          <div
            onClick={() => goBack()}
            className="cursor-pointer text-primary-500 hover:text-primary-400 text-xs text-center -mb-4"
          >
            Previous date range
          </div>
        )} */}
      </div>
      <div className="flex flex-row gap-4 items-center">
        <div className="-space-x-2 overflow-hidden hidden md:flex">
          {me.selectedClinic?.clinicians?.map((clinician) => {
            const profileImage = clinician.profileImage?.includes('https://')
              ? clinician.profileImage
              : `${import.meta.env.VITE_S3_ASSETS_URL}/${
                  clinician.profileImage
                }`;
            return (
              <div className="relative" key={clinician.ID}>
                {clinician.profileImage ? (
                  <img
                    className={classNames(
                      'inline-block h-8 w-8 rounded-full ring-2 ring-gray-50 cursor-pointer',
                    )}
                    src={profileImage}
                    alt={clinician.name}
                    onClick={toggleUser.bind(null, clinician.ID)}
                  />
                ) : (
                  <div
                    className={classNames(
                      'flex-none bg-gray-300 rounded-full h-8 w-8 flex items-center justify-center cursor-pointer',
                    )}
                    onClick={toggleUser.bind(null, clinician.ID)}
                  >
                    <div className="h-8 w-8 text-white rounded-full items-center flex justify-center text-xs">
                      {userInitials(`${clinician.fname} ${clinician.lname}`)}
                    </div>
                  </div>
                )}
                {hasSelectedUsers && !selectedUsers[clinician.ID] && (
                  <div className="bg-gray-900/50 w-full h-full rounded-full absolute left-0 top-0 pointer-events-none" />
                )}
              </div>
            );
          })}
        </div>
        {hasRole([
          UserRoles.Admin,
          UserRoles.Provider,
          UserRoles.Staff,
          UserRoles.ClinicalAssistant,
        ]) && (
          <>
            <div className="hidden md:flex md:items-center">
              {selectedTreatment ? (
                <Button
                  text={`Scheduling ${selectedTreatment.name}`}
                  color={ButtonColors.plainWithBorder}
                  icon={<XMarkIcon className="h-6 w-6" />}
                  loading={loadingAvailableSlots}
                  onClick={() => {
                    selectTreatment(null);
                  }}
                />
              ) : (
                <Button
                  color={ButtonColors.primary}
                  text="New Appointment"
                  onClick={() => {
                    navigate(addQueryParams({ open: 'add' }).link);
                  }}
                  extraOptions={treatmentOptions}
                />
              )}
            </div>
            <Menu as="div" className="relative md:hidden">
              <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Open menu</span>
                <EllipsisHorizontalIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-30 mt-3 w-48 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={addQueryParams({ open: 'add' }).link}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm',
                          )}
                        >
                          New Appointment
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </>
        )}
      </div>
    </header>
  );
};

export default ScheduleHeader;
