import { OpenClosedStates } from '@chiroup/components';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { MeContext } from '../../contexts/me.context';
import MaterialsCard from './MaterialsCard';
import { MeClinic, Plan, UserRoles } from '@chiroup/core';
import { getGreaterPlan, MarketingPlan } from './helpers';
import dayjs from 'dayjs';
import MarketingBanner from './MarketingBanner';
import Header from '../layout/Header';
import marketingAddOnsService from '../../services/marketingAddOns.service';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import MarketingBillingModal from './MarketingBillingModal';

export enum ButtonText {
  UnlockMaterials = 'Unlock Materials',
  AccessMaterials = 'Access Materials',
  Pending = 'Pending',
  SelectPlan = 'Select Plan',
}

enum plans {
  education = 0,
  trial = 1,
  basic = 2,
  plus = 3,
  premium = 4,
}

export enum planNames {
  Education = 'education',
  Trial = 'trial',
  Basic = 'basic',
  Plus = 'plus',
  Premium = 'premium',
}

const MarketingAddOns = () => {
  const [state, setState] = useState<OpenClosedStates>(OpenClosedStates.Closed);
  const [type, setType] = useState<Plan>(MarketingPlan.Plus);
  const [contentToShow, setContentToShow] = useState<
    MarketingPlan.Plus | MarketingPlan.Premium | null
  >(null);
  const [showUntil, setShowUntil] = useState<Date | null>(null);
  const [linksToShow, setLinksToShow] = useState<Plan | null>(null);
  const [premiumLink, setPremiumLink] = useState<string>('');
  const [plusLink, setPlusLink] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { me, hasRole } = useContext(MeContext);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);

  const updateStates = (clinic: MeClinic) => {
    const { greaterPlan, isFutureDate, isPlanMatching } =
      getGreaterPlan(clinic);

    setContentToShow(greaterPlan as MarketingPlan.Plus | MarketingPlan.Premium);
    if (!isPlanMatching && isFutureDate) {
      const selectedDate =
        greaterPlan === MarketingPlan.Plus
          ? clinic.plusUntil
          : clinic.premiumUntil;
      setShowUntil(dayjs(selectedDate).endOf('day').toDate());
    } else {
      setShowUntil(null);
    }
  };

  const isPending = useMemo(() => {
    return (
      me.selectedClinic?.postingSchedule !== 'A' &&
      me.selectedClinic?.postingSchedule !== 'B' &&
      (me.selectedClinic?.plan === MarketingPlan.Plus ||
        me.selectedClinic?.plan === MarketingPlan.Premium)
    );
  }, [me.selectedClinic?.postingSchedule, me.selectedClinic?.plan]);

  useEffect(() => {
    if (me.selectedClinic) {
      updateStates(me.selectedClinic);
    }

    const fetchLinks = async () => {
      if (!linksToShow) return;

      setLoading(true);
      try {
        const response = await marketingAddOnsService.list(
          me.selectedClinic?.ID,
        );
        if (contentToShow === MarketingPlan.Premium) {
          setPremiumLink(response.premium);
          setPlusLink(response.plus);
        } else if (contentToShow === MarketingPlan.Plus) {
          setPlusLink(response.plus);
        }
      } catch (error) {
        console.error('Failed to fetch links:', error);
      } finally {
        setLoading(false);
      }
    };
    if (
      (contentToShow === MarketingPlan.Premium ||
        contentToShow === MarketingPlan.Plus) &&
      !isPending
    ) {
      fetchLinks();
    }
  }, [
    linksToShow,
    me.selectedClinic?.ID,
    contentToShow,
    me.selectedClinic,
    isPending,
  ]);

  useEffect(() => {
    if (me.selectedClinic) {
      updateStates(me.selectedClinic);
    }
  }, [me.selectedClinic]);

  useEffect(() => {
    const currentPlanLevel =
      plans[me.selectedClinic?.plan as keyof typeof plans];
    const contentToShowLevel = plans[contentToShow as keyof typeof plans];
    if (
      me.selectedClinic?.plan === contentToShow ||
      currentPlanLevel < contentToShowLevel
    ) {
      setLinksToShow(isPending ? null : (contentToShow as MarketingPlan));
    }
  }, [me.selectedClinic?.plan, contentToShow, isPending]);

  const handleMaterials = (subscriptionType: MarketingPlan) => {
    const contentToShowLevel = plans[contentToShow as keyof typeof plans];
    const subscriptionTypeLevel = plans[subscriptionType as keyof typeof plans];

    // If the current plan and contentToShow are the same and there's no postingSchedule, do nothing
    const allowModalBecauseBasic = linksToShow !== 'basic';
    const allowModalBecauseUpgradingToPremium =
      linksToShow === 'plus' && contentToShow === 'premium';
    if (
      isAccessMaterials(MarketingPlan.Premium) &&
      !isPending &&
      premiumLink &&
      subscriptionType === MarketingPlan.Premium
    ) {
      window.open(premiumLink, '_blank');
      return;
    } else if (
      subscriptionType === MarketingPlan.Plus &&
      (isAccessMaterials(MarketingPlan.Premium) ||
        isAccessMaterials(MarketingPlan.Plus)) &&
      !isPending &&
      plusLink
    ) {
      window.open(plusLink, '_blank');
    }
    if (
      allowModalBecauseBasic &&
      allowModalBecauseUpgradingToPremium &&
      me.selectedClinic?.plan === contentToShow &&
      !me.selectedClinic?.postingSchedule
    ) {
      return;
    }

    // If subscriptionType is greater than contentToShow
    if (subscriptionTypeLevel > contentToShowLevel) {
      setType(subscriptionType);
      setState(OpenClosedStates.Open);
    }

    // If the current plan is different from contentToShow
    else if (me.selectedClinic?.plan !== contentToShow) {
      setType(subscriptionType);
      setState(OpenClosedStates.Open);
    }
  };

  const isAccessMaterials = (plan: string) => {
    return (
      me.selectedClinic?.plan === plan ||
      (contentToShow === plan && !dayjs(showUntil).isSameOrBefore(dayjs()))
    );
  };

  const getButtonText = (plan: string) => {
    if (me.selectedClinic?.plan === planNames.Trial) {
      return ButtonText.SelectPlan;
    }
    const isPlusAccessMaterial = isAccessMaterials(MarketingPlan.Plus);
    const isSameOrAfterToday = dayjs(showUntil).isSameOrAfter(
      dayjs().endOf('day'),
    );

    if (isPending && plan === me.selectedClinic?.plan) {
      return ButtonText.Pending;
    }

    if (contentToShow === plan && !isPending && isSameOrAfterToday) {
      return ButtonText.AccessMaterials;
    }

    return isPlusAccessMaterial && plan === MarketingPlan.Premium
      ? ButtonText.UnlockMaterials
      : isPending
        ? ButtonText.Pending
        : linksToShow?.toLowerCase() === plan.toLowerCase() &&
            isAccessMaterials(plan)
          ? ButtonText.AccessMaterials
          : linksToShow === MarketingPlan.Premium &&
              isAccessMaterials(MarketingPlan.Premium)
            ? ButtonText.AccessMaterials
            : ButtonText.UnlockMaterials;
  };

  const getMaterialsCardProps = (
    title: string,
    subtitle: string,
    description: string,
    plan: MarketingPlan,
  ) => {
    const buttonText = getButtonText(plan);
    const isUnlockMaterials = buttonText === ButtonText.UnlockMaterials;

    const link = isUnlockMaterials
      ? plan === MarketingPlan.Plus
        ? 'https://chiroup.com/plus'
        : 'https://chiroup.com/premium'
      : '';

    return {
      title,
      subtitle:
        contentToShow?.toLowerCase() === plan.toLowerCase()
          ? '(Current Subscription)'
          : subtitle,
      description:
        isAccessMaterials(plan) && !isPending
          ? plan === MarketingPlan.Plus
            ? 'Your plus subscription materials include evidence-based & patient-centric social media blurbs, for your selected social media pages. Access your materials in the link below!'
            : plan === MarketingPlan.Premium
              ? 'Your premium subscription materials include social media blurbs, plus monthly patient and MD newsletters and quarterly attorney newsletters. Way to reach more of your community!'
              : description
          : description,
      contentToShow,
      currentPlan: me.selectedClinic?.plan ?? '',
      buttonText: getButtonText(plan),
      disabled:
        getButtonText(plan) === ButtonText.UnlockMaterials ? false : isPending,
      onClick: () => handleMaterials(plan),
      link,
      linksToShow: linksToShow === plan ? linksToShow : null,
      clinicId: me.selectedClinic?.ID,
      isAdmin: hasRole([UserRoles.Admin]),
      loading,
    };
  };

  return (
    <>
      {(me.selectedClinic?.plan === planNames.Trial ||
        me.selectedClinic?.plan === planNames.Basic) && (
        <Header
          title="Reach More of your community with ChiroUp's professional marketing add-ons"
          afterTitle="Upgrade your clinic's presence today with the right plan for you!"
        />
      )}

      {(showUntil || isPending) && (
        <MarketingBanner
          pending={isPending}
          showUntil={showUntil}
          contentToShow={contentToShow}
          currentPlan={me.selectedClinic?.plan}
        />
      )}
      {contentToShow !== MarketingPlan.Premium && (
        <MaterialsCard
          {...getMaterialsCardProps(
            'Social Media Blurbs',
            '(Plus subscription)',
            `The following benefits are available to Plus subscribers only. Plus materials include automated,
          evidence-based social media posts, posted to your Facebook and/or Instagram every business day.`,
            MarketingPlan.Plus,
          )}
        />
      )}
      <MaterialsCard
        {...getMaterialsCardProps(
          'Premium Community Marketing',
          '(Premium subscription)',
          `The following benefits are available to Premium subscribers only. Premium materials include social media
          blurbs, plus monthly patient and MD newsletters, and quarterly attorney newsletters.`,
          MarketingPlan.Premium,
        )}
      />
      {hasRole([UserRoles.Admin, UserRoles.BusinessManager]) &&
        me.selectedClinic?.plan !== 'basic' &&
        me.selectedClinic?.plan !== 'trial' && (
          <p className="px-6 pb-6 text-sm font-light leading-6 text-gray-900 dark:text-darkGray-100">
            Visit your{' '}
            <Link className={'text-primary-500'} to={'/settings/subscription'}>
              billing portal
            </Link>{' '}
            to make changes to your existing plan.
          </p>
        )}
      <MarketingBillingModal
        state={state}
        setState={setState}
        selectedPlan={type}
        isAdmin={hasRole([UserRoles.Admin])}
      />
    </>
  );
};

export default MarketingAddOns;
