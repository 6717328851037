import { Payor } from './Payor.type';

export type Instances =
  | 'payors'
  | 'billingCodes'
  | 'billingCodePayor'
  | 'noShowCancelFees'
  | 'billingAdjustments'
  | 'billingProfiles'
  | 'compensationRates'
  | 'caseTypes'
  | 'packages'
  | 'customSteps';

export const InstanceFormDefaultValues: {
  [key in Instances]: any;
} = {
  customSteps: {
    id: NaN,
    name: '',
    clinicId: NaN,
  },
  payors: {
    ID: NaN,
    clinicID: NaN,
    legalName: '',
    internalName: '',
    address1: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'US',
    phone: '',
    portalURL: '',
    payorID: '',
    insuranceProgram: undefined,
    insuranceProgramOther: '',
    electronicBilling: false,
    sendsToSecondary: false,
    inNetwork: true,
    billable: true,
    providers: [],
  },
  caseTypes: {
    id: NaN,
    name: '',
    clinicId: undefined,
  },
  billingCodes: {
    ID: NaN,
    clinicID: NaN,
    codeSet: null,
    code: null,
    codeShortcut: null,
    description: null,
    billedAmount: null,
    defaultModifiers: [],
  },
  billingCodePayor: {},
  noShowCancelFees: {},
  billingAdjustments: {},
  billingProfiles: {},
  compensationRates: {},
  packages: {},
};

/**
 * Just FYI, there is a _slight_ difference between showOnList and listField.
 * The former is for the column header and the latter is for the actual data.
 * Using the td property, it is possible to have multiple 'listField' fields
 * be displayed in a single column. An example of this is the defaultModifiers
 * field in the billingCodes instance.
 */
export type SchemaFieldType = {
  label?: string;
  type?: string;
  hidden?: boolean;
  showOnList?: boolean;
  tooltip?: string;
  uiOnly?: boolean;
  listField?: boolean;
  getField?: boolean;
  td?: (row: any) => JSX.Element | string | number | boolean | undefined | null;
  tdClassName?: string;
  tdDivClassName?: string;
  thClassName?: string;
};

export type InstanceSchemaType = {
  [key: string]: SchemaFieldType;
};

type InstanceSchema = {
  [key in Instances]: InstanceSchemaType;
};

export const InstanceSchema: InstanceSchema = {
  customSteps: {
    id: {
      label: 'ID',
      hidden: true,
      type: 'number',
      listField: true,
      getField: true,
    },
    name: {
      label: 'Name',
      type: 'string',
      getField: true,
      showOnList: true,
      listField: true,
    },
  },
  caseTypes: {
    id: {
      label: 'ID',
      hidden: true,
      type: 'number',
      listField: true,
      getField: true,
    },
    name: {
      label: 'Name',
      type: 'string',
      getField: true,
      showOnList: true,
      listField: true,
    },
    deleted: {
      showOnList: false,
      listField: true,
      getField: true,
    },
  },
  payors: {
    ID: {
      label: 'ID',
      // hidden: true,
      type: 'number',
      listField: true,
      getField: true,
    },
    legalName: {
      label: 'Legal Name',
      showOnList: true,
      listField: true,
      getField: true,
    },
    providers: {
      label: 'Providers',
      showOnList: false,
      listField: false,
      getField: false,
    },
    internalName: { label: 'Internal Name', getField: true },
    address1: {
      label: 'Address',
      showOnList: true,
      getField: true,
      listField: true,
    },
    address2: {
      label: 'Address cont.',
      showOnList: false,
      getField: true,
      listField: false,
    },
    city: { label: 'City', getField: true },
    state: { label: 'State/Province', getField: true },
    country: { label: 'Country', getField: true },
    postalCode: { label: 'Zip Code', getField: true },
    phone: { label: 'Phone', getField: true },
    portalURL: { label: 'Portal URL', getField: true },
    payorID: {
      label: 'Payor ID',
      showOnList: true,
      listField: true,
      getField: true,
    },
    maxPerVisit: {
      label: 'Max Allowed Amount Per Visit',
      getField: true,
      listField: false,
      showOnList: false,
    },
    insuranceProgram: { label: 'Insurance Program', getField: true },
    insuranceProgramOther: { label: 'Insurance Program Other', getField: true },
    electronicBilling: {
      label: 'Electronic Billing',
      getField: true,
      listField: true,
      showOnList: false,
    },
    sendsToSecondary: { label: 'Sends to Secondary', getField: true },
    inNetwork: {
      label: 'In Network',
      getField: true,
      listField: true,
      showOnList: false,
    },
    billable: {
      label: 'Billable',
      getField: true,
      listField: true,
      showOnList: false,
    },
  },
  noShowCancelFees: {
    ID: {
      label: 'ID',
      hidden: true,
      type: 'number',
      listField: true,
      getField: true,
    },
    name: {
      label: 'Name',
      showOnList: true,
      listField: true,
      getField: true,
      tdClassName: 'whitespace-nowrap',
    },
    type: {
      label: 'Type',
      showOnList: true,
      listField: true,
      getField: true,
      tdClassName: 'whitespace-nowrap',
    },
    structure: {
      label: 'Structure',
      showOnList: true,
      listField: true,
      getField: true,
      tdClassName: 'whitespace-nowrap',
    },
    value: {
      label: 'Value',
      showOnList: true,
      listField: true,
      getField: true,
      tdClassName: 'whitespace-nowrap',
    },
  },
  billingProfiles: {
    ID: {
      label: 'ID',
      hidden: true,
      type: 'number',
      listField: true,
      getField: true,
    },
    active: {
      label: 'Active',
      showOnList: false,
      listField: true,
      getField: true,
      tdClassName: 'whitespace-nowrap',
    },
    type: {
      label: 'Type',
      showOnList: true,
      listField: true,
      getField: true,
      tdClassName: 'whitespace-nowrap',
    },
    name: {
      label: 'Name',
      showOnList: true,
      listField: true,
      getField: true,
      tdClassName: 'whitespace-nowrap',
    },
    firstName: {
      label: 'First Name',
      showOnList: true,
      listField: true,
      getField: true,
    },
    lastName: {
      label: 'Last Name',
      showOnList: true,
      listField: true,
      getField: true,
    },
    middleInitial: {
      label: 'Middle Initial',
      showOnList: false,
      listField: false,
      getField: true,
    },
    suffix: {
      label: 'Suffix',
      showOnList: false,
      listField: false,
      getField: true,
    },
    addressLine1: {
      label: 'Address',
      showOnList: false,
      listField: false,
      getField: true,
    },
    addressLine2: {
      label: 'Address cont.',
      showOnList: false,
      listField: false,
      getField: true,
    },
    city: {
      label: 'City',
      showOnList: false,
      listField: false,
      getField: true,
    },
    state: {
      label: 'State/Province',
      showOnList: false,
      listField: false,
      getField: true,
    },
    zip: {
      label: 'Zip Code',
      showOnList: false,
      listField: false,
      getField: true,
    },
    phone: {
      label: 'Phone',
      showOnList: false,
      listField: false,
      getField: true,
    },
    extension: {
      label: 'Extension',
      showOnList: false,
      listField: false,
      getField: true,
    },
    email: {
      label: 'Email',
      showOnList: false,
      listField: false,
      getField: true,
    },
    contactName: {
      label: 'Contact Name',
      showOnList: false,
      listField: false,
      getField: true,
    },
    clearinghouse: {
      label: 'Clearinghouse',
      showOnList: true,
      listField: true,
      getField: true,
    },
    taxIdType: {
      label: 'Tax ID Type',
      showOnList: false,
      listField: false,
      getField: true,
    },
    taxId: {
      label: 'Tax ID',
      showOnList: false,
      listField: false,
      getField: true,
    },
    npi: {
      label: 'NPI',
      showOnList: false,
      listField: false,
      getField: true,
    },
    taxonomyCode: {
      label: 'Taxonomy Code',
      showOnList: false,
      listField: false,
      getField: true,
    },
    submitterType: {
      label: 'Submitter Type',
      showOnList: false,
      listField: false,
      getField: true,
    },
    submitterName: {
      label: 'Submitter Name',
      showOnList: false,
      listField: false,
      getField: true,
    },
    submitterFirstName: {
      label: 'Submitter First Name',
      showOnList: false,
      listField: false,
      getField: true,
    },
    submitterMiddleInitial: {
      label: 'Submitter Middle Initial',
      showOnList: false,
      listField: false,
      getField: true,
    },
    submitterLastName: {
      label: 'Submitter Last Name',
      showOnList: false,
      listField: false,
      getField: true,
    },
    submitterPhoneNumber: {
      label: 'Submitter Phone',
      showOnList: false,
      listField: false,
      getField: true,
    },
    submitterExtension: {
      label: 'Submitter Extension',
      showOnList: false,
      listField: false,
      getField: true,
    },
    submitterEmail: {
      label: 'Submitter Email',
      showOnList: false,
      listField: false,
      getField: true,
    },
    submitterContactName: {
      label: 'Submitter Contact Name',
      showOnList: false,
      listField: false,
      getField: true,
    },
    payToAddressLine1: {
      label: 'Submitter Pay-To Address',
      showOnList: false,
      listField: false,
      getField: true,
    },
    payToAddressLine2: {
      label: 'Submitter Pay-To Address cont.',
      showOnList: false,
      listField: false,
      getField: true,
    },
    payToCity: {
      label: 'Pay-To City',
      showOnList: false,
      listField: false,
      getField: true,
    },
    payToState: {
      label: 'Pay-To State/Province',
      showOnList: false,
      listField: false,
      getField: true,
    },
    payToZipCode: {
      label: 'Submitter Pay-To Zip Code',
      showOnList: false,
      listField: false,
      getField: true,
    },
    sameAsBillingProviderInfo: {
      label: 'Same as Billing Provider Info',
      showOnList: false,
      listField: false,
      getField: true,
    },
    payToNonPersonEntity: {
      label: 'Pay-To Non-Person Entity',
      showOnList: false,
      listField: false,
      getField: true,
    },
    payToPerson: {
      label: 'Pay to Person',
      showOnList: false,
      listField: false,
      getField: true,
    },
    providerDesignatedUsers: {
      label: 'Provider Users',
      showOnList: false,
      listField: false,
      getField: false,
    },
  },
  billingAdjustments: {
    ID: {
      label: 'ID',
      hidden: true,
      type: 'number',
      listField: true,
      getField: true,
    },
    clinicId: { getField: true, hidden: true },
    name: {
      label: 'Name',
      showOnList: true,
      listField: true,
      getField: true,
      tdClassName: 'whitespace-nowrap',
    },
    structure: {
      label: 'Structure',
      showOnList: true,
      listField: true,
      getField: true,
      tdClassName: 'whitespace-nowrap',
    },
    value: {
      label: 'Value',
      showOnList: true,
      listField: true,
      getField: true,
      tdClassName: 'whitespace-nowrap',
    },
  },
  billingCodes: {
    ID: {
      label: 'ID',
      hidden: true,
      type: 'number',
      listField: true,
      getField: true,
    },
    clinicID: { getField: true, hidden: true },
    codeSet: {
      label: 'Set',
      showOnList: true,
      listField: true,
      getField: true,
      tdClassName: 'whitespace-nowrap',
    },
    code: {
      label: 'Code',
      showOnList: true,
      listField: true,
      getField: true,
      tdClassName: 'whitespace-nowrap',
    },
    codeShortcut: {
      tdClassName: 'whitespace-nowrap',
      label: 'Shortcut',
      showOnList: true,
      listField: true,
      getField: true,
    },
    billedAmount: {
      label: 'Billed Amount',
      showOnList: true,
      listField: true,
      getField: true,
      tooltip:
        'Enter the full amount you charge for one (1) unit of this code.',
      td: (row: any) => {
        if (!row.billedAmount) return '';
        const resp = `$${row.billedAmount}`;
        return /\.\d$/.test(resp)
          ? `${resp}0`
          : /\.\d\d$/.test(resp)
            ? resp
            : `${resp}.00`;
      },
    },
    /**
     * [2023-09-01.1307 by Brian]
     *    Initially, the default modifiers were on the list/preview. After looking
     *    at the story, this was not needed. However, I left the ability to show
     *    all the modifiers as one field in the preview as an example. It requires
     *    the 'td' method on this guy and adding 'listField' as true for the
     *    modifier[n] fields.
     */
    defaultModifiers: {
      uiOnly: true,
      showOnList: false,
      label: 'Default Modifiers',
      tooltip:
        'There can be up to four default modifiers, each two characters long. Entering a modifier will create a new field for the next.',
      // td: (row: any) => {
      //   return [row.modifier1, row.modifier2, row.modifier3, row.modifier4]
      //     .filter((val: string) => !!val)
      //     .join(', ');
      // },
    },
    modifier1: {
      label: 'Default Modifier #1',
      // listField: true,
      getField: true,
      showOnList: false,
    },
    modifier2: {
      label: 'Default Modifier #2',
      // listField: true,
      showOnList: false,
      getField: true,
    },
    modifier3: {
      label: 'Default Modifier #3',
      // listField: true,
      showOnList: false,
      getField: true,
    },
    modifier4: {
      label: 'Default Modifier #4',
      // listField: true,
      showOnList: false,
      getField: true,
    },
    description: {
      label: 'Description',
      getField: true,
      listField: true,
      showOnList: true,
      tdDivClassName: 'break-normal',
    },
    deleted: {},
  },
  billingCodePayor: {
    billingCodeID: {
      label: 'Billing Code ID',
      type: 'number',
      getField: true,
    },
    payorID: {
      label: 'Payor ID',
      type: 'number',
      getField: true,
    },
    allowedAmount: {
      label: 'Allowed Amount',
      type: 'number',
      getField: true,
    },
    modifier1: {
      label: 'Modifier #1',
      type: 'number',
      getField: true,
    },
    modifier2: {
      label: 'Modifier #2',
      type: 'number',
      getField: true,
    },
    modifier3: {
      label: 'Modifier #3',
      type: 'number',
      getField: true,
    },
    modifier4: {
      label: 'Modifier #4',
      type: 'number',
      getField: true,
    },
    customCode: {
      label: 'Custom Code',
      type: 'string',
      getField: true,
    },
  },
  compensationRates: {
    id: {
      getField: true,
      showOnList: false,
      listField: true,
    },
    name: {
      label: 'Name',
      type: 'string',
      getField: true,
      showOnList: true,
      listField: true,
    },
    rate: {
      label: 'Rate',
      type: 'number',
      getField: true,
      showOnList: true,
      listField: true,
    },
  },
  packages: {
    ID: {
      label: 'ID',
      hidden: true,
      type: 'number',
      listField: true,
      getField: false,
    },
    name: {
      label: 'Package Name',
      type: 'string',
      getField: true,
      showOnList: true,
      listField: true,
    },
    price: {
      label: 'Package Price',
      type: 'number',
      getField: true,
      showOnList: true,
      listField: true,
    },
    collectTax: {
      label: 'Collect Tax',
      type: 'boolean',
      getField: true,
      showOnList: false,
      listField: true,
    },
    expiresAfter: {
      label: 'Expires After',
      type: 'string',
      getField: true,
      showOnList: true,
      listField: true,
    },
    expiresAfterInterval: {
      label: 'Expires After Interval',
      type: 'string',
      getField: true,
      showOnList: true,
      listField: true,
    },
    doesNotExpire: {
      label: 'Does Not Expire',
      type: 'boolean',
      getField: true,
      showOnList: false,
      listField: false,
    },
    totalUses: {
      label: 'Total Number of Uses per Package',
      type: 'number',
      getField: true,
      showOnList: true,
      listField: true,
    },
    treatments: {
      label: 'Eligible Treatments',
      type: 'string',
      getField: false,
      showOnList: false,
      listField: false,
    },
    additionalPayment: {
      label: 'Additional Patient Payment per Use',
      type: 'number',
      getField: true,
      showOnList: true,
      listField: true,
    },
    providers: {
      label: 'Eligible Providers',
      type: 'string',
      getField: false,
      showOnList: false,
      listField: false,
    },
    locations: {
      label: 'Eligible Locations',
      type: 'string',
      getField: false,
      showOnList: false,
      listField: false,
    },
    active: {
      label: 'Active',
      type: 'boolean',
      getField: true,
      showOnList: false,
      listField: true,
    },
  },
};

/**
 * The idea here is that we have sets of fields for listing the items in
 * a database instance and one for getting the entire thing. Ideally,
 * we'd test for 'get' or 'list' and only use those, but this is for a
 * small cadre of coders.
 *
 * @param instanceKey
 * @param target
 * @returns
 */
export const instanceColumnsToGet = (
  instanceKey: Instances,
  target?: string,
  alias?: string,
) => {
  target = target || 'list';
  alias = alias || '';
  return Object.entries(InstanceSchema[instanceKey]).reduce(
    (arr: string[], [key, val]) => {
      if (!!val[`${target}Field` as keyof SchemaFieldType] && !val.uiOnly) {
        arr.push(`${alias ? alias + '.' : ''}\`${key}\``);
      }
      return arr as string[];
    },
    [],
  ) as string[];
};

export type InstanceRowTypes = Payor;
