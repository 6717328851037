export const SCHOOLS = [
  {
    name: 'Campbellsville University',
    address1: '974 University Drive',
    city: 'Harrodsburg',
    state: 'KY',
    zip: '40330',
    country: 'USA',
    phone: '',
    email: '',
    url: '',
  },
  {
    name: 'Cleveland University – Kansas City',
    address1: '10850 Lowell Avenue',
    city: 'Overland Park',
    state: 'KS',
    zip: '66210',
    country: 'USA',
    phone: '913-234-0600',
    email: 'carl.clevelandIII@cleveland.edu',
    url: 'www.cleveland.edu',
  },
  {
    name: 'Durban University of Technology',
    address1: 'PO Box 1334',
    city: 'Durban',
    state: '',
    zip: '4000',
    country: 'ZAF',
    phone: '031-373-2000',
    email: 'info@dut.ac.za',
    url: 'www.dut.ac.za',
  },
  {
    name: "D'Youville College ",
    address1: 'Interim Executive Director 320 Porter Avenue',
    city: 'Buffalo',
    state: 'NY',
    zip: '14201-1084',
    country: 'USA',
    phone: '716-829-7725',
    email: 'warej@dyc.edu',
    url: 'www.dyc.edu',
  },
  {
    name: 'International Medical University Malaysia',
    address1: 'Block H, Unit 9 & 10, Ground Floor',
    address2: 'Setiawalk, Pusat Bandar Puchong',
    city: 'Puchong',
    state: 'Selangor',
    zip: '47160',
    country: 'MYS',
    phone: '603-5879-2323',
    email: '',
    url: 'www.imuhealthcare.com.my',
  },
  {
    name: 'Keiser University',
    address1: '1500 NW 49th St',
    city: 'Fort Lauderdale',
    state: 'FL',
    zip: '33309',
    country: 'USA',
    phone: '954-776-4456',
    email: '',
    url: 'www.keiseruniversity.edu/fort-lauderdale',
  },
  {
    name: 'Life University',
    address1: '1269 Barclay Circle',
    city: 'Marietta',
    state: 'GA',
    zip: '30060',
    country: 'USA',
    phone: '770-426-2601',
    email: 'president@life.edu',
    url: 'www.life.edu',
  },
  {
    name: 'Life Chiropractic College West',
    address1: '25001 Industrial Blvd.',
    city: 'Hayward',
    state: 'CA',
    zip: '94545',
    country: 'USA',
    phone: '510-780-4500 X4555',
    email: 'bkelly@lifewest.edu',
    url: 'www.lifewest.edu',
  },
  {
    name: 'Logan University',
    address1: '1851 Schoettler Road',
    city: 'Chesterfield',
    state: 'MO',
    zip: '63017',
    country: 'USA',
    phone: '636-230-1934',
    email: 'clay.mcdonald@logan.edu',
    url: 'www.logan.edu',
  },
  {
    name: 'Southern California University of Health Sciences',
    address1: 'P.O. Box 1166',
    city: 'Whittier',
    state: 'CA',
    zip: '90609-1166',
    country: 'USA',
    phone: '562-902-3330',
    email: 'president@scuhs.edu',
    url: 'www.scuhs.edu',
  },
  {
    name: 'National University of Health Sciences',
    address1: '200 East Roosevelt Road',
    city: 'Lombard',
    state: 'IL',
    zip: '60148-4583',
    country: 'USA',
    phone: '630-889-6604',
    email: 'jstiefel@nuhs.edu',
    url: 'www.nuhs.edu',
  },
  {
    name: 'National University of Health Sciences – Florida',
    address1: '7200 66th Street N.',
    city: 'Pinellas Park',
    state: 'FL',
    zip: '33781',
    country: 'USA',
    phone: '727-394-6058',
    email: 'dstrauss@nuhs.edu',
    url: 'www.nuhs.edu',
  },
  {
    name: 'Northeast College of Health Sciences',
    address1: 'P.O. Box 800',
    city: 'Seneca Falls',
    state: 'NY',
    zip: '13148-0800',
    country: 'USA',
    phone: '315-568-3100',
    email: 'fnicchi@nycc.edu',
    url: 'www.nycc.edu',
  },
  {
    name: 'Northwestern Health Sciences University',
    address1: '2501 West 84th Street',
    city: 'Bloomington',
    state: 'MN',
    zip: '55431',
    country: 'USA',
    phone: '952-888-4777',
    email: 'ccassirer@nwhealth.edu',
    url: 'www.nwhealth.edu',
  },
  {
    name: 'Palmer College of Chiropractic',
    address1: '1000 Brady Street',
    city: 'Davenport',
    state: 'IA',
    zip: '52803',
    country: 'USA',
    phone: '563-884-5500',
    email: null,
    url: null,
  },
  {
    name: 'Palmer College of Chiropractic West',
    address1: '90 East Tasman Drive',
    city: 'San Jose',
    state: 'CA',
    zip: '95134',
    country: 'USA',
    phone: '408-944-6005',
    email: 'william.meeker@palmer.edu',
    url: 'www.palmer.edu',
  },
  {
    name: 'Palmer College of Chiropractic Florida',
    address1: '4777 City Center Parkway',
    city: 'Port Orange',
    state: 'FL',
    zip: '32129-4153',
    country: 'USA',
    phone: '386-763-2642',
    email: 'peter.martin@palmer.edu',
    url: 'www.palmer.edu',
  },
  {
    name: 'Parker University',
    address1: '2540 Walnut Hill Lane, Suite 200 South',
    city: 'Dallas',
    state: 'TX',
    zip: '75229-5668',
    country: 'USA',
    phone: '214-902-3470',
    email: 'president@parker.edu',
    url: 'www.parker.edu',
  },
  {
    name: 'Texas Chiropractic College',
    address1: 'President 5912 Spencer Highway',
    city: 'Pasadena',
    state: 'TX',
    zip: '77505-1699',
    country: 'USA',
    phone: '281-487-1170',
    email: 'bmckechnie@txchiro.edu',
    url: 'www.txchiro.edu',
  },
  {
    name: 'Universidad Central Del Caribe',
    address1: 'Ave. Laurel, Santa Juanita',
    city: 'Bayamon',
    state: 'PR',
    zip: '00956',
    country: 'PRI',
    phone: '787-798-3001',
    email: 'ucc@uccaribe.edu',
    url: 'https://www.uccaribe.edu/',
  },
  {
    name: 'University of Bridgeport',
    address1: '30 Hazel St.',
    city: 'Bridgeport',
    state: 'CT',
    zip: '06604',
    country: 'USA',
    phone: '203-576-4278',
    email: 'mciolfi@bridgeport.edu',
    url: 'www.bridgeport.edu',
  },
  {
    name: 'University of Western States',
    address1: '2900 N.E. 132nd Avenue',
    city: 'Portland',
    state: 'OR',
    zip: '97230',
    country: 'USA',
    phone: '503-251-5712',
    email: 'jbrimhall@uws.edu',
    url: 'www.uws.edu',
  },
  {
    name: 'Central Queensland University',
    address1: 'QLD 4702',
    city: 'Bruce Highway',
    state: 'North Rockhampton',
    zip: null,
    country: 'AUS',
    phone: '+61 (07) 4923 2244 Ext 2244',
    email: 'a.bowman@cqu.edu.au',
    url: 'www.cqu.edu.au',
  },
  {
    name: 'Macquarie University',
    address1: null,
    city: 'Sydney',
    state: 'NSW',
    zip: '2109',
    country: 'AUS',
    phone: '+61 2 9850 6992',
    email: 'rosemary.giuriato@mq.edu.au',
    url: 'www.chiro.mq.edu.au/Clinics',
  },
  {
    name: 'Murdoch University',
    address1: '90 South Street',
    city: 'Murdoch',
    state: 'Western Australia',
    zip: '6150',
    country: 'AUS',
    phone: '+61 8 9360 1297',
    email: 'bruce.walker@murdoch.edu.au',
    url: 'www.chiropractic.murdoch.edu.au',
  },
  {
    name: 'RMIT University',
    address1: 'Bundoora West Campus, Building 202 Level 4, Plenty Road',
    city: 'Bundoora',
    state: 'Victoria',
    zip: '3083',
    country: 'AUS',
    phone: '+61 3 9925 7275',
    email: 'barry.draper@rmit.edu.au',
    url: 'www.rmit.edu.au/chiropractic',
  },
  {
    name: 'Canadian Memorial Chiropractic College',
    address1: '6100 Leslie Street',
    city: 'Toronto',
    state: 'ON',
    zip: 'M2H 3J1',
    country: 'CAN',
    phone: '+1 416 482 2340',
    email: 'president@cmcc.ca',
    url: 'www.cmcc.ca',
  },
  {
    name: 'Anglo-European College of Chiropractic',
    address1: '13-15 Parkwood Road',
    city: 'Bournemouth',
    state: 'Dorset',
    zip: 'BH5 2DF',
    country: 'GBR',
    phone: '+44 1202 436 200',
    email: 'hthiel@aecc.ac.uk',
    url: 'www.aecc.ac.uk',
  },
  {
    name: 'McTimoney College of Chiropractic',
    address1: '1 Kimber Road',
    city: 'Abingdon',
    state: 'Oxfordshire',
    zip: 'OX14 1BZ',
    country: 'GBR',
    phone: '+44 1235 523336',
    email: 'principal@mctimoney-college.ac.uk',
    url: 'www.mctimoney-college.ac.uk',
  },
  {
    name: 'University of South Wales - Welsh Institute of Chiropractic',
    address1: '',
    city: 'Pontypridd',
    state: 'Wales',
    zip: 'CF37 1DL',
    country: 'GBR',
    phone: '+44 14 4348 2453',
    email: 'david.byfield@southwales.ac.uk',
    url: 'www.southwales.ac.uk',
  },
  {
    name: 'New Zealand College of Chiropractic',
    address1: '6 Harrison Road, Mt Wellington',
    city: 'Newmarket',
    state: 'Auckland',
    zip: '1060',
    country: 'NZL',
    phone: '+64 9 526 6789',
    email: 'chiro@nzchiro.co.nz',
    url: 'www.chiropractic.ac.nz',
  },
  {
    name: 'Barcelona College of Chiropractic',
    address1: 'Balmes Building, Carrer de Balmes, 132',
    city: 'Barcelona',
    state: 'Barcelona',
    zip: '08008',
    country: 'ESP',
    phone: '+34 935 42 28 22',
    email: 'info@bcchiropractic.es',
    url: 'https://bcchiropractic.es/en/contact/',
  },
  {
    name: 'Sherman College of Chiropractic',
    address1: '2020 Springfield Road',
    city: 'Boiling Springs',
    state: 'SC',
    zip: '29316',
    country: 'USA',
    phone: '864-578-8770',
    email: 'admissions@sherman.edu',
    url: 'https://www.sherman.edu/',
  },
  {
    name: 'University of Johannesburg',
    address1: 'PO Box 524',
    city: 'Aukland Park',
    state: 'Johannesburg',
    zip: '2006',
    country: 'ZAF',
    phone: '+27 0 11 559 4555',
    email: 'mylife@uj.ac.za',
    url: 'https://www.uj.ac.za/',
  },
  {
    name: 'London South Bank University',
    address1: '',
    city: '',
    state: '',
    zip: '',
    country: 'GBR',
    phone: '',
    email: '',
    url: 'https://www.lsbu.ac.uk/study/course-finder/chiropractic',
  },
];

export const SORTED_SCHOOLS = SCHOOLS.sort((a, b) =>
  a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
);
