import { SelectOption } from '@chiroup/components';
import { Survey } from '@chiroup/core';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { MeContext } from '../contexts/me.context';
import surveyService from '../services/survey.service';

const useSurveys = () => {
  const meContext = useContext(MeContext);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const { data, isFetching } = useQuery<{
    data: Survey[];
  }>(
    'surveys',
    (key) => surveyService.list(meContext?.me?.selectedClinic?.ID),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    if (data?.data?.length) {
      setOptions(
        data?.data?.map((opt) => ({
          text: opt.name,
          value: opt.id,
        })),
      );
    }
  }, [data]);

  return { isFetching, data, options };
};

export default useSurveys;
