import React from 'react';
import { Link } from 'react-router-dom';
import BreadcrumbArrow from './BreadcrumbArrow';

export type Breadcrumb = {
  text?: string;
  path: string;
};

type Props = {
  breadcrumbs: Breadcrumb[];
};

const Breadcrumbs: React.FC<Props> = ({ breadcrumbs }) => {
  return (
    <nav className="hidden sm:flex items-center text-sm leading-5 font-medium mb-2">
      {breadcrumbs.map((breadcrumb, i) => (
        <React.Fragment key={i}>
          {!!i && <BreadcrumbArrow />}
          <Link
            to={breadcrumb.path}
            className="text-gray-500 dark:text-darkGray-300 hover:text-gray-700 dark:hover:text-darkGray-100 transition duration-150 ease-in-out"
          >
            {breadcrumb.text}
          </Link>
        </React.Fragment>
      ))}
    </nav>
  );
};

export default Breadcrumbs;
