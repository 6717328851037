import { GLOBAL_MANY_IDS_SEP, Modalities } from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';

const modalityService = () => {
  const list = async (
    clinicId: number,
    ids: number[] = [],
  ): Promise<{ data: Modalities[] }> => {
    return ChiroUpAPI.get('api', `/${clinicId}/modality?ids=${ids}`, {});
  };

  /**
   * This differs fromo the 'list' above in that it returns the reference codes
   * attached to each modality -- if any. It returns a singleton or an array of
   * singletons.
   *
   * @param clinicId
   * @param ids
   * @returns
   */
  const get = async (clinicId = -1, id: number): Promise<Modalities> => {
    if (id === -1) return {} as Modalities;
    return ChiroUpAPI.get('api', `/${clinicId}/modality/${id}`, {});
  };

  const getMany = async (clinicId = -1, ids: number[]): Promise<Modalities> => {
    const idsWithNoNegativeOne = ids.filter((id) => String(id) !== '-1');
    if (idsWithNoNegativeOne.length === 0) return {} as Modalities;
    return ChiroUpAPI.get(
      'api',
      `/${clinicId}/modality/${idsWithNoNegativeOne.join(GLOBAL_MANY_IDS_SEP)}`,
      {},
    );
  };

  return {
    list,
    get,
    getMany,
  };
};

export default modalityService();
