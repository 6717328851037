import {
  AppointmentForUI,
  AppointmentStatuses,
  ChiroUpDayJsCommon,
  TailwindColor,
  classNames,
  tailwindColorsHash,
} from '@chiroup/core';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Dayjs } from 'dayjs';
import React from 'react';

type Props = {
  patientName: string;
  pendingPatient?: boolean;
  start: Dayjs;
  className?: string;
  isDragging?: boolean;
  width?: string;
  height?: number;
  timezone: string;
  duration: number;
  isPending?: boolean;
  color: TailwindColor;
  appointment: AppointmentForUI;
};

const AppointmentViewForModal: React.FC<Props> = ({
  patientName,
  start,
  className = '',
  isDragging,
  width,
  height,
  timezone,
  duration,
  color,
  isPending = false,
  appointment,
}) => {
  const colorHex = tailwindColorsHash[color];
  const isaPendingPatient = appointment?.pendingPatient;

  return (
    <div
      className={classNames(
        className,
        'absolute inset-x-0 inset-y-0 pl-1 appointment-view text-gray-900 p-1',
      )}
      style={{
        width: isDragging ? width : 'auto',
        height,
      }}
    >
      <div
        className={classNames(
          'group w-full h-full rounded-lg flex flex-col overflow-hidden pl-2 relative ring-2',
          isPending
            ? `ring-${color}-200 hover:ring-${color}-400`
            : `ring-${color}-300 hover:ring-${color}-500`,
          isDragging
            ? `bg-${color}-100`
            : `bg-${color}-50 hover:bg-${color}-100`,
        )}
      >
        <div
          className="absolute top-0 left-0 w-full h-full rounded-lg"
          style={
            appointment?.status === AppointmentStatuses.Canceled
              ? {
                  background: `repeating-linear-gradient(
                      135deg,
                      ${colorHex}40,
                      ${colorHex}40 4px,
                      ${colorHex}10 4px,
                      ${colorHex}10 8px
                    )`,
                }
              : {}
          }
        />
        <div className={`flow-root${duration === 15 && 'flex flex-row'}`}>
          <div className={`flow-root `}>
            <span className="text-sm">{patientName}</span>
            {duration === 15 && (
              <span className="text-xs">
                {' '}
                {start.format(ChiroUpDayJsCommon.format.time)}
              </span>
            )}
            {isaPendingPatient ? (
              <ExclamationTriangleIcon
                className="absolute w-5 h-5 text-yellow-500 right-1 inset-y-1"
                title="Pending patient."
              />
            ) : null}
          </div>
          {duration !== 15 && (
            <p className="text-xs leading-3">
              <time>
                {start.format(ChiroUpDayJsCommon.format.time)} -
                {start
                  .add(duration, 'minutes')
                  .format(ChiroUpDayJsCommon.format.time)}
              </time>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointmentViewForModal;
