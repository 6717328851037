import { useContext } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import formService, { Attachment } from '../../../services/form.service';

const getQuery = (clinicId = -1) => {
  return async (context: QueryFunctionContext) => {
    return formService.listConsents(clinicId);
  };
};

const useConsents = () => {
  const queryClient = useQueryClient();
  const meContext = useContext(MeContext);
  const { status, data, error, isFetching, refetch } = useQuery<Attachment[]>(
    ['consents', meContext?.me.selectedClinic?.ID],
    getQuery(meContext?.me.selectedClinic?.ID),
    {
      refetchOnWindowFocus: false,
      retry: false,
      // Stale time = 15 minutes
      staleTime: 1000 * 60 * 15,
    },
  );

  const addToConsents = (item: Attachment) => {
    queryClient.setQueryData(
      ['consents', meContext?.me.selectedClinic?.ID],
      (data || []).concat(item),
    );
  };

  const removeFromConsents = (url: string) => {
    queryClient.setQueryData(
      ['consents', meContext?.me.selectedClinic?.ID],
      (data || []).filter((item) => item.url !== url),
    );
  };

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
    addToConsents,
    removeFromConsents,
  };
};

export default useConsents;
