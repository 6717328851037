import Decimal from 'decimal.js';
import { createDecimal } from './createDecimal';

type CalculationInput = {
  totalServices: number | string;
  coPay?: number | string;
  coInsurance?: number | string;
  deductible?: number | string;
};

const ONE_HUNDRED = createDecimal(100);
const ZERO = createDecimal(0);

export const calculatePatientResponsibility = ({
  totalServices,
  coPay = 0,
  coInsurance = 0,
  deductible = 0,
}: CalculationInput): string => {
  const total = createDecimal(totalServices || 0).toDP(2);
  const coPayDecimal = createDecimal(coPay || 0).toDP(2);
  const coInsuranceDecimal = createDecimal(coInsurance || 0)
    .div(ONE_HUNDRED)
    .toDP(2);
  const deductibleDecimal = createDecimal(deductible || 0).toDP(2);

  // Subtract Co-Pay first
  let remainingAmount = total.sub(coPayDecimal).lessThan(ZERO)
    ? ZERO
    : total.sub(coPayDecimal);

  // Then, subtract Deductible
  const deductibleApplied = Decimal.min(remainingAmount, deductibleDecimal);
  remainingAmount = remainingAmount.sub(deductibleApplied);

  // Apply Coinsurance to the remaining amount
  const coInsuranceAmount = remainingAmount.mul(coInsuranceDecimal);

  // Calculate patient responsibility
  let patientResponsibility = coPayDecimal
    .add(deductibleApplied)
    .add(coInsuranceAmount);

  // Ensure patient responsibility does not exceed total charges and is not negative
  patientResponsibility = Decimal.min(patientResponsibility, total).lessThan(
    ZERO,
  )
    ? ZERO
    : patientResponsibility;

  return patientResponsibility.toFixed(2);
};
