import { classNames } from '@chiroup/core';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import React, { useEffect, useState } from 'react';
import { Loading } from '../Loading';
import DatepickerDays from './DatepickerDays';
import DatepickerDaysOfWeek from './DatepickerDaysOfWeek';

dayjs.extend(isSameOrAfter);

type Props = {
  selectedDate?: Dayjs;
  setSelectedDate: (date: Dayjs) => void;
  onChange: (val: string) => void;
  onChangeMonth: (year: string, month: string) => void;
  primaryColor: string;
  className?: string;
  dottedDays: {
    [key: number]: boolean;
  };
  loading: boolean;
  viewDate: Dayjs;
  setViewDate: (date: Dayjs) => void;
};

export const DatepickerAlt: React.FC<Props> = ({
  selectedDate,
  setSelectedDate,
  onChange,
  onChangeMonth,
  primaryColor,
  className = '',
  dottedDays,
  loading,
  viewDate,
  setViewDate,
}) => {
  const [skipDays, setSkipDays] = useState(viewDate.startOf('month').day());

  useEffect(() => {
    setSkipDays(viewDate.startOf('month').day());
  }, [viewDate]);

  const allowPreviousYear = () => {
    const oneYearAgoViewDate = viewDate.startOf('M').subtract(1, 'year');
    const furthestBack = dayjs().startOf('M');
    return oneYearAgoViewDate.isSameOrAfter(furthestBack);
  };

  const allowPreviousMonth = () => {
    const oneMonthAgoViewDate = viewDate.startOf('M').subtract(1, 'month');
    const furthestBack = dayjs().startOf('M');
    return oneMonthAgoViewDate.isSameOrAfter(furthestBack);
  };

  const prevMonth = () => {
    const newDay = viewDate.subtract(1, 'month');
    setViewDate(newDay);
    onChangeMonth(newDay.format('YYYY'), newDay.format('MM'));
  };

  const nextMonth = () => {
    const newDay = viewDate.add(1, 'month');
    setViewDate(newDay);
    onChangeMonth(newDay.format('YYYY'), newDay.format('MM'));
  };

  const prevYear = () => {
    const newDay = viewDate.subtract(1, 'year');
    setViewDate(newDay);
    onChangeMonth(newDay.format('YYYY'), newDay.format('MM'));
  };

  const nextYear = () => {
    const newDay = viewDate.add(1, 'year');
    setViewDate(newDay);
    onChangeMonth(newDay.format('YYYY'), newDay.format('MM'));
  };

  const selectDate = (day: number) => {
    const newDate = viewDate.set('date', day);
    setSelectedDate(newDate);
    onChange(newDate.format('YYYY-MM-DD'));
  };

  return (
    <div className={className}>
      <div className="text-sm relative">
        {loading && (
          <div className="absolute inset-0 flex justify-center items-center w-full h-full z-10">
            <Loading color="text-gray-500" />
          </div>
        )}
        <div className={loading ? 'opacity-25' : ''}>
          <div className="flex justify-between pb-6">
            <div>
              <button
                onClick={prevYear}
                type="button"
                className={classNames(
                  'h-8 w-8 rounded-full border-2 border-transparent p-1 transition duration-150 ease-in-out focus:outline-none',
                  allowPreviousYear()
                    ? 'cursor-pointer text-gray-500 hover:text-gray-900 focus:text-gray-900'
                    : 'cursor-not-allowed text-gray-400',
                )}
                aria-label="Previous year"
                disabled={loading || !allowPreviousYear()}
              >
                <ChevronDoubleLeftIcon />
              </button>
              <button
                onClick={prevMonth}
                type="button"
                className={classNames(
                  'h-8 w-8 rounded-full border-2 border-transparent p-1 transition duration-150 ease-in-out focus:outline-none',
                  allowPreviousMonth()
                    ? 'cursor-pointer text-gray-500 hover:text-gray-900 focus:text-gray-900'
                    : 'cursor-not-allowed text-gray-400',
                )}
                aria-label="Previous month"
                disabled={loading || !allowPreviousMonth()}
              >
                <ChevronLeftIcon />
              </button>
            </div>
            <div className="place-self-center p-1 px-3 rounded-full text-gray-900 transition duration-150 ease-in-out">
              {viewDate.format('MMMM YYYY')}
            </div>
            <div>
              <button
                onClick={nextMonth}
                type="button"
                className="h-8 w-8 rounded-full border-2 border-transparent p-1 text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:text-gray-900 focus:outline-none"
                aria-label="Next month"
                disabled={loading}
              >
                <ChevronRightIcon />
              </button>
              <button
                onClick={nextYear}
                type="button"
                className="h-8 w-8 rounded-full border-2 border-transparent p-1 text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:text-gray-900 focus:outline-none"
                aria-label="Next year"
                disabled={loading}
              >
                <ChevronDoubleRightIcon />
              </button>
            </div>
          </div>
          <div className="grid grid-cols-7 gap-4">
            <DatepickerDaysOfWeek />
            <DatepickerDays
              days={viewDate.daysInMonth()}
              skipDays={skipDays}
              selectDate={selectDate}
              selectedDate={selectedDate}
              viewDate={viewDate}
              futureOnly={true}
              primaryColor={primaryColor}
              dottedDays={dottedDays}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
