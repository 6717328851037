import { useContext, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { databaseService } from './database.service';
import {
  ChiroUpJSON,
  InstanceFormDefaultValues,
  InstanceKeys,
  Instances,
  applyDatabaseSaveRules,
} from '@chiroup/core';
import { MeContext } from '../../../contexts/me.context';

const getDatabaseItem = <T>(skipInitialFetch?: boolean) => {
  return async (context: QueryFunctionContext) => {
    if (skipInitialFetch) {
      return;
    }
    const clinicId = context.queryKey[1] as number;
    const instanceKey = context.queryKey[2] as string;
    const id = context.queryKey[3];

    if (id === 'add') {
      return InstanceFormDefaultValues[instanceKey as Instances] as T;
    }

    return databaseService.get<T>({
      clinicId,
      database: instanceKey,
      id: id as number,
    });
  };
};

const useDatabaseItem = <T>({
  instanceKey,
  id,
  skipInitialFetch = false,
}: {
  instanceKey: string;
  id: number | 'add';
  skipInitialFetch?: boolean;
}) => {
  const { me } = useContext(MeContext);
  const queryClient = useQueryClient();
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { data, isFetching } = useQuery({
    queryKey: ['databaseItem', me.selectedClinic?.ID, instanceKey, id],
    queryFn: getDatabaseItem<T>(skipInitialFetch),
    enabled: !!instanceKey && !!id,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });

  const save = async (toSaveItem: T, force = false) => {
    // We mash it up to send, and do not want to change the original.
    const item = ChiroUpJSON.clone(toSaveItem);
    const clinicId = me.selectedClinic?.ID,
      errors = applyDatabaseSaveRules({
        instanceKey: instanceKey as InstanceKeys,
        item,
        clinicId,
      });

    if (instanceKey === InstanceKeys.billingCodes) {
      /**
       * Going to scrub some data that the backend does not need
       * as it just makes the payload larger.
       */
      const cleanupObject = item as any;
      if (
        cleanupObject &&
        cleanupObject.payors &&
        Array.isArray(cleanupObject.payors)
      ) {
        delete cleanupObject.usageCount;
        cleanupObject.payors.forEach((payor: any) => {
          delete payor.legalName;
          delete payor.billingCodeID;
          for (const key in payor) {
            if (payor[key] === null) {
              delete payor[key];
            }
          }
        });
      }
    }

    if (errors?.form?.length) {
      throw new Error(ChiroUpJSON.stringify({ statusCode: 400, errors }));
    }

    setIsSaving(true);

    const res = await databaseService.save<T>({
      clinicId: clinicId ?? -1,
      database: instanceKey,
      item,
      force,
    });

    if (
      instanceKey === InstanceKeys.compensationRates &&
      !force &&
      (res as any)?.message
    ) {
      return res;
    }

    queryClient.invalidateQueries([
      'databaseItem',
      me.selectedClinic?.ID,
      instanceKey,
      id,
    ]);
    queryClient.invalidateQueries(['database']);
    setIsSaving(false);
    return res;
  };

  const del = async (idToDelete: number) => {
    // Just in case we somehow get in a bad state, let's not trust the id in the hook!
    const clinicId = me.selectedClinic?.ID;
    if (!clinicId) {
      return;
    }
    setIsDeleting(true);
    const res = await databaseService.del({
      clinicId,
      database: instanceKey,
      id: idToDelete,
    });
    queryClient.invalidateQueries([
      'databaseItem',
      me.selectedClinic?.ID,
      instanceKey,
      idToDelete,
    ]);
    queryClient.invalidateQueries(['database']);
    setIsDeleting(false);
    return res;
  };

  return {
    data,
    isFetching,
    isSaving,
    save,
    del,
    isDeleting,
  };
};

export default useDatabaseItem;
