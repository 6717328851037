import { PatientTransactionItemType } from '../types';
import { ChiroUpJSON } from './ChiroUpJSON';

//When we do insurance calculations, allowed amounts, etc...  Duplicate services are
// combined into one service with the total units and the first service's amount
// partly because there is a ton of code in calculate responsibility and appointmentInsurance that assumes a code is unique
// and partly because it is a bit confusing to have the same service listed multiple times and they should really always have the same allowed amount
// Emily said we can ASSUME (That's becoming a terrifying word) that the allowed amount will always be the same for duplicate services
// This will also have to be done in either calculate responsibility or in the before services are passed to calculate responsibility
// When this goes on the claim they will need to be separated out again because there are supposed to be different modifiers for each  duplicate service
// Not sure how we want to handle if the modifiers are the same?  Should probably recombine them in that case

export const handleDuplicateServices = (
  services: PatientTransactionItemType[],
) => {
  const servicesCopy = ChiroUpJSON.clone(services);
  const codeMap = new Map<string, { totalUnits: number; firstIndex: number }>();

  servicesCopy.forEach((service: PatientTransactionItemType, index: number) => {
    const code = service.code;
    if (code) {
      const units = service.units || 1;
      if (codeMap.has(code)) {
        const entry = codeMap.get(code)!;
        entry.totalUnits += units;
      } else {
        codeMap.set(code, { totalUnits: units, firstIndex: index });
      }
    }
  });

  codeMap.forEach((entry, code) => {
    const { firstIndex, totalUnits } = entry;
    const firstService = servicesCopy[firstIndex];
    const firstUnits = firstService.units || 1;
    if (totalUnits > firstUnits) {
      firstService.units = totalUnits;
      servicesCopy.forEach(
        (service: PatientTransactionItemType, index: number) => {
          if (service.code === code && index !== firstIndex) {
            service.isDuplicate = true;
          }
        },
      );
    }
  });

  return {
    deduped: servicesCopy.filter(
      (s: PatientTransactionItemType) => !s.isDuplicate,
    ),
    duplicates: servicesCopy.filter(
      (s: PatientTransactionItemType) => s.isDuplicate,
    ),
    original: services,
  };
};
