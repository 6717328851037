import { classNames } from '@chiroup/core';
import { icons } from '../icons';
import { Icon } from '../Icon';

type Props = {
  icon: keyof typeof icons;
  onClick: (e: Event) => void;
  isActive?: boolean;
  disabled?: boolean;
};

const EditorIcon: React.FC<Props> = ({ icon, onClick, isActive, disabled }) => {
  return (
    <button
      type="button"
      className={classNames(
        'rounded-md hover:bg-gray-50 cursor-pointer p-1 dark:hover:bg-darkGray-600',
        disabled ? 'opacity-50' : '',
        isActive ? 'text-primary-500' : 'text-gray-900 dark:text-darkGray-200',
      )}
    >
      <Icon icon={icon} onClick={onClick} />
    </button>
  );
};

export default EditorIcon;
