import { classNames } from '@chiroup/core';

type Props = {
  textClassName?: string;
  containerClassName?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  title?: string;
  disabled?: boolean;
  isBillingStarted?: boolean;
};

export const AddAllButton: React.FC<Props> = ({
  textClassName = 'text-white',
  containerClassName = 'w-6 h-6 -mr-4 mt-1 p-4 rounded-md cursor-pointer border-2 border-gray-300',
  title = '*** Perform some click Action ***',
  onClick = (e: any) => {
    console.warn('Make sure you add a click handler!', e);
  },
  disabled = false,
  isBillingStarted = false,
}) => {
  const useThisOnClick = disabled ? () => {} : onClick;
  return (
    <div
      className={classNames(
        'relative',
        disabled
          ? 'bg-gray-300'
          : isBillingStarted
            ? 'bg-accent-200 hover:bg-accent-600'
            : 'bg-primary-200 hover:bg-primary-600',
        containerClassName,
        textClassName,
      )}
    >
      <div
        className={classNames(
          'w-4 absolute bottom-1 left-1',
          disabled ? 'cursor-not-allowed' : '',
        )}
        onClick={useThisOnClick}
        title={title}
      >
        {' '}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 4.75V4C11.8063 4 11.62 4.075 11.4803 4.2093L12 4.75ZM19.25 4.75H20C20 4.33579 19.6642 4 19.25 4V4.75ZM18.5 12C18.5 12.4142 18.8358 12.75 19.25 12.75C19.6642 12.75 20 12.4142 20 12H18.5ZM9.13685 19.1359C9.42031 19.4379 9.89495 19.4529 10.197 19.1695C10.499 18.886 10.5141 18.4114 10.2306 18.1093L9.13685 19.1359ZM5.28997 13.941L5.83684 13.4277L5.83683 13.4277L5.28997 13.941ZM5.3623 11.1305L5.88204 11.6712L5.88204 11.6712L5.3623 11.1305ZM12 5.5H19.25V4H12V5.5ZM18.5 4.75V12H20V4.75H18.5ZM10.2306 18.1093L5.83684 13.4277L4.74309 14.4543L9.13685 19.1359L10.2306 18.1093ZM5.83683 13.4277C5.36839 12.9286 5.38854 12.1456 5.88204 11.6712L4.84255 10.5898C3.75683 11.6334 3.7125 13.3562 4.7431 14.4543L5.83683 13.4277ZM5.88204 11.6712L12.5198 5.2907L11.4803 4.2093L4.84255 10.5898L5.88204 11.6712Z"
            fill="currentColor"
          />
          <path
            d="M15 9V10C15.5523 10 16 9.55228 16 9H15ZM15 9H14C14 9.55228 14.4477 10 15 10V9ZM15 9V8C14.4477 8 14 8.44772 14 9H15ZM15 9H16C16 8.44772 15.5523 8 15 8V9Z"
            fill="currentColor"
          />
          <path
            d="M16.75 14.75C16.75 14.3358 16.4142 14 16 14C15.5858 14 15.25 14.3358 15.25 14.75H16.75ZM15.25 19.25C15.25 19.6642 15.5858 20 16 20C16.4142 20 16.75 19.6642 16.75 19.25H15.25ZM15.25 14.75V19.25H16.75V14.75H15.25Z"
            fill="currentColor"
          />
          <path
            d="M18.25 17.75C18.6642 17.75 19 17.4142 19 17C19 16.5858 18.6642 16.25 18.25 16.25L18.25 17.75ZM13.75 16.25C13.3358 16.25 13 16.5858 13 17C13 17.4142 13.3358 17.75 13.75 17.75L13.75 16.25ZM18.25 16.25L13.75 16.25L13.75 17.75L18.25 17.75L18.25 16.25Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  );
};
