import React from 'react';
import StarIcon from '../icons/StarIcon';
import { EyeIcon } from '@heroicons/react/24/outline';
import { Loading } from '@chiroup/components';

type Props = {
  label: string;
  value?: string | React.ReactNode;
  star?: boolean;
  show?: boolean;
  unHide?: boolean;
  unHideFunction?: () => void;
  isFetchingHidden?: boolean;
  elementTitle?: string | null;
};

const InfoItem: React.FC<Props> = ({
  label,
  value,
  star = false,
  show = true,
  unHide = false,
  unHideFunction,
  isFetchingHidden = false,
  elementTitle = '',
}) => {
  if (!show || !value) {
    return null;
  }
  return (
    <div className="sm:col-span-1" title={elementTitle ? elementTitle : ''}>
      <dt className="flex gap-x-1 justify-items-center text-sm leading-5 font-medium text-gray-500 dark:text-darkGray-400">
        {label}
        {unHide && !isFetchingHidden ? (
          <EyeIcon
            className="w-4 h-4 cursor-pointer mt-[2px] text-gray-500 dark:text-darkGray-400 hover:text-gray-700 dark:hover:text-darkGray-300 transition duration-150 ease-in-out"
            onClick={unHideFunction}
          />
        ) : isFetchingHidden ? (
          <Loading
            size={4}
            color="text-grey-30 mt-[2px] text-gray-500 dark:text-darkGray-400"
          />
        ) : null}
      </dt>
      <dd className="mt-1 text-sm leading-5 text-gray-900 dark:text-darkGray-100 flex">
        {value} {star && <StarIcon />}
      </dd>
    </div>
  );
};

export default InfoItem;
