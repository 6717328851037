import { ChiroUpAPI } from '@chiroup/client-core';

const marketingAddOnsService = () => {
  const list = async (
    clinicId?: number,
  ): Promise<{ premium: string; plus: string }> => {
    return ChiroUpAPI.get('api', `/settings/${clinicId}/marketing-addons`, {});
  };

  return {
    list,
  };
};

export default marketingAddOnsService();
