import { ClinicCaseType } from '@chiroup/core';
import DollarBadge from './DollarBadge';
import HealthInsuranceBadge from './HealthInsuranceBadge';
import PersonalInjuryLabelBadge from './PersonalInjuryLabelBadge';
import WorkerCompLabelBadge from './WorkerCompLabelBadge';
import MedicareLabelBadge from './MedicareLabelBadge';
import CHUSALabelBadge from './CHUSALabelBadge';
import AutoLabelBadge from './AutoLabelBadge';
import { ShieldCheckIcon } from '@heroicons/react/24/outline';
import CustomCaseBadge from './CustomCaseBadge';

export enum CaseTypes {
  Cash = 'Cash',
  HealthInsurance = 'Health Insurance',
  PersonalInjury = 'Personal Injury',
  WorkersCompensation = 'Workers Compensation',
  Medicare = 'Medicare',
  CHUSA = 'CHUSA',
  AutoAccident = 'Auto Accident',
}

export const getCaseTypeIcon = ({
  caseType,
  textOnly = false,
  caseTypes,
  hasActiveInsurance,
  size = 24,
}: {
  caseType: number | undefined;
  textOnly?: boolean;
  caseTypes: Partial<ClinicCaseType>[];
  hasActiveInsurance: boolean;
  size?: number;
}): JSX.Element => {
  const caseTypeObj = caseTypes.find((c) => c.id === caseType);

  if (!caseTypeObj?.name && !textOnly) {
    return hasActiveInsurance ? (
      <ShieldCheckIcon className="w- h-6 text-primary-600 inline-flex items-center" />
    ) : (
      <></>
    );
  }

  const iconProps = { height: size, width: size };
  if (caseTypeObj?.name) {
    if (textOnly) {
      return <span>{caseTypeObj.name}</span>;
    }

    switch (caseTypeObj.name) {
      case CaseTypes.Cash:
        return (
          <span
            className="flex-inline items-center"
            style={{ verticalAlign: 'middle' }}
          >
            <DollarBadge {...iconProps} />
          </span>
        );
      case CaseTypes.HealthInsurance:
        return (
          <span
            className="flex-inline items-center"
            style={{ verticalAlign: 'middle' }}
          >
            <HealthInsuranceBadge {...iconProps} />
          </span>
        );
      case CaseTypes.PersonalInjury:
        return (
          <span
            className="flex-inline items-center"
            style={{ verticalAlign: 'middle' }}
          >
            <PersonalInjuryLabelBadge {...iconProps} />
          </span>
        );
      case CaseTypes.WorkersCompensation:
        return (
          <span
            className="flex-inline items-center"
            style={{ verticalAlign: 'middle' }}
          >
            <WorkerCompLabelBadge {...iconProps} />
          </span>
        );
      case CaseTypes.Medicare:
        return (
          <span
            className="flex-inline items-center"
            style={{ verticalAlign: 'middle' }}
          >
            <MedicareLabelBadge {...iconProps} />
          </span>
        );
      case CaseTypes.CHUSA:
        return (
          <span
            className="flex-inline items-center"
            style={{ verticalAlign: 'middle' }}
          >
            <CHUSALabelBadge {...iconProps} />
          </span>
        );
      case CaseTypes.AutoAccident:
        return (
          <span
            className="flex-inline items-center"
            style={{ verticalAlign: 'middle' }}
          >
            <AutoLabelBadge {...iconProps} />
          </span>
        );
      default:
        return (
          <span
            className="flex-inline items-center"
            style={{ verticalAlign: 'middle' }}
          >
            <CustomCaseBadge tooltip={caseTypeObj.name} {...iconProps} />
          </span>
        );
    }
  } else return <></>;
};
