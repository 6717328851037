export const fileTypes = [
  'png',
  'jpg',
  'jpeg',
  'gif',
  'mp4',
  'wmv',
  'mov',
  'pdf',
  'txt',
  'doc',
  'docx',
  'rtf',
];
