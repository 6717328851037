import Button, { ButtonColors } from '../common/Button';
import { Link } from 'react-router-dom';
import { Plan } from '@chiroup/core';
import { SparklesIcon } from '@heroicons/react/20/solid';
import { ButtonText, planNames } from './MarketingAddOns';
import { useNavigate } from 'react-router';

type Props = {
  title: string;
  subtitle: string;
  description: string;
  buttonText: string;
  link?: string;
  disabled: boolean;
  onClick: () => void;
  currentPlan: string;
  linksToShow?: Plan | null;
  clinicId?: number;
  loading?: boolean;
  isAdmin: boolean;
};

const MaterialsCard: React.FC<Props> = ({
  title,
  subtitle,
  description,
  buttonText,
  disabled,
  onClick,
  link,
  currentPlan,
  linksToShow,
  loading,
  isAdmin,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (currentPlan === planNames.Trial) {
      navigate('/settings/subscription');
    } else {
      onClick();
    }
  };

  const isDisabled = () =>
    disabled ||
    ((buttonText === ButtonText.UnlockMaterials ||
      buttonText === ButtonText.SelectPlan) &&
      !isAdmin);

  const isLoading = () =>
    loading &&
    currentPlan !== planNames.Trial &&
    buttonText !== ButtonText.UnlockMaterials;

  return (
    <div className="m-6 p-4 bg-white dark:bg-darkGray-700 rounded-lg shadow">
      <div className="flex items-baseline">
        <span className="text-lg print:text-md font-medium text-gray-900 dark:text-darkGray-100">
          {title}
        </span>
        <span className="text-sm leading-6 font-light text-gray-900 dark:text-darkGray-100 ml-2">
          {subtitle}
          {buttonText !== ButtonText.AccessMaterials && (
            <SparklesIcon className="inline align-middle ml-1 h-4 w-4 text-yellow-300" />
          )}
        </span>
      </div>
      <p className="text-md leading-6 text-gray-900 dark:text-darkGray-100 mt-2">
        {description}
      </p>
      {!!link && !linksToShow && (
        <div>
          <Link className="text-sm text-primary-500" to={link} target="_blank">
            View Sample
          </Link>
        </div>
      )}
      {currentPlan !== planNames.Education && (
        <Button
          text={buttonText}
          className="my-4"
          disabled={isDisabled()}
          onClick={handleClick}
          color={ButtonColors.orange}
          loading={isLoading()}
        />
      )}
    </div>
  );
};

export default MaterialsCard;
