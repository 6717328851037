import React, { useContext, useMemo } from 'react';
import { AppointmentsContext } from '../../contexts/appointments.context';

type Props = {
  minMaxTime: {
    minTime: number;
    maxTime: number;
  };
};

const ScheduleHours: React.FC<Props> = ({
  minMaxTime: { minTime, maxTime },
}) => {
  const { zoom } = useContext(AppointmentsContext);

  const gridTemplateRows = `repeat(${
    (maxTime - minTime) * zoom
  }, minmax(60px, 1fr))`;

  const hourRows = useMemo(() => {
    // Convert the time from 1 to 1AM, etc.
    const time = (t: number) => {
      if (t === 0) return '12AM';
      if (t === 12) return '12PM';
      if (t < 12) return `${t}AM`;
      return `${t - 12}PM`;
    };

    const rows = [];

    for (let i = minTime; i < maxTime; i++) {
      rows.push(
        <div
          key={i}
          className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 dark:text-gray-100"
        >
          {i === minTime ? null : time(i)}
        </div>,
      );
      const zoomArr = Array.from({ length: zoom - 1 }, (_, i) => i);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      zoomArr.forEach((_, ii) => {
        rows.push(<div key={`${i}-${ii}-extra`} />);
      });
    }

    return rows;
  }, [maxTime, minTime, zoom]);

  return (
    <div
      className="col-start-1 row-start-1 grid w-full text-right text-xs leading-5 text-gray-400"
      style={{ gridTemplateRows }}
    >
      {hourRows}
    </div>
  );
};

export default ScheduleHours;
