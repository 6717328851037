import { XCircleIcon } from '@heroicons/react/20/solid';

type Props = {
  title?: string;
  error?: string;
};

const Alert: React.FC<Props> = ({
  title = 'Error',
  error = 'An error occurred',
}) => {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm text-left font-medium text-red-800">
            {title}
          </h3>
          <div className="mt-2 text-left text-sm text-red-700">{error}</div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
