export enum NotificationTypes {
  Invite = 'Invite',
  InviteResponse = 'InviteResponse',
  SurveyResponse = 'SurveyResponse',
  IconUpdate = 'IconUpdate',
  Global = 'Global',
}

export enum NotificationIcons {
  OfficeBuilding = 'OfficeBuilding',
  User = 'User',
  Update = 'Update',
}

export enum NotificationColors {
  Primary500 = 'Primary500',
}

export type NotificationSnsEvent = {
  id: string;
  userId: string;
  clinicId: number;
  type: NotificationTypes;
  message: string;
  icon: NotificationIcons;
  color: NotificationColors;
  createdAt: number;
};

export type Notification = NotificationSnsEvent & {
  userIdAndType: string;
  clinicIdAndUserId: string;
  clinicIdAndUserIdAndType: string;
  clinicIdAndType: string;
  patientId?: string;
};

export enum PatientNotifications {
  AppointmentScheduled = 'AppointmentScheduled',
  AppointmentRescheduled = 'AppointmentRescheduled',
  AppointmentCanceled = 'AppointmentCanceled',
  AppointmentReminder = 'AppointmentReminder',
  RecurringAppointmentsScheduled = 'RecurringAppointmentsScheduled',
  RequestForInfo = 'RequestForInfo',
}

export const notificationSettingHash: {
  [key in PatientNotifications]?: string;
} = {
  [PatientNotifications.AppointmentScheduled]:
    'notificationAppointmentScheduled',
  [PatientNotifications.RecurringAppointmentsScheduled]:
    'notificationRecurringAppointmentsScheduled',
  [PatientNotifications.AppointmentRescheduled]:
    'notificationAppointmentRecheduled',
  [PatientNotifications.AppointmentCanceled]: 'notificationAppointmentCanceled',
  [PatientNotifications.AppointmentReminder]: 'notificationAppointmentReminder',
};
