import { classNames } from '@chiroup/core';
import { useContext, useMemo } from 'react';
import { AppointmentsContext } from '../../contexts/appointments.context';

type Props = {
  top: number;
  time: string;
  minTime: number;
  maxTime: number;
};

const CurrentTime: React.FC<Props> = ({ top, time, minTime, maxTime }) => {
  const { zoom } = useContext(AppointmentsContext);

  const pixelsPerHour = useMemo(() => {
    return 60 * zoom;
  }, [zoom]);

  const offset = useMemo(() => {
    return minTime * pixelsPerHour;
  }, [minTime, pixelsPerHour]);

  const realTop = useMemo(() => {
    return top - offset;
  }, [top, offset]);

  const maxTimeInPixels = useMemo(() => {
    return maxTime * pixelsPerHour - offset;
  }, [maxTime, offset, pixelsPerHour]);

  const minTimeInPixels = useMemo(() => {
    return minTime * pixelsPerHour - offset;
  }, [minTime, offset, pixelsPerHour]);

  return (
    <div
      className={classNames(
        'absolute left-2 bg-gray-500 w-14 h-5 z-50 text-white text-xs rounded-md flex items-center justify-center dark:text-gray-100',
        realTop > maxTimeInPixels || realTop < minTimeInPixels ? 'hidden' : '',
      )}
      style={{
        top: realTop - 9.5,
      }}
    >
      {time}
    </div>
  );
};

export default CurrentTime;
