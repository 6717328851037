import { FormError } from '@chiroup/core';
import React, { ChangeEvent } from 'react';
import { SelectOption } from '.';
import { FieldErrors } from './FieldErrors';

type Props = {
  className?: string;
  errors?: FormError;
  label?: string | JSX.Element;
  name?: string;
  onChange?: (val: any) => void;
  value?: boolean | number | string;
  options?: SelectOption[];
  disabled?: boolean;
  title?: string;
  optionsClassName?: string;
};

export const RadioGroup: React.FC<Props> = ({
  className,
  errors,
  label,
  name,
  onChange,
  value,
  options = [],
  disabled,
  title,
  optionsClassName, //  This REPLACES the default.
}) => {
  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  return (
    <div className={className}>
      <div>
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-5 text-gray-900 dark:text-darkGray-400 sm:mt-px sm:pt-2"
        >
          {label}
        </label>
      </div>
      <div className={optionsClassName || 'space-y-2 mt-2'} title={title}>
        {options.map((opt, i) => (
          <div className="flex items-center" key={i}>
            <input
              id={`${name}-${opt.value}`}
              name={`${name}-${opt.value}`}
              type="radio"
              className="form-radio h-4 w-4 text-primary-600 transition duration-150 ease-in-out"
              onChange={onChangeValue}
              value={opt.value}
              checked={opt.value === value}
              disabled={opt.disabled || disabled}
              data-cy={opt.text}
            />
            <label
              htmlFor={`${name}-${opt.value}`}
              className={[
                'ml-2 block text-sm leading-5',
                errors
                  ? 'text-red-600'
                  : 'text-gray-900 dark:text-darkGray-400',
              ].join(' ')}
            >
              {opt.text}
            </label>
          </div>
        ))}
      </div>
      <FieldErrors errors={errors} />
    </div>
  );
};
