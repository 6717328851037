export const enumToArrayOfOptions = (
  enumeration: {
    [key: string]: string;
  },
  keyFilter?: (s: string) => boolean,
): Array<{ text: string; value: string }> => {
  if (keyFilter) {
    return Object.keys(enumeration)
      .filter((key) => keyFilter(key))
      .map((key) => ({
        text: key,
        value: enumeration[key],
      }));
  }
  return Object.keys(enumeration).map((key) => ({
    text: key,
    value: enumeration[key],
  }));
};

export const enumToArrayOfOptionsAlternate = (
  enumeration: {
    [key: string]: string;
  },
  keyFilter?: (s: string) => boolean,
): Array<{ text: string; value: string }> => {
  if (keyFilter) {
    return Object.keys(enumeration)
      .filter((key) => keyFilter(key))
      .map((key) => ({
        text: enumeration[key],
        value: enumeration[key],
      }));
  }
  return Object.keys(enumeration).map((key) => ({
    text: enumeration[key],
    value: enumeration[key],
  }));
};

export const titleCaseEnumToArrayOfOptions = (enumeration: {
  [key: string]: string;
}): Array<{ text: string; value: string }> => {
  return Object.keys(enumeration).map((key) => {
    // Add spaces to TitleCase words
    const text = addSpacesToTitleCase(key);

    return {
      text,
      value: enumeration[key],
    };
  });
};

export const addSpacesToTitleCase = (str: string): string => {
  return str.replace(/([a-z0-9])([A-Z])|([A-Z]+)([A-Z][a-z])/g, '$1$3 $2$4');
};
