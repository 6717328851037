import { Any } from '@chiroup/core';
import { useCallback, useContext } from 'react';
import { MeContext } from '../contexts/me.context';

export enum LSType {
  user,
  clinic,
  both,
}

const getKey = (
  type: LSType,
  userId: string,
  clinicId?: number,
  key?: string,
) => {
  const userPart = userId;
  const clinicPart = `${clinicId}`;
  let parts = '';
  if (type === LSType.user) {
    parts = `${userPart}`;
  } else if (type === LSType.clinic) {
    parts = `${clinicPart}`;
  } else {
    parts = `${userPart}:${clinicPart}`;
  }
  return `${parts}:${key}`;
};

const useLocalStorage = () => {
  const meContext = useContext(MeContext);

  const getItem = useCallback(
    (lsType: LSType, key: string) => {
      if (!meContext?.me) {
        return;
      }
      const finalKey = getKey(
        lsType,
        meContext?.me.ID,
        meContext?.me.selectedClinic?.ID,
        key,
      );
      try {
        const item = localStorage.getItem(finalKey);
        return item
          ? typeof item === 'string' &&
            !item.startsWith('[') &&
            !item.startsWith('{')
            ? item
            : JSON.parse(item)
          : null;
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    [meContext.me],
  );

  const setItem = useCallback(
    (lsType: LSType, key: string, val: Any) => {
      if (!meContext?.me) {
        return;
      }
      val = typeof val === 'string' ? val : JSON.stringify(val);
      const finalKey = getKey(
        lsType,
        meContext?.me.ID,
        meContext?.me.selectedClinic?.ID,
        key,
      );
      try {
        localStorage.setItem(finalKey, val);
      } catch (err) {
        console.error(err);
      }
    },
    [meContext.me],
  );

  const removeItem = useCallback(
    (lsType: LSType, key: string) => {
      if (!meContext?.me) {
        return;
      }
      const finalKey = getKey(
        lsType,
        meContext?.me.ID,
        meContext?.me.selectedClinic?.ID,
        key,
      );
      try {
        localStorage.removeItem(finalKey);
      } catch (err) {
        console.error(err);
      }
    },
    [meContext.me],
  );

  return { setItem, getItem, removeItem };
};

export default useLocalStorage;
