import React from 'react';
import CodeEncounterDiagnoses from './CodeEncounterDiagnoses';
import { DndServiceDiagnosisType } from './CodeEncounterDiagnosisServices';
import {
  PatientTransactionItemDiagnosisType,
  PatientTransactionItemType,
} from '@chiroup/core';

type Props = {
  omitDiagnosisHeader?: boolean;
  listofDiagnoses?: DndServiceDiagnosisType[];
  listofServices?: PatientTransactionItemType[];
  isFetchingService?: boolean;
  addDiagnosisToAllServices?: (
    e: React.MouseEvent<HTMLDivElement>,
    diagnosis: PatientTransactionItemDiagnosisType,
  ) => void;
  isBillingStarted?: boolean;
  readonly?: boolean;
  locked?: boolean;
  importOnlyMode?: boolean;
};

const CodeEncounterDiagnosisServicesList: React.FC<Props> = ({
  omitDiagnosisHeader,
  listofDiagnoses,
  readonly,
  listofServices,
  isFetchingService,
  addDiagnosisToAllServices,
  isBillingStarted,
  locked,
  importOnlyMode = false,
}) => {
  if (importOnlyMode) return null;

  return (
    <div className="w-full">
      {!omitDiagnosisHeader && (
        <div className="mb-2.5 relative block text-lg text-primary-600 font-medium leading-5 sm:mt-px sm:pt-2 mt-4">
          Diagnoses
        </div>
      )}
      {listofDiagnoses && listofDiagnoses.length > 0 ? (
        <div className="rounded-md bg-white border border-gray-300 mb-3">
          <CodeEncounterDiagnoses
            diagnoses={listofDiagnoses}
            onAddAllClicked={addDiagnosisToAllServices}
            readonly={locked || readonly}
            isBillingStarted={isBillingStarted}
          />
        </div>
      ) : (
        <div className="text-sm text-gray-400 ml-2">
          <cite>
            No diagnoses have been specified.{' '}
            <ol className="ml-2">
              <li>1. Add one or more services.</li>
              <li>2. Add one or more diagnoses to a service.</li>
              <li>
                3. Drag a diagnosis to service <cite>or</cite> copy the
                diagnosis to all services.
              </li>
              <li>
                4. Drag a diagnosis to sort in priority order (primary,
                secondary,...).
              </li>
              <li>5. Drag services to sort.</li>
            </ol>
          </cite>
        </div>
      )}
    </div>
  );
};

export default CodeEncounterDiagnosisServicesList;
