import { Select } from '@chiroup/components';
import { FormError } from '@chiroup/core';
import React from 'react';
import { LANGUAGES } from '../../../../constants';

type Props = {
  onChange: (val: string) => void;
  value?: string;
  label?: string;
  showLabel?: boolean;
  className?: string;
  errors?: FormError;
  options?: { value: string; text: string }[];
};

const LanguageSelect: React.FC<Props> = ({
  onChange,
  value,
  label = 'Language',
  showLabel = true,
  className = 'flex-1 min-w-0',
  errors,
  options,
}) => {
  return (
    <Select
      name="language"
      label={label}
      showLabel={showLabel}
      className={className}
      options={options ? options : LANGUAGES}
      onChange={onChange}
      value={value}
      limit={1}
      errors={errors}
    />
  );
};

export default LanguageSelect;
