import { Loading } from '@chiroup/components';

type Props = {
  title?: string;
};

export const LoadingPage = ({ title }: Props) => {
  return (
    <div className="flex flex-col items-center justify-center py-8">
      {title && (
        <div>
          <h3 className="text-xl leading-6 font-medium text-gray-500 dark:text-darkGray-100">
            {title}
          </h3>
        </div>
      )}
      <Loading color="text-gray-400" size={10} className="-ml-1 mr-3" />
    </div>
  );
};
