import { Checkbox } from '@chiroup/components';
import { formatPhone } from '@chiroup/core';
import React, { useContext, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Link } from 'react-router-dom';
import Button, { ButtonColors } from '../../common/Button';
import Modal from '../../common/Modal';
import { MeContext } from '../../../contexts/me.context';

type Props = {
  isOpen: boolean;
  close: () => void;
  existing?: any[];
  createAnyway: () => void;
  closeSearch?: () => void;
  mergePatients?: (ids: string[]) => Promise<void>;
  existsIsBusy: boolean;
};

const PatientExistsModal: React.FC<Props> = ({
  isOpen,
  close,
  existing,
  createAnyway,
  closeSearch,
  mergePatients,
  existsIsBusy,
}) => {
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});
  const { hasAccess } = useContext(MeContext);
  const closeModals = () => {
    setSelected({});
    close();
    closeSearch?.();
  };

  const toggleSelected = (id: string) => {
    setSelected((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const patientsToMerge = useMemo(() => {
    return Object.keys(selected).filter((id) => selected[id]);
  }, [selected]);

  const onClickMerge = () => {
    mergePatients?.(patientsToMerge);
  };

  return (
    <Modal
      isOpen={isOpen}
      close={closeModals}
      disableClickOutsideClose
      className="inline-block align-bottom bg-white dark:bg-darkGray-900 rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
    >
      <div className="grid">
        <div className="mx-auto items-center justify-center ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-primary-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div className="mt-3 text-center">
          <h3
            className="text-lg font-semibold pb-4 text-gray-900 dark:text-darkGray-100"
            id="modal-headline"
          >
            This patient may already exist.
          </h3>
          {!hasAccess('ehrNotes') && (
            <h2 className="text-md text-gray-900 dark:text-darkGray-100 mb-5">
              If you would like to merge existing patients into this new record,
              please select those patients and click the 'Create & merge'
              button.
            </h2>
          )}
        </div>
        <Scrollbars className="h-64 w-full -px-4">
          <div role="list" className="divide-y divide-gray-300 px-4">
            <ul>
              {existing?.map((patient, index) => (
                <div key={index}>
                  <li className="py-4 flex items-center gap-4">
                    {!hasAccess('ehrNotes') && (
                      <Checkbox
                        name="mergePatientCheckbox"
                        className="m-0"
                        value={selected[patient.ID] || false}
                        onChange={toggleSelected.bind(null, patient.ID)}
                      />
                    )}
                    <div className="text-sm text-gray-500 dark:text-darkGray-400">
                      <p className="font-medium text-gray-900 dark:text-darkGray-100">
                        {patient.lname}, {patient.fname}
                        <span className="font-light text-xs">
                          {patient.dateOfBirth
                            ? ` (${patient.dateOfBirth})`
                            : ''}
                        </span>
                      </p>
                      <p>{patient.birthday}</p>
                      <p>{patient.email}</p>
                      <p>{formatPhone(patient.phone)}</p>
                    </div>
                    <div className="ml-auto">
                      <Link to={`/patients/${patient.ID}`} target="_blank">
                        <Button
                          text="Go to patient"
                          className="w-32 h-10"
                          color={ButtonColors.plainWithBorder}
                        />
                      </Link>
                    </div>
                  </li>
                </div>
              ))}
            </ul>
          </div>
        </Scrollbars>
        <div className={'mt-6 grid gap-3 grid-flow-col-dense'}>
          <Button
            text="Cancel"
            onClick={closeModals}
            fullWidth
            color={ButtonColors.plain}
            className={'border border-gray-300 dark:border-darkGray-600'}
            disabled={existsIsBusy}
          />
          {patientsToMerge?.length ? (
            <Button
              text="Create & merge"
              onClick={onClickMerge}
              fullWidth
              color={ButtonColors.primary}
              disabled={existsIsBusy}
              loading={existsIsBusy}
            />
          ) : (
            <Button
              text="Create anyway"
              onClick={createAnyway}
              disabled={existsIsBusy}
              loading={existsIsBusy}
              fullWidth
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PatientExistsModal;
