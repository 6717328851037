import { AppointmentForUI, DisciplineTreatment } from '@chiroup/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';
import AppointmentView from './AppointmentView';

type Props = {
  appointment: AppointmentForUI;
  userId: string;
  treatment: DisciplineTreatment;
};

const ScheduleEventSlot: React.FC<Props> = ({
  userId,
  appointment,
  treatment,
}) => {
  const { addQueryParams } = useQueryParams();

  return (
    <Link
      to={
        addQueryParams({
          open: 'add',
          treatment: treatment.ID,
          clinician: userId,
          startTime: appointment.startTime.valueOf(),
          duration: appointment.duration,
        }).link
      }
    >
      <div
        className="absolute"
        style={{
          top: appointment.top,
          left: `${appointment.left}%`,
          height: appointment.height || 15,
          width: `${appointment.width || 100}%`,
        }}
      >
        <AppointmentView
          patientName={treatment.name}
          start={appointment.startTime}
          width={`${appointment.width || 100}%`}
          duration={appointment.duration}
          color={treatment.color}
          isPending
          appointment={appointment}
        />
      </div>
    </Link>
  );
};

export default ScheduleEventSlot;
