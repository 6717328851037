import { Discipline, Insurance, classNames } from '@chiroup/core';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

type Props = {
  value: Partial<Insurance>;
  patchValue: (values: Partial<Insurance>) => void;
  disciplines?: Discipline[];
};

const PatientDisciplineSelectButton: React.FC<Props> = ({
  value,
  patchValue,
  disciplines,
}) => {
  const isButtonDisabled =
    !disciplines?.length ||
    disciplines?.length === value?.disciplineBenefits?.length;

  const buttonClass = classNames(
    'flex flex-row gap-2 items-center p-2 rounded-md text-sm font-medium focus:outline-none',
    isButtonDisabled
      ? 'cursor-not-allowed bg-gray-300 text-gray-500'
      : 'cursor-pointer hover:bg-primary-400 bg-primary-500 text-white',
  );

  return (
    <div className="flex flex-col gap-1">
      <Menu as="div" className="relative inline-block text-left">
        <div className="flex flex-row gap-2">
          <Menu.Button disabled={isButtonDisabled} className={buttonClass}>
            {'Add Benefit'}
            <ChevronDownIcon className="w-3 h-3" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-50 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {disciplines
                ?.filter(
                  (discipline) =>
                    discipline.active &&
                    !value?.disciplineBenefits?.some(
                      (d) => d.disciplineId === discipline.ID,
                    ),
                )
                ?.map((discipline) => (
                  <Menu.Item key={discipline.ID}>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm cursor-pointer',
                        )}
                        onClick={() => {
                          patchValue({
                            disciplineBenefits: [
                              ...(value?.disciplineBenefits || []),
                              { disciplineId: discipline.ID },
                            ],
                          });
                        }}
                      >
                        {discipline.name}
                      </div>
                    )}
                  </Menu.Item>
                ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default PatientDisciplineSelectButton;
