export type TailwindColor =
  | 'primary'
  | 'gray'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'lime'
  | 'emerald'
  | 'teal'
  | 'cyan'
  | 'sky'
  | 'blue'
  | 'indigo'
  | 'violet'
  | 'purple'
  | 'pink'
  | 'rose';

export const tailwindColors: {
  value: TailwindColor;
  sampleColor: string;
}[] = [
  {
    value: 'primary',
    sampleColor: 'bg-primary-500',
  },
  { value: 'gray', sampleColor: 'bg-gray-500' },
  { value: 'red', sampleColor: 'bg-red-500' },
  {
    value: 'orange',
    sampleColor: 'bg-orange-500',
  },
  {
    value: 'yellow',
    sampleColor: 'bg-yellow-500',
  },
  { value: 'lime', sampleColor: 'bg-lime-500' },
  {
    value: 'emerald',
    sampleColor: 'bg-emerald-500',
  },
  { value: 'teal', sampleColor: 'bg-teal-500' },
  { value: 'cyan', sampleColor: 'bg-cyan-500' },
  { value: 'sky', sampleColor: 'bg-sky-500' },
  { value: 'blue', sampleColor: 'bg-blue-500' },
  {
    value: 'indigo',
    sampleColor: 'bg-indigo-500',
  },
  {
    value: 'violet',
    sampleColor: 'bg-violet-500',
  },
  {
    value: 'purple',
    sampleColor: 'bg-purple-500',
  },
  { value: 'pink', sampleColor: 'bg-pink-500' },
  { value: 'rose', sampleColor: 'bg-rose-500' },
];

export const tailwindColorsHash = {
  primary: '#93cc68',
  gray: '#d1d5db',
  red: '#fca5a5',
  orange: '#fdba74',
  yellow: '#fde047',
  lime: '#bef264',
  emerald: '#6ee7b7',
  teal: '#5eead4',
  cyan: '#67e8f9',
  sky: '#7dd3fc',
  blue: '#93c5fd',
  indigo: '#a5b4fc',
  violet: '#c4b5fd',
  purple: '#d8b4fe',
  pink: '#f9a8d4',
  rose: '#fda4af',
};
