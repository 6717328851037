import {
  GLOBAL_COUNTRY_OPTIONS,
  GLOBAL_MORE_LANGUAGE_OPTIONS,
  GLOBAL_PREFERS_OPTIONS,
  GLOBAL_PREFIX_OPTIONS,
  GLOBAL_PRONOUN_OPTIONS,
  GLOBAL_RACE_OPTIONS,
  GLOBAL_SUFFIX_OPTIONS,
  Region,
} from '@chiroup/core';
export const LIST_LIMIT = 30;

export enum Regions {
  'C' = 'C',
  'S' = 'S',
  'E' = 'E',
  'H/W' = 'H/W',
  'T' = 'T',
  'L' = 'L',
  'H' = 'H',
  'K' = 'K',
  'F/A' = 'F/A',
  'O' = 'O',
  'HEAD' = 'HEAD',
  'F/B' = 'F/B',
}

export enum JointRegions {
  SPINE = 'SPINE',
  RIBS = 'RIBS',
  'S' = 'S',
  'E' = 'E',
  'H/W' = 'H/W',
  'H' = 'H',
  'K' = 'K',
  'F/A' = 'F/A',
  'O' = 'O',
  'HEAD' = 'HEAD',
  'F/B' = 'F/B',
}

export const JOINT_REGION_TO_REGION: {
  [key in Region]: JointRegions;
} = {
  [Regions.C]: JointRegions.SPINE,
  [Regions.S]: JointRegions.S,
  [Regions.E]: JointRegions.E,
  [Regions.HEAD]: JointRegions.HEAD,
  [Regions['H/W']]: JointRegions['H/W'],
  [Regions.T]: JointRegions.SPINE,
  [Regions.L]: JointRegions.SPINE,
  [Regions.H]: JointRegions.H,
  [Regions.K]: JointRegions.K,
  [Regions['F/A']]: JointRegions['F/A'],
  [Regions.O]: JointRegions.O,
  [Regions['F/B']]: JointRegions['F/B'],
};

export const INSURANCE_TYPE_CODES = [
  {
    value: '12',
    text: 'Medicare Secondary, Working Aged Beneficiary or Spouse with Employer Group Health Plan',
  },
  {
    value: '13',
    text: 'Medicare Secondary, End-Stage Renal Disease Beneficiary in the Mandated Coordination Period with an Employer’s Group Health Plan',
  },
  {
    value: '14',
    text: 'Medicare Secondary, No-fault insurance is Primary',
  },
  { value: '15', text: 'Medicare Secondary, Workers Comp' },
  {
    value: '16',
    text: 'Medicare Secondary, Public Health Service or Other Public Agency',
  },
  { value: '41', text: 'Medicare Secondary, Black Lung' },
  { value: '42', text: 'Medicare Secondary, Veterans Administration' },
  {
    value: '43',
    text: 'Medicare Secondary, Disabled Beneficiary Under Age 65 with Large Group Health Plan',
  },
  {
    value: '47',
    text: 'Medicare Secondary, Other Liability Insurance is Primary',
  },
];
export const DEGREES = [
  { value: '', text: '' },
  { value: 'ARNP', text: 'ARNP' },
  { value: 'APRN', text: 'APRN' },
  { value: 'NP', text: 'NP' },
  { value: 'DC', text: 'DC' },
  { value: 'DDS', text: 'DDS' },
  { value: 'DMD', text: 'DMD' },
  { value: 'DNP', text: 'DNP' },
  { value: 'DO', text: 'DO' },
  { value: 'DPM', text: 'DPM' },
  { value: 'MD', text: 'MD' },
  { value: 'ND', text: 'ND' },
  { value: 'PA', text: 'PA' },
  { value: 'PT', text: 'PT' },
  { value: 'Other', text: 'Other' },
];

export const MEDICAL_SPECIALTIES = [
  { value: '', text: '' },
  { value: 'Chiropractic', text: 'Chiropractic' },
  { value: 'Family/Internist', text: 'Family/Internist' },
  { value: 'Neurology', text: 'Neurology' },
  { value: 'Neurosurgery', text: 'Neurosurgery' },
  { value: 'Ob/Gyn', text: 'Ob/Gyn' },
  { value: 'Orthopedic Surgery', text: 'Orthopedic Surgery' },
  { value: 'Pain Management', text: 'Pain Management' },
  { value: 'Pediatrics', text: 'Pediatrics' },
  { value: 'Physiatry', text: 'Physiatry' },
  { value: 'Rheumatology', text: 'Rheumatology' },
  { value: 'Sports Medicine', text: 'Sports Medicine' },
  { value: 'Other', text: 'Other' },
];

export const REGION_OPTIONS: { value: Regions; text: string }[] = [
  { value: Regions.C, text: 'Cervical' },
  { value: Regions.S, text: 'Shoulder' },
  { value: Regions.E, text: 'Elbow' },
  { value: Regions['H/W'], text: 'Hand and Wrist' },
  { value: Regions.T, text: 'Thoracic' },
  { value: Regions.L, text: 'Lumbosacral' },
  { value: Regions.H, text: 'Hip' },
  { value: Regions.K, text: 'Knee' },
  { value: Regions['F/A'], text: 'Foot and Ankle' },
  { value: Regions.HEAD, text: 'Head' },
  { value: Regions['F/B'], text: 'Full Body' },
  { value: Regions.O, text: 'Other' },
];
export const ROM_REGION_OPTIONS: { [key in string]: boolean } = {
  [Regions.C]: true,
  [Regions.S]: true,

  [Regions.E]: true,
  [Regions['H/W']]: true,
  [Regions.T]: true,
  [Regions.L]: true,
  [Regions.H]: true,
  [Regions.K]: true,
  [Regions['F/A']]: true,
};

export const REGIONS_HASH: { [key in Regions]: string } = {
  [Regions.C]: 'Cervical',
  [Regions.S]: 'Shoulder',
  [Regions.E]: 'Elbow',
  [Regions['H/W']]: 'Hand and Wrist',
  [Regions.T]: 'Thoracic',
  [Regions.L]: 'Lumbosacral',
  [Regions.H]: 'Hip',
  [Regions.HEAD]: 'Head',
  [Regions.K]: 'Knee',
  [Regions['F/A']]: 'Foot and Ankle',
  [Regions.O]: 'Other',
  [Regions['F/B']]: 'Full Body',
};

export const LANGUAGES = [
  { value: 'en', text: 'English' },
  { value: 'es', text: 'Spanish' },
];

export const MORE_LANGUAGES = GLOBAL_MORE_LANGUAGE_OPTIONS;

export const SESSION_UNITS = [
  { value: '', text: '' },
  { value: 'hour', text: 'hour' },
  { value: 'day', text: 'day' },
  { value: 'week', text: 'week' },
  { value: 'month', text: 'month' },
  { value: 'as needed', text: 'PRN' },
];

export const TREATMENT_CATEGORIES = [
  { value: 'Manipulation', text: 'Manipulation' },
  { value: 'Mobilization', text: 'Mobilization' },
  {
    value: 'DirectionalPreferenceTherapy',
    text: 'Directional preference therapy',
  },
  { value: 'STM', text: 'STM' },
  { value: 'IASTM', text: 'IASTM' },
  { value: 'Neurodynamics', text: 'Neurodynamics' },
  { value: 'Taping', text: 'Taping' },
  { value: 'Other', text: 'Other' },
];

export const EXERCISE_TYPES = [
  { value: 'PROM', text: 'PROM' },
  { value: 'AROM', text: 'AROM' },
  { value: 'Stretch', text: 'Stretch' },
  { value: 'C/R Stretch', text: 'C/R Stretch' },
  { value: 'Stability', text: 'Stability' },
  { value: 'Ball/Band', text: 'Ball/Band' },
  { value: 'Weights', text: 'Weights' },
  { value: 'Functiona', text: 'Functional' },
  { value: 'Directional therapy', text: 'Directional therapy' },
  { value: 'Dynamic stabilization', text: 'Dynamic stabilization' },
];

export const treatmentTypes = {
  Common: 'common-treatments',
  Clinical: 'treatments',
};

export const PREFERS = GLOBAL_PREFERS_OPTIONS;

export const RACE = GLOBAL_RACE_OPTIONS;

export const PREFIX = GLOBAL_PREFIX_OPTIONS;
export const SUFFIX = GLOBAL_SUFFIX_OPTIONS;

export const TITLES = [
  { value: '', text: 'None' },
  { value: 'Dr', text: 'Dr' },
  { value: 'Mr', text: 'Mr' },
  { value: 'Ms', text: 'Ms' },
  { value: 'Mrs', text: 'Mrs' },
];

export const PRONOUNS = GLOBAL_PRONOUN_OPTIONS;

export const COUNTRIES = GLOBAL_COUNTRY_OPTIONS;

export const THEMES = [
  {
    text: 'Default',
    value: 'default-theme',
    primary: '#13547a',
    accent: '#78cac1',
    primaryText: '#ffffff',
    accentText: '#58585A',
  },
  {
    text: 'Green beach',
    value: 'green-beach-theme',
    primary: '#02aab0',
    accent: '#00cdac',
    primaryText: '#ffffff',
    accentText: '#ffffff',
  },
  {
    text: 'Blue',
    value: 'blue-theme',
    primary: '#314755',
    accent: '#26a0da',
    primaryText: '#ffffff',
    accentText: '#58585A',
  },
  {
    text: 'Green',
    value: 'original-green-theme',
    primary: '#76be40',
    accent: '#d2ff9c',
    primaryText: '#ffffff',
    accentText: '#58585A',
  },
  {
    text: 'Orange',
    value: 'orange-theme',
    primary: '#e65c00',
    accent: '#f9d423',
    primaryText: '#ffffff',
    accentText: '#58585A',
  },
  {
    text: 'Purple',
    value: 'purple-theme',
    primary: '#cc2b5e',
    accent: '#753a88',
    primaryText: '#ffffff',
    accentText: '#ffffff',
  },
  {
    text: 'Ultraviolet',
    value: 'ultra-violet-theme',
    primary: '#654ea3',
    accent: '#eaafc8',
    primaryText: '#ffffff',
    accentText: '#58585A',
  },
  {
    text: 'Blue/Orange',
    value: 'blue-orange-theme',
    primary: '#544a7d',
    accent: '#ffd452',
    primaryText: '#ffffff',
    accentText: '#58585A',
  },
  {
    text: 'Red',
    value: 'red-theme',
    primary: '#ed213a',
    accent: '#93291e',
    primaryText: '#ffffff',
    accentText: '#ffffff',
  },
  {
    text: 'Grey',
    value: 'grey-theme',
    primary: '#9a9c9e',
    accent: '#2c3e50',
    primaryText: '#ffffff',
    accentText: '#ffffff',
  },
];

export const PLAN_HASH: { [key: string]: string } = {
  premium: 'Premium',
  basic: 'Basic',
  plus: 'Plus',
};
