import { Input, Select } from '@chiroup/components';
import {
  FormFieldErrors,
  LEGAL_GUARDIAN_RELATIONSHIP_OPTIONS,
  Patient,
  ValueOf,
} from '@chiroup/core';

type Props = {
  fieldErrors?: FormFieldErrors;
  onChange: (key: keyof Partial<Patient>) => (val: ValueOf<Patient>) => void;
  values: Partial<Patient>;
  update?: boolean;
};

export const LegalGuardianFields: React.FC<Props> = ({
  fieldErrors,
  onChange,
  values,
  update,
}) => {
  return (
    <>
      <Input
        className={update ? 'col-span-6' : 'col-span-6'}
        name="legalGuardianName"
        label="Legal guardian name *"
        value={values.legalGuardianName}
        onChange={onChange('legalGuardianName')}
        errors={fieldErrors?.legalGuardianName}
      />
      <Select
        className={update ? 'col-span-6' : 'col-span-6'}
        name="legalGuardianRelationship"
        label="Legal guardian relationship *"
        value={values.legalGuardianRelationship}
        onChange={onChange('legalGuardianRelationship')}
        errors={fieldErrors?.legalGuardianRelationship}
        options={LEGAL_GUARDIAN_RELATIONSHIP_OPTIONS}
        limit={1}
      />
    </>
  );
};
export default LegalGuardianFields;
