import { titleCase } from '@chiroup/core';
import dayjs from 'dayjs';
import { MarketingPlan } from './helpers';

type Props = {
  pending?: boolean;
  showUntil?: Date | null;
  contentToShow?: string | null;
  currentPlan?: string;
};
const ExpirationMessage: React.FC<Props> = ({
  showUntil,
  contentToShow,
  currentPlan,
}) => (
  <span className="font-normal">
    {`Your ${titleCase(contentToShow || '')} plan will expire on ${dayjs(
      showUntil,
    ).format('MMMM D, YYYY')}.`}{' '}
    {currentPlan === MarketingPlan.Plus &&
      contentToShow === MarketingPlan.Premium && (
        <span className={'font-normal'}>
          You will still be able to access your Plus materials from this tab.
        </span>
      )}
  </span>
);

export default ExpirationMessage;
