import { ChiroUpBaseCommon } from '@chiroup/core';
import classNames from 'classnames';

type Props = {
  value: any;
  currency?: string;
  credit?: boolean;
  debit?: boolean;
  className?: string;
  prefix?: string;
  omitWhenFalse?: boolean;
  omitWhenZero?: boolean;
  negativeClassName?: string;
};

export const Currency: React.FC<Props> = ({
  value = 0,
  currency = 'USD',
  credit = true,
  debit = false,
  className = '',
  prefix,
  omitWhenFalse = false,
  omitWhenZero = false,
  negativeClassName = '',
}) => {
  if (omitWhenFalse && (value === undefined || value === null)) return null;

  const useValue = value || 0;
  // const negative = (debit && useValue > 0) || (credit && useValue < 0);
  if (omitWhenZero && useValue === 0) return null;
  if (omitWhenZero && useValue === '0.00') return null;

  return (
    <div
      className={classNames(className, useValue < 0 ? negativeClassName : '')}
    >
      {!!prefix && <span> {prefix + ' '}</span>}
      {ChiroUpBaseCommon.format.asMoney(useValue, currency)}
    </div>
  );
};
