import * as Sentry from '@sentry/react';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import App from './App';
import './styles/index.css';
import { fetchAuthSession } from '@aws-amplify/core';

window.React = React;

const VITE_CHIROUP_API = import.meta.env.VITE_CHIROUP_API as string;

if (import.meta.env.VITE_STAGE !== 'local') {
  Sentry.init({
    release: import.meta.env.VITE_VERSION,
    // debug: true,
    dsn: 'https://4e31ca6b060ff97a5d318de5b6ad29a8@o4506354335612928.ingest.sentry.io/4506354340265984',
    // This is how we add the user dialog for feedback
    // beforeSend(event, hint) {
    //   if (event.exception) {
    //     Sentry.showReportDialog({ eventId: event.event_id });
    //   }
    //   return event;
    // },
    tracePropagationTargets: [
      VITE_CHIROUP_API,
      // Adding V2 will break the app without fixing the issue with header baggage not being supported.
      // VITE_V2_API
    ],
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
      // new RewriteFramesIntegration(),
    ],
    environment: import.meta.env.VITE_STAGE,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /fast\.trychameleon\.com/i,
    ],
  });
}

const authConfig: ResourcesConfig['Auth'] = {
  Cognito: {
    allowGuestAccess: false,
    userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID as string,
    userPoolClientId: import.meta.env.VITE_COGNITO_APP_CLIENT_ID as string,
    identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID as string,
  },
};

Amplify.configure(
  {
    Auth: authConfig,
    API: {
      REST: {
        api: {
          endpoint: import.meta.env.VITE_CHIROUP_API as string,
        },
        v2: {
          endpoint: import.meta.env.VITE_V2_API as string,
        },
        'api-noauth': {
          endpoint: import.meta.env.VITE_CHIROUP_API as string,
        },
        'v2-noauth': {
          endpoint: import.meta.env.VITE_V2_API as string,
        },
      },
    },
  },
  {
    API: {
      REST: {
        headers: async () => {
          const authToken = (
            await fetchAuthSession()
          ).tokens?.idToken?.toString();
          return {
            Authorization: `Bearer ${authToken}`,
          };
        },
      },
    },
  },
);

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
  // <StrictMode>
  // <Authenticator.Provider>
  <App />,
  // </Authenticator.Provider>
  // </StrictMode>
);
