import { CommunicationMethodTypes } from '../enums';

export type DefaultSettings =
  | 'notificationAppointmentScheduled'
  | 'notificationAppointmentRecheduled'
  | 'notificationAppointmentCanceled'
  | 'notificationAppointmentReminder'
  | 'clinicCommunicationsDefault';

export const defaultSettings: {
  [key in DefaultSettings]: boolean | CommunicationMethodTypes;
} = {
  notificationAppointmentScheduled: true,
  notificationAppointmentRecheduled: true,
  notificationAppointmentCanceled: true,
  notificationAppointmentReminder: true,
  clinicCommunicationsDefault: CommunicationMethodTypes.text,
};
