import React, { useEffect, useState } from 'react';
import { Portal } from './Portal';

type Props = {
  isWaiting?: boolean;
  isNotWaiting?: boolean;
  children?: React.ReactNode;
};

/**
 * [2023-09-06.1115 by Brian]
 * @param param0
 * @returns
 */
export const MakeBrowserWait: React.FC<Props> = ({
  isWaiting = false,
  isNotWaiting = true,
  children = null,
}) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isWaiting === true) {
      interval = setInterval(() => {
        setDots((p) => {
          return p + ' .';
        });
      }, 750);
    } else {
      if (interval !== null) {
        clearInterval(interval);
      }
      setDots('');
    }

    return () => {
      if (interval !== null) {
        clearInterval(interval);
      }
    };
  }, [isWaiting]);

  if (isNotWaiting === true && isWaiting === false) return null;

  if (!children) {
    children = (
      <div className="absolute bottom-2 left-4 text-3xl text-white">{dots}</div>
    );
  }

  return (
    <Portal>
      <div
        className="cursor-wait"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.125)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
        }}
      >
        {children}
      </div>
    </Portal>
  );
};
