import React from 'react';
import { Link } from 'react-router-dom';

export type ButtonGroupType = {
  label: string;
  onClick?: () => void;
  to?: string;
};

type Props = {
  buttons: ButtonGroupType[];
  disabled?: boolean;
  transparent?: boolean;
  isEmptyOkay?: boolean;
};

const getButtonClassNames = (
  index: number,
  length: number,
  disabled: boolean,
  transparent: boolean,
) => {
  const baseClasses =
    'isolate relative -ml-px inline-flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-700 dark:text-darkGray-400';
  const disabledClasses = disabled
    ? 'text-gray-500 dark:text-gray-300 cursor-not-allowed'
    : 'cursor-pointer hover:text-gray-500 dark:hover:text-darkGray-300';
  const transparentClasses = transparent
    ? ''
    : `bg-white dark:bg-darkGray-700 hover:bg-gray-50 dark:hover:bg-darkGray-600 ring-1 ring-inset ring-gray-300`;

  let roundedClass = '';
  if (length === 1) {
    roundedClass = 'rounded-md';
  } else if (index === 0) {
    roundedClass = 'rounded-l-md';
  } else if (index === length - 1) {
    roundedClass = 'rounded-r-md';
  }

  return `${baseClasses} ${disabledClasses} ${transparentClasses} ${roundedClass}`;
};

const ButtonGroup: React.FC<Props> = React.memo(
  ({ buttons, disabled = false, transparent = false, isEmptyOkay = false }) => {
    if (!buttons?.length) {
      if (!isEmptyOkay) {
        console.error('ButtonGroup requires an array of at least 1 button');
      }
      return null;
    }

    return (
      <span>
        {buttons.map((button, index) =>
          button.to ? (
            <Link to={button.to} key={`btn-grp-lnk-key-${index}`}>
              <button
                key={`${button.label}-${index}`}
                type="button"
                className={getButtonClassNames(
                  index,
                  buttons.length,
                  disabled,
                  transparent,
                )}
                onClick={button.onClick}
                disabled={disabled}
              >
                {button.label}
              </button>
            </Link>
          ) : (
            <button
              key={`${button.label}-${index}`}
              type="button"
              className={getButtonClassNames(
                index,
                buttons.length,
                disabled,
                transparent,
              )}
              onClick={button.onClick}
              disabled={disabled}
            >
              {button.label}
            </button>
          ),
        )}
      </span>
    );
  },
);

export default ButtonGroup;
