import React from 'react';
import Breadcrumbs, { Breadcrumb } from './Breadcrumbs';

type Props = {
  breadcrumbs?: Breadcrumb[];
  title?: string | React.ReactNode;
  rightSide?: React.ReactNode;
  afterTitle?: React.ReactNode;
  className?: string;
  sticky?: boolean;
  children?: React.ReactNode;
  killClassName?: string;
  addClassName?: string;
};

const Header: React.FunctionComponent<Props> = ({
  title = '',
  breadcrumbs,
  rightSide,
  children,
  afterTitle,
  className = '',
  sticky,
  killClassName,
  addClassName = '',
}) => {
  // const navigate = useNavigate();

  // const back = () => {
  //   navigate(-1);
  // };

  const killClasses = (killClassName || '')
    .split(' ')
    .reduce((a: { [key: string]: boolean }, c) => {
      a[c] = true;
      return a as { [key: string]: boolean };
    }, {});

  const rawClassNames =
      'px-3 pt-2 sm:pt-auto py-1 border-b border-gray-300 dark:border-darkGray-800 bg-white dark:bg-darkGray-900 min-h-12',
    useThesClasses = (rawClassNames.split(' ') as string[]).filter(
      (s) => !killClasses[s],
    );

  return (
    <div
      className={[
        addClassName,
        useThesClasses.join(' '),
        className,
        sticky && 'sticky top-0 z-10',
      ].join(' ')}
    >
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-0">
        {/* <nav className="sm:hidden">
          <div
            onClick={back}
            className="flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out cursor-pointer print:hidden"
          >
            <svg
              className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            Back
          </div>
        </nav> */}
        <div>
          {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <h1 className="flex-1 text-xl leading-6 font-medium">{title}</h1>
          {afterTitle && (
            <div className="mb-1 text-md font-light text-gray-500 dark:text-gray-400">
              {afterTitle}
            </div>
          )}
        </div>
        <div className="flex space-x-3">{rightSide}</div>
      </div>
      {children}
    </div>
  );
};

export default Header;

// import React from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import Breadcrumbs, { Breadcrumb } from './Breadcrumbs';

// type Props = {
//   breadcrumbs?: Breadcrumb[];
//   title?: string | React.ReactNode;
//   rightSide?: React.ReactNode;
//   afterTitle?: React.ReactNode;
// };

// const Header: React.FunctionComponent<Props> = ({
//   title = '',
//   breadcrumbs,
//   rightSide,
//   children,
//   afterTitle,
// }) => {
//   const navigate = useNavigate();

//   const back = () => {
//     navigate(-1);
//   };

//   return (
//     <div
//       className="pl-4 pr-6 pt-5 pb-4 border-b border-gray-300 dark:border-darkGray-800 sm:pl-6 bg-white dark:bg-darkGray-900 items-center flex"
//       style={{ minHeight: 75 }}
//     >
//       <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between w-full">
//         <div>
//           <nav className="sm:hidden">
//             <div
//               onClick={back}
//               className="flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out cursor-pointer"
//             >
//               <svg
//                 className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
//                 viewBox="0 0 20 20"
//                 fill="currentColor"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
//                   clipRule="evenodd"
//                 />
//               </svg>
//               Back
//             </div>
//           </nav>
//           {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
//           <h1 className="flex-1 text-xl leading-6 font-medium">{title}</h1>
//           {afterTitle && <div className="mb-1">{afterTitle}</div>}
//         </div>
//         <div className="flex space-x-3">{rightSide}</div>
//       </div>
//       {children}
//     </div>
//   );
// };

// export default Header;
