import { FormError } from '@chiroup/core';
import React, { ChangeEvent, forwardRef } from 'react';
import { ErrorIcon } from './ErrorIcon';
import { FieldErrors } from './FieldErrors';
import { TrivialTooltip } from '../TrivialTooltip';
import { NumericFormat, PatternFormat } from 'react-number-format';
import classNames from 'classnames';

type InputRef = {
  focus: () => void;
  blur: () => void;
  // Add any other methods or properties you want to expose
};

type Props = {
  name: string;
  label?: string;
  errors?: FormError;
  onChange: (val: any) => void;
  value?: string | number | null;
  className?: string;
  placeholder?: string;
  onBlur?: (val: string) => void;
  hint?: string | React.ReactNode;
  hintOnClick?: () => void;
  disabled?: boolean;
  tooltip?: string;
  tooltipClassName?: string;
  tooltipId?: string;
  inputTitle?: string;
  patternFormat?: string;
  numericOptions?: any;
  inline?: boolean;
  inlineLabelClassName?: string;
  inlineInputClassName?: string;
  autoFocus?: boolean;
};

export const InputMasked = forwardRef<InputRef, Props>(
  (
    {
      label,
      name,
      onChange,
      value,
      errors,
      className = '',
      placeholder,
      onBlur,
      hint,
      hintOnClick,
      disabled = false,
      tooltip,
      tooltipId,
      tooltipClassName,
      inputTitle,
      patternFormat,
      numericOptions = {},
      inline = false,
      inlineLabelClassName,
      inlineInputClassName,
      autoFocus = false,
    }: Props,
    ref,
  ) => {
    const onChangeCleave = (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      onChange(val);
    };

    const onBlurCleave = (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      onBlur?.(val);
    };

    return (
      <div className={classNames(className)}>
        {label && (
          <div
            className={
              inline
                ? 'align-top pr-4 pt-0 inline-block border border-green-500'
                : ''
            }
          >
            <label
              htmlFor={name}
              className={classNames(
                inline
                  ? 'inline-block  border border-green-500'
                  : 'block sm:mt-px sm:pt-2',
                inlineLabelClassName ? inlineLabelClassName : '',
                'text-sm font-medium leading-5 text-gray-900 dark:text-darkGray-200',
              )}
            >
              {label}
              {tooltip && (
                <TrivialTooltip
                  text={tooltip}
                  type="info"
                  id={tooltipId}
                  tipClassName={tooltipClassName}
                />
              )}
            </label>
          </div>
        )}
        <div
          className={classNames(
            inline ? 'inline-block' : '',
            inline && inlineInputClassName,
          )}
        >
          <div className="relative rounded-md shadow-sm">
            {patternFormat ? (
              <PatternFormat
                format={patternFormat}
                className={[
                  'block w-full border-gray-300 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200 focus:ring-2',
                  errors
                    ? 'border-red-500 text-red-900 placeholder-red-500 focus:border-red-500 focus:ring-red-500 outline-none'
                    : 'focus:border-primary-500 focus:ring-primary-500 outline-none',
                  disabled
                    ? 'text-gray-500 dark:text-darkGray-300 bg-gray-300 dark:bg-darkGray-500 cursor-not-allowed'
                    : '',
                ].join(' ')}
                onChange={onChangeCleave}
                onBlur={onBlurCleave}
                value={value as string}
                placeholder={placeholder}
                disabled={disabled}
                title={inputTitle}
                autoFocus={autoFocus}
              />
            ) : (
              <NumericFormat
                // options={options}
                className={[
                  'block w-full border-gray-300 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200 focus:ring-2',
                  errors
                    ? 'border-red-500 text-red-900 placeholder-red-500 focus:border-red-500 focus:ring-red-500 outline-none'
                    : 'focus:border-primary-500 focus:ring-primary-500 outline-none',
                  disabled
                    ? 'text-gray-500 dark:text-darkGray-300 bg-gray-300 dark:bg-darkGray-500 cursor-not-allowed'
                    : '',
                ].join(' ')}
                onChange={onChangeCleave}
                onBlur={onBlurCleave}
                value={value as string}
                placeholder={placeholder}
                disabled={disabled}
                title={inputTitle}
                autoFocus={autoFocus}
                {...numericOptions}
              />
            )}
            {errors && <ErrorIcon />}
          </div>
          {inline ? null : <FieldErrors errors={errors} />}
          {!!hint && (
            <p
              className={[
                'mt-2 text-sm',
                hintOnClick
                  ? 'text-primary-600 font-semibold hover:text-primary-500 cursor-pointer'
                  : 'text-gray-500',
              ].join(' ')}
              id={`${name}-hint`}
              onClick={hintOnClick}
            >
              {hint}
            </p>
          )}
          {inline ? <FieldErrors errors={errors} /> : null}
        </div>
        {/* {inline ? 'inline' : 'block'} */}
      </div>
    );
  },
);
