import { ChiroUpAPI } from '@chiroup/client-core';
import { getExtension } from '../components/common/helpers/getExtension';

const downloadService = () => {
  const getUrl = async (clinicId: number, key: string) => {
    return ChiroUpAPI.get('api', `/${clinicId}/download`, {
      queryParams: {
        key,
      },
    });
  };

  const download = async (
    type: string,
    clinicId?: number,
    key?: string,
    lastName?: string,
    firstName?: string,
  ) => {
    if (!clinicId || !key) {
      return;
    }
    const url = await getUrl(clinicId, key);
    const extension = getExtension(key);
    const image = await fetch(url);
    const blob = await image.blob();
    const imageURL = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = imageURL;
    let name = '';
    if (lastName) {
      name += `${lastName}-`;
    }
    if (firstName) {
      name += `${firstName}-`;
    }
    name += type;
    link.download = `${name.toLowerCase()}.${extension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return {
    getUrl,
    download,
  };
};

export default downloadService();
