import React from 'react';
import { Tooltip } from 'react-tooltip';

const HealthInsuranceBadge: React.FC<{
  height?: number;
  width?: number;
}> = ({ height = 28, width = 28}) => {
  return (
    <>
      <div
        data-tooltip-content={`Health Insurance`}
        data-tooltip-id="health-insurance-badge-tooltip"
        style={{ display: 'inline-block', position: 'relative' }}
      >
        <svg
          width={width}
          height={height}
          viewBox="0 0 4.2438703 4.2333174"
          version="1.1"
          id="svg1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs id="defs1">
            <linearGradient id="swatch6">
              <stop
                style={{ stopColor: '#000000', stopOpacity: 1 }}
                offset="0"
                id="stop6"
              />
            </linearGradient>
          </defs>
          <g id="layer1" transform="translate(-114.25136,-112.62227)">
            <g
              style={{ fill: 'none', stroke: '#000000', strokeOpacity: 1 }}
              id="g1"
              transform="matrix(0.26458093,0.0011263,-0.0011263,0.26458093,113.2082,111.55044)"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M 12,4.75 4.75001,8 c 0,0 -0.75,11.25 7.24999,11.25 C 20,19.25 19.25,8 19.25,8 Z"
                id="path1"
                style={{
                  stroke: 'rgb(147 204 104)',
                  strokeOpacity: 1,
                }}
              />
            </g>
            <g
              style={{ fill: 'none' }}
              id="g10"
              transform="matrix(0.16285957,0,0,0.14094456,114.40059,113.03536)"
            >
              <circle
                cx="12"
                cy="12"
                r="7.25"
                stroke="rgb(147 204 104)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                fill="rgb(147 204 104)"
                id="circle1"
              />
              <path
                d="m 13.25,7.75 h -2.5 v 3 h -3 v 2.5 h 3 v 3 h 2.5 v -3 h 3 v -2.5 h -3 z"
                stroke="rgb(147 204 104)"
                strokeWidth=".2"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="#fff"
                id="path2"
              />
            </g>
          </g>
        </svg>
      </div>
      <Tooltip id="health-insurance-badge-tooltip" className="text-xs" />
    </>
  );
};

export default HealthInsuranceBadge;
