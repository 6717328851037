import {
  PatientTransactionItemType,
  AppointmentInsuranceType,
  PatientTransaction,
  TransactionItemSubtypeEnum,
} from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';
import patientBillingService from './patientBilling.service';

/**
 * This may look funky, but trying to NOT save the transaction items TWICE!
 * If this sees a transaction in the payload, it will save it as a transaction
 * and not just as services. Otherwise, hopefully it works the way it did.
 *
 * @returns
 */
const billingServiceFactory = () => {
  const set = async <T>({
    clinicId,
    id,
    services,
    insurances,
    transaction,
    courtesyBilling = false,
    billingProfileId = null,
    superBill = false,
  }: {
    clinicId: number;
    id: string;
    services: PatientTransactionItemType[];
    insurances: Partial<AppointmentInsuranceType>[];
    transaction?: PatientTransaction | null;
    courtesyBilling?: boolean;
    superBill?: boolean;
    billingProfileId?: number | null;
  }): Promise<T> => {
    let transactionResp = null;
    let servicesOnly = null;
    let serviceResponse = {};
    const proms: Promise<any>[] = [];

    if (transaction) {
      transaction.items = transaction.items.map((item) => {
        const itemIsLocked = services.some(
          (service) => service.id === item.id && service.locked,
        );
        return {
          ...item,
          locked: itemIsLocked || false,
        };
      });
      transactionResp = patientBillingService
        .savePatientTransaction({
          clinicId,
          transaction,
        })
        .then((resp) => {
          servicesOnly = resp?.items.filter(
            (service: PatientTransactionItemType) =>
              service.subtype === TransactionItemSubtypeEnum.Service,
          );
        });
      proms.push(transactionResp);
    } else {
      if (!id) {
        return servicesOnly ? ({ services: servicesOnly } as any) : ({} as any);
      }
      const servicesPromise = ChiroUpAPI.post(
        'api',
        `/encounters/${clinicId}/appointment/${id}/services`,
        {
          body: {
            services: transaction ? null : services,
            insurances,
            courtesyBilling,
            superBill,
            billingProfileId,
          },
        },
      ).then((resp) => {
        serviceResponse = resp;
        return resp;
      });

      proms.push(servicesPromise);
    }

    await Promise.all(proms);

    return servicesOnly
      ? ({ ...serviceResponse, services: servicesOnly } as any)
      : (serviceResponse as any);
  };

  const get = async <T>({
    clinicId,
    id,
  }: {
    clinicId: number;
    id: string;
  }): Promise<T> => {
    if (!id) {
      return {
        services: [],
        insurances: [],
        payors: [],
      } as any;
    }
    return ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/appointment/${id}/services`,
      {},
    );
  };

  const afterLock = async ({
    clinicId,
    billingKey,
    trace = false,
  }: {
    clinicId: number;
    billingKey: string | undefined | null;
    trace?: boolean;
  }) => {
    if (!billingKey || !clinicId) {
      Promise.reject(`Clinic id and billing key are required.`);
    }
    const target = `/transactions/${clinicId}/transaction/${billingKey}/after-lock`;
    if (trace) {
      console.log(`[TRACE] billingService.afterLock: ${target}`);
    }
    return ChiroUpAPI.get('api', target, {});
  };

  /**
   * Expose it in two ways. Might make things easier?
   */
  const encounterServices = {
    get,
    set,
    afterLock,
  };
  const appointmentServices = {
    get,
    set,
    afterLock,
  };

  return {
    encounterServices,
    appointmentServices,
  };
};

export const billingService = billingServiceFactory();
