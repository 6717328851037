import { functionalDisabilitySurveys, isEmpty } from '@chiroup/core';
import React, { useContext } from 'react';
import { MeContext } from '../../../contexts/me.context';
import Header from '../../layout/Header';
import ClinicSettingsPatientPreferencesForm, {
  PatientPreferencesForm,
} from './ClinicSettingsPatientPreferencesForm';
import ClinicSettingsTabs from './ClinicSettingsTabs';

export const patientSettingReference: any = {
  'Allergy survey': {
    uiProp: 'allergySurvey',
    type: 'boolean',
    defaultValue: false,
    section: 'intakeSurveys',
  },
  'Chief complaint survey': {
    uiProp: 'ccs',
    type: 'boolean',
    defaultValue: false,
    section: 'intakeSurveys',
  },
  'Family history': {
    uiProp: 'familyHistory',
    type: 'boolean',
    defaultValue: false,
    section: 'intakeSurveys',
  },
  'Health history': {
    uiProp: 'healthHistory',
    type: 'boolean',
    defaultValue: false,
    section: 'intakeSurveys',
  },
  'Lifestyle history': {
    uiProp: 'lifestyleHistory',
    type: 'boolean',
    defaultValue: false,
    section: 'intakeSurveys',
  },
  'Medication history': {
    uiProp: 'medicationHistory',
    type: 'boolean',
    defaultValue: false,
    section: 'intakeSurveys',
  },
  'Review of systems': {
    uiProp: 'reviewOfSystems',
    type: 'boolean',
    defaultValue: false,
    section: 'intakeSurveys',
  },
  'Surgical history': {
    uiProp: 'surgicalHistory',
    type: 'boolean',
    defaultValue: false,
    section: 'intakeSurveys',
  },
  'Restrict notifications to primary clinician': {
    uiProp: 'patientNotifications',
    type: 'boolean',
    defaultValue: false,
    section: 'notifications',
  },
  'Photo ID': {
    uiProp: 'photoID',
    type: 'boolean',
    defaultValue: false,
    section: 'intake',
  },
  'Insurance card': {
    uiProp: 'insurance',
    type: 'boolean',
    defaultValue: false,
    section: 'intake',
  },
  'Patient demographics': {
    uiProp: 'demographics',
    type: 'boolean',
    defaultValue: false,
    section: 'intake',
  },
  'Prefers email': {
    uiProp: 'prefersEmail',
    type: 'boolean',
    defaultValue: false,
    section: 'preferences',
  },
  'Prefers text': {
    uiProp: 'prefersText',
    type: 'boolean',
    defaultValue: true,
    section: 'preferences',
  },
  'Prefers none': {
    uiProp: 'prefersNone',
    type: 'boolean',
    defaultValue: false,
    section: 'preferences',
  },
  DASH: {
    uiProp: 'quickDash',
    type: 'boolean',
    defaultValue: false,
    section: 'ccsFollowup',
  },
  Oswestry: {
    uiProp: 'oswestry',
    type: 'boolean',
    defaultValue: false,
    section: 'ccsFollowup',
  },
  NDI: {
    uiProp: 'ndi',
    type: 'boolean',
    defaultValue: false,
    section: 'ccsFollowup',
  },
  LEFS: {
    uiProp: 'lefs',
    type: 'boolean',
    defaultValue: false,
    section: 'ccsFollowup',
  },
  ssn: {
    uiProp: 'ssn',
    type: 'boolean',
    defaultValue: false,
    section: 'patientDemographics',
  },
};

export const defaultValueForItem = (
  serverItem: any,
  settingReferenceItem: any,
) => {
  const emptyServerValue = isEmpty(serverItem?.jsonValue);
  if (emptyServerValue) {
    return settingReferenceItem.defaultValue;
  }
  if (settingReferenceItem.type === 'boolean') {
    return serverItem.jsonValue === true;
  } else if (settingReferenceItem.type === 'number') {
    return Number(serverItem.jsonValue);
  } else if (settingReferenceItem.type === 'json') {
    return JSON.parse(serverItem.jsonValue);
  }
};

export const transformPreferences = (
  fromServer?: any[],
): PatientPreferencesForm => {
  const ommittedDisabilitySurveys: string[] =
    fromServer?.find(
      (item) =>
        item.setting === 'Hidden surveys' || item.name === 'Hidden surveys',
    )?.jsonValue || [];

  const disabilitySurveys: string[] = functionalDisabilitySurveys
    .filter((survey: any) => !ommittedDisabilitySurveys?.includes(survey.id))
    .map((item: any) => item.id);
  const customIntakes = fromServer?.find(
    (item) => item.setting === 'Custom intakes',
  );

  const basicIntakeSurveys: string[] =
    customIntakes?.jsonValue?.basicIntakeSurveys;
  const detailedIntakeSurveys: string[] =
    customIntakes?.jsonValue?.detailedIntakeSurveys;

  return Object.keys(patientSettingReference).reduce(
    (obj: any, serverName: string) => {
      const fromServerPatientPreferences = fromServer?.find(
        (setting) =>
          setting.setting === 'New patient preferences' ||
          setting.name === 'New patient preferences',
      );
      obj[patientSettingReference[serverName].uiProp] =
        fromServerPatientPreferences?.jsonValue?.[
          patientSettingReference[serverName].uiProp
        ] === true ||
        fromServerPatientPreferences?.jsonValue?.[
          patientSettingReference[serverName].uiProp
        ] === 'true';
      return {
        ...obj,
        disabilitySurveys,
        basicIntakeSurveys,
        detailedIntakeSurveys,
      };
    },
    {},
  );
};

const ClinicSettingsPatientPreferences: React.FC = () => {
  const meContext = useContext(MeContext);

  const defaults = () => {
    const transformed = Array.isArray(meContext?.me.selectedClinic?.settings)
      ? transformPreferences(meContext?.me.selectedClinic?.settings)
      : meContext?.me.selectedClinic?.settings || {};
    return transformed;
  };

  return (
    <>
      <Header title="Survey preferences" />
      <ClinicSettingsTabs />
      <div className="px-6">
        <ClinicSettingsPatientPreferencesForm
          defaultValues={defaults()}
          ID={meContext?.me.ID}
          clinicID={meContext?.me.selectedClinic?.ID}
        />
      </div>
    </>
  );
};

export default ClinicSettingsPatientPreferences;
