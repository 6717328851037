import React from 'react';

type Props = {
  className?: string;
  parentClassName?: string;
  children: React.ReactNode;
};

const SectionContainer: React.FC<Props> = ({
  className,
  parentClassName = 'p-3',
  children,
}) => {
  return (
    <div className={parentClassName}>
      <div className={`${className}`}>{children}</div>
    </div>
  );
};

export default SectionContainer;
