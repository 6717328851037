import { InformationCircleIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { Link } from 'react-router-dom';

export const AlertIcons = {
  info: (
    <InformationCircleIcon
      className="h-5 w-5 text-blue-400"
      aria-hidden="true"
    />
  ),
};

type Props = {
  icon?: React.ReactNode;
  button?: {
    text: string;
    link: string;
  };
  className?: string;
  children: React.ReactNode;
};

const Alert: React.FC<Props> = ({ children, icon, button, className = '' }) => {
  return (
    <div className={['rounded-md bg-blue-50 p-4', className].join(' ')}>
      <div className="flex">
        <div className="flex-shrink-0">{icon}</div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-700">{children}</p>
          {button && (
            <p className="mt-3 text-sm md:mt-0 md:ml-6">
              <Link
                to={button.link}
                className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
              >
                {button.text} <span aria-hidden="true">&rarr;</span>
              </Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Alert;
