import { classNames } from '@chiroup/core';
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';

type Props = {
  command: (args: { name: string }) => void;
  items: {
    name: string;
    emoji: string;
    fallbackImage?: string;
  }[];
};

const EmojiList: React.FC<Props> = forwardRef(function EmojiList(
  { command, items },
  ref,
) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = useCallback(
    (index: number) => {
      const item = items[index];

      if (item) {
        command({ name: item.name });
      }
    },
    [command, items],
  );

  const upHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  }, [items, selectedIndex]);

  const downHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + 1) % items.length);
  }, [items, selectedIndex]);

  const enterHandler = useCallback(() => {
    selectItem(selectedIndex);
  }, [selectItem, selectedIndex]);

  useEffect(() => setSelectedIndex(0), [items]);

  useImperativeHandle(
    ref,
    () => {
      return {
        onKeyDown: ({ event }: { event: KeyboardEvent }) => {
          if (event.key === 'ArrowUp') {
            upHandler();
            return true;
          }

          if (event.key === 'ArrowDown') {
            downHandler();
            return true;
          }

          if (event.key === 'Enter') {
            enterHandler();
            return true;
          }

          return false;
        },
      };
    },
    [upHandler, downHandler, enterHandler],
  );

  return (
    <div className="relative overflow-hidden rounded-lg bg-white p-1 text-sm text-zinc-700 shadow-md">
      {items.map((item, index) => (
        <button
          className={classNames(
            `item flex w-full flex-row items-center gap-1 rounded-md border-2 p-1 text-left hover:bg-zinc-100`,
            index === selectedIndex
              ? 'is-selected border-primary-500'
              : 'border-transparent',
          )}
          key={index}
          onClick={() => selectItem(index)}
        >
          {item.fallbackImage ? (
            <img src={item.fallbackImage} alt={item.name} className="h-4 w-4" />
          ) : (
            item.emoji
          )}
          <span>:{item.name}:</span>
        </button>
      ))}
    </div>
  );
});

export default EmojiList;
