import {
  Button,
  ButtonColors,
  Checkbox,
  Input,
  InputMasked,
} from '@chiroup/components';
import {
  DisciplineInsuranceBenefit,
  Insurance,
  UserRoles,
} from '@chiroup/core';
import { MeContext } from '../../../../../contexts/me.context';
import { useContext } from 'react';

interface Props {
  value: Partial<Insurance>;
  patchValue: (values: Partial<Insurance>) => void;
  index: number;
}

const updateNestedValue = (
  obj: Partial<Insurance>,
  keyPath: string,
  newValue: any,
): Partial<Insurance> => {
  const keys = keyPath.split('.');
  const result = { ...obj };
  let current: any = result;

  for (let i = 0; i < keys.length; i++) {
    const key = isNaN(Number(keys[i])) ? keys[i] : Number(keys[i]);

    if (i === keys.length - 1) {
      current[key] = newValue;
    } else {
      if (!current[key]) {
        current[key] = isNaN(Number(keys[i + 1])) ? {} : [];
      }
      current = current[key];
    }
  }
  return result;
};

const DisciplineBenefitForm: React.FC<Props> = ({
  value,
  patchValue,
  index,
}) => {
  const { hasRole } = useContext(MeContext);

  const handleInputChange = (keyPath: string, e: any) => {
    const updatedValue = updateNestedValue(value, keyPath, e);
    patchValue(updatedValue);
  };

  const currentBenefit: DisciplineInsuranceBenefit | undefined =
    value.disciplineBenefits?.[index];

  return (
    <>
      <div className="sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
        <Input
          name={`maxTreatments${index}`}
          label="Max Number of Treatments"
          type="number"
          min="0"
          value={currentBenefit?.maxNumberOfTreatments}
          onChange={(e) => {
            handleInputChange(
              `disciplineBenefits.${index}.maxNumberOfTreatments`,
              e,
            );
          }}
          className="col-span-2"
        />
        <div className="relative col-span-2">
          <InputMasked
            label="Co-Pay"
            name={`coPay${index}`}
            placeholder="#,###.##"
            value={currentBenefit?.coPay}
            onChange={(e) => {
              handleInputChange(`disciplineBenefits.${index}.coPay`, e);
            }}
            numericOptions={{
              decimalScale: 2,
              fixedDecimalScale: true,
            }}
          />
          <Checkbox
            name={`coPayExempt${index}`}
            label="Co-Pay Exempt"
            value={currentBenefit?.coPayExempt || false}
            onChange={(e) => {
              handleInputChange(`disciplineBenefits.${index}.coPayExempt`, e);
            }}
            className="col-span-2"
          />
        </div>

        <Input
          name={`coInsurance${index}`}
          label="Co-Insurance"
          min="0"
          max="100"
          value={currentBenefit?.coInsurance}
          onChange={(e) => {
            handleInputChange(`disciplineBenefits.${index}.coInsurance`, e);
          }}
          className="col-span-2"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 60 60"
              version="1.0"
              y="0"
              x="0"
              height={14}
              width={14}
              className="text-gray-500"
            >
              <path
                id="path832"
                fill="currentColor"
                transform="matrix(6.0787 0 0 6.0787 -120.93 -180)"
                d="m20.945 31.999c0-0.348 0.082-0.67 0.246-0.967 0.168-0.3 0.397-0.537 0.686-0.709 0.289-0.175 0.602-0.263 0.937-0.263 0.266 0 0.514 0.052 0.745 0.158 0.234 0.105 0.433 0.248 0.597 0.428 0.168 0.175 0.299 0.38 0.393 0.615 0.094 0.234 0.14 0.48 0.14 0.738 0 0.344-0.082 0.666-0.246 0.967s-0.39 0.541-0.679 0.721c-0.289 0.179-0.606 0.269-0.95 0.269-0.351 0-0.671-0.09-0.96-0.269-0.286-0.18-0.508-0.42-0.668-0.721-0.161-0.305-0.241-0.627-0.241-0.967zm1.43 5.496l3.873-6.803c0.125-0.214 0.242-0.373 0.352-0.474 0.109-0.106 0.24-0.158 0.392-0.158 0.129 0 0.248 0.031 0.358 0.093 0.109 0.059 0.195 0.141 0.257 0.246 0.063 0.102 0.094 0.211 0.094 0.329 0 0.144-0.031 0.281-0.094 0.41-0.062 0.125-0.156 0.289-0.281 0.492l-3.873 6.803c-0.113 0.207-0.226 0.365-0.34 0.474-0.113 0.11-0.248 0.164-0.404 0.164-0.199 0-0.367-0.066-0.504-0.199s-0.205-0.291-0.205-0.474c0-0.145 0.029-0.278 0.088-0.399 0.062-0.121 0.158-0.289 0.287-0.504zm-0.064-5.496c0 0.164 0.045 0.303 0.134 0.416 0.094 0.109 0.217 0.164 0.369 0.164 0.157 0 0.28-0.055 0.37-0.164 0.089-0.113 0.134-0.252 0.134-0.416 0-0.16-0.045-0.293-0.134-0.398-0.09-0.11-0.213-0.164-0.37-0.164-0.152 0-0.275 0.054-0.369 0.164-0.089 0.105-0.134 0.238-0.134 0.398zm4.072 5.127c0 0.152 0.045 0.285 0.135 0.398 0.093 0.11 0.216 0.165 0.369 0.165 0.152 0 0.273-0.055 0.363-0.165 0.094-0.113 0.141-0.246 0.141-0.398 0-0.16-0.047-0.295-0.141-0.404-0.09-0.114-0.211-0.17-0.363-0.17-0.153 0-0.276 0.056-0.369 0.17-0.09 0.109-0.135 0.244-0.135 0.404zm-1.371 0c0-0.352 0.082-0.676 0.246-0.973 0.168-0.3 0.394-0.539 0.68-0.714 0.289-0.18 0.605-0.27 0.949-0.27s0.658 0.09 0.943 0.27c0.285 0.179 0.51 0.419 0.674 0.72 0.168 0.297 0.252 0.619 0.252 0.967 0 0.344-0.084 0.664-0.252 0.961-0.164 0.297-0.389 0.535-0.674 0.715s-0.599 0.269-0.943 0.269c-0.356 0-0.678-0.088-0.967-0.263-0.285-0.18-0.508-0.42-0.668-0.721s-0.24-0.621-0.24-0.961z"
              />
            </svg>
          }
          iconRight
        />
        <Input
          name={`referral${index}`}
          label="Referral number"
          className="col-span-2"
          value={currentBenefit?.referralNumber}
          onChange={(e) => {
            handleInputChange(`disciplineBenefits.${index}.referralNumber`, e);
          }}
        />
        <hr className="col-span-4 my-4 border-t border-gray-400" />
        <p className="col-span-4">Prior Authorization Number</p>
        <Input
          name={`number${index}`}
          label="Number"
          value={currentBenefit?.priorAuthorization?.number}
          onChange={(e) => {
            handleInputChange(
              `disciplineBenefits.${index}.priorAuthorization.number`,
              e,
            );
          }}
          className="col-span-4 px-2"
        />
        <InputMasked
          className="col-span-2 pl-2"
          value={currentBenefit?.priorAuthorization?.validStartDate}
          onChange={(e) => {
            handleInputChange(
              `disciplineBenefits.${index}.priorAuthorization.validStartDate`,
              e,
            );
          }}
          name={`validStartDate${index}`}
          label="Valid Start Date"
          patternFormat="##/##/####"
          placeholder="MM/DD/YYYY"
        />
        <InputMasked
          className="col-span-2 pr-2"
          value={currentBenefit?.priorAuthorization?.validEndDate}
          onChange={(e) => {
            handleInputChange(
              `disciplineBenefits.${index}.priorAuthorization.validEndDate`,
              e,
            );
          }}
          name={`validEndDate${index}`}
          label="Valid End Date"
          patternFormat="##/##/####"
          placeholder="MM/DD/YYYY"
        />
        <Input
          name={`numberOfUses${index}`}
          label="Number of Uses"
          type="number"
          min="0"
          className="col-span-2 px-2"
          value={currentBenefit?.priorAuthorization?.numberOfUses}
          onChange={(e) => {
            handleInputChange(
              `disciplineBenefits.${index}.priorAuthorization.numberOfUses`,
              e,
            );
          }}
        />
      </div>
      {hasRole([UserRoles.Admin, UserRoles.Biller, UserRoles.Staff]) && (
        <Button
          text="Remove Benefit"
          onClick={() => {
            value.disciplineBenefits?.splice(index, 1);
            patchValue(value);
          }}
          className="mt-4"
          color={ButtonColors.red}
        />
      )}
    </>
  );
};

export default DisciplineBenefitForm;
