/**
 * Story: 7180257105
 */

import {
  AppointmentInsuranceType,
  isaServiceItem,
  PatientTransaction,
  PatientTransactionItemType,
} from '@chiroup/core';
import { MagicAction, MagicActionType } from './commonMagic';
import { TransactionMagiceResponseType } from './transactionMagic';
import { ServiceMagiceResponseType } from './serviceMagic';

export const applicableCodes: { [key: string]: boolean } = {
  '98940': true,
  '98941': true,
  '98942': true,
};

/**
 * This examines the transaction and updates it in place.
 *
 * @param transaction
 * @returns
 */
export const atAutoPopulateModifier = (
  transaction: PatientTransaction,
): TransactionMagiceResponseType => {
  let touched = false;
  const youSmellMedicare = insuranceSmellsLikeMedicare(
      transaction?.insurances as AppointmentInsuranceType[],
    ),
    actions: MagicActionType[] = [],
    items = (transaction.items || []).filter(
      (item: PatientTransactionItemType) => applicableCodes[item.code ?? ''],
    );

  if (items?.length) {
    if (youSmellMedicare) {
      const addedTo: string[] = [];
      items.forEach((item: PatientTransactionItemType) => {
        const itemAsAny = item as any,
          modifiers = [];
        if (item.modifier1) modifiers.push(item.modifier1);
        if (item.modifier2) modifiers.push(item.modifier2);
        if (item.modifier3) modifiers.push(item.modifier3);
        if (item.modifier4) modifiers.push(item.modifier4);

        const hasAtModifier = modifiers.some((m) => m === 'AT');

        if (!hasAtModifier) {
          modifiers.unshift('AT');
          touched = true;
          addedTo.push(item.code ?? '-unknown-');
          itemAsAny.modifier1 = modifiers[0];
          itemAsAny.modifier2 = modifiers[1] ?? null;
          itemAsAny.modifier3 = modifiers[2] ?? null;
          itemAsAny.modifier4 = modifiers[3] ?? null;
          if (modifiers.length > 4) {
            actions.push({
              message: `All modifiers already populated for ${item.code}. Modifier ${modifiers[4]} dropped.`,
              type: MagicAction.Warning,
            });
          }
        }
      });
      if (addedTo.length) {
        actions.push({
          message: `Added modifier AT to code${
            addedTo.length === 1 ? '' : 's'
          } ${addedTo.join(', ')}.`,
          type: MagicAction.Info,
        });
      }
      if (touched) {
        transaction.services = transaction.items.filter((item) =>
          isaServiceItem(item.subtype),
        );
      }
    } else {
      const codes: string[] = [];

      items.forEach((item: PatientTransactionItemType) => {
        const modifiers = [
            item.modifier1,
            item.modifier2,
            item.modifier3,
            item.modifier4,
          ],
          foundModifier = modifiers.some((m) => m === 'AT');
        if (foundModifier) {
          codes.push(item.code ?? '-unknown-');
        }
      });
      if (codes.length) {
        actions.push({
          message: `Found code${codes.length === 1 ? '' : 's'} ${codes.join(
            ', ',
          )} with modifier AT. This may not be appropriate without Medicare on the transaction.`,
          type: MagicAction.Warning,
        });
      }
    }
  }

  return {
    actions,
    transaction,
    touched,
  };
};

/**
 *
 * Story: 7519482792
 *
 * This examines the service and insurances and updates it in place.
 *
 * @param arguments service and insurances
 * @returns
 */
export const serviceAutoPopulateMedicareModifier = ({
  service,
  insurances = [],
}: {
  service: PatientTransactionItemType;
  insurances: AppointmentInsuranceType[];
}): ServiceMagiceResponseType => {
  const actions: MagicActionType[] = [];

  if (!service) {
    actions.push({
      message: `No actions possible without a service.`,
      type: MagicAction.Error,
    });
  }
  let touched = false;
  const youSmellMedicare = insuranceSmellsLikeMedicare(insurances);

  if (actions.length) {
    return {
      actions,
      service,
      touched,
    };
  }

  const modifiers = [];

  if (service.modifier1) modifiers.push(service.modifier1);
  if (service.modifier2) modifiers.push(service.modifier2);
  if (service.modifier3) modifiers.push(service.modifier3);
  if (service.modifier4) modifiers.push(service.modifier4);

  const foundModifier = modifiers.some((m) => m === 'AT');

  if (youSmellMedicare) {
    if (!foundModifier) {
      modifiers.unshift('AT');
      touched = true;
      actions.push({
        message: `Modifier AT added to ${service.code}.`,
        type: MagicAction.Info,
      });
      if (modifiers.length > 4) {
        actions.push({
          message: `Modifier ${modifiers[4]} dropped.`,
          type: MagicAction.Warning,
        });
      }
      service.modifier1 = modifiers[0];
      service.modifier2 = modifiers[1] ?? null;
      service.modifier3 = modifiers[2] ?? null;
      service.modifier4 = modifiers[3] ?? null;
    }
  }

  return {
    actions,
    service,
    touched,
  };
};

// Are any of the insurances Medicare or Medicaid?
export const insuranceSmellsLikeMedicare = (
  insurances: AppointmentInsuranceType[],
) => {
  return insurances?.some(
    (i: any) =>
      (i?.insuranceProgram ?? '').indexOf('mcare') === 0 ||
      (i?.insuranceProgram ?? '').indexOf('mcaid') === 0 ||
      (i?.insuranceProgram ?? '').indexOf('hmomedicare') === 0,
  );
};
