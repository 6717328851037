import { useContext, useEffect, useRef } from 'react';
import { WSContext } from '../../../contexts/ws.context';

const useWS = (subscription: string | null, onMessage: (data: any) => void) => {
  const { addSubscription, removeSubscription } = useContext(WSContext);
  const onMessageRef = useRef(onMessage);

  useEffect(() => {
    onMessageRef.current = onMessage;
  }, [onMessage]);

  useEffect(() => {
    const handleMessage = (data: any) => {
      onMessageRef.current(data);
    };

    if (subscription) {
      addSubscription(subscription, handleMessage);
    }
    return () => {
      if (subscription) {
        removeSubscription(subscription);
      }
    };
  }, [subscription, addSubscription, removeSubscription]);
};

export default useWS;
