import { MeContext } from '../../../../../contexts/me.context';
import patientUploadService from '../../../../../services/patient-upload.service';
import downloadService from '../../../../../services/download.service';
import { ChangeEvent, useContext, useState } from 'react';
import { Insurance, Patient, ValueOf } from '@chiroup/core';

const useInsuranceCard = (
  patient: Partial<Patient>,
  value: Partial<Insurance>,
  onChange: (
    key: keyof Partial<Insurance>,
  ) => (val: ValueOf<Insurance>) => void,
) => {
  const { me } = useContext(MeContext);
  const [fileOpened, setFileOpened] = useState<string | null>(null);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);

  const uploadInsuranceCard = async (
    e: ChangeEvent<HTMLInputElement>,
    side: string,
  ) => {
    if (e.target.files?.[0]) {
      try {
        const url = await patientUploadService.upload({
          clinicId: me.selectedClinic?.ID,
          patientId: patient.ID,
          file: e.target.files?.[0] || null,
        });
        side === 'front'
          ? onChange('insuranceFront')(url)
          : onChange('insuranceBack')(url);
      } catch (err) {
        // Handle error
        console.error(err);
      }
    }
  };

  const openFile = async (fileName?: string) => {
    if (!me?.selectedClinic?.ID || !fileName) {
      return;
    }
    try {
      setLoadingFile(true);
      const url = await downloadService.getUrl(
        me?.selectedClinic?.ID,
        fileName,
      );
      if (!url) {
        return;
      }
      setFileOpened(url);
      setLoadingFile(false);
    } catch (err) {
      setLoadingFile(false);
    }
  };

  return {
    fileOpened,
    loadingFile,
    uploadInsuranceCard,
    openFile,
    setFileOpened,
  };
};

export default useInsuranceCard;
