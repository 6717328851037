export const orderBy = (prop: string, desc?: boolean) => {
  return (a: any, b: any) => {
    if (a[prop] < b[prop]) {
      return desc ? 1 : -1;
    }
    if (a[prop] > b[prop]) {
      return desc ? -1 : 1;
    }
    return 0;
  };
};
