import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ReadyState } from 'react-use-websocket/dist/lib/constants';
import { WebSocketMessage } from 'react-use-websocket/dist/lib/types';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { WSContext } from './ws.context';
import { MeContext } from './me.context';
import { fetchAuthSession } from '@aws-amplify/auth';

type Props = {
  children: React.ReactNode;
};

const WebsocketContext = ({ children }: Props) => {
  const { sessionId, me, selectedLocationFull } = useContext(MeContext);
  const [subscriptions, setSubscriptions] = useState<{
    [key: string]: (message: WebSocketMessage) => void;
  }>({});
  const getSocketUrl = useCallback(async () => {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    return `${
      import.meta.env.VITE_WEBSOCKET_URL
    }?token=${accessToken}&sessionId=${sessionId}`;
  }, [sessionId]);

  const { readyState, sendJsonMessage } = useWebSocket<any | null>(
    getSocketUrl,
    {
      share: true,
      shouldReconnect: () => true,
      retryOnError: true,
      reconnectAttempts: 100,
      reconnectInterval: (atmpt) => Math.min(Math.pow(2, atmpt) * 1000, 60000),
      onOpen: () => {
        console.log('WebSocket connected');
      },
      onMessage: (message) => {
        const messageData = JSON.parse(message.data || '{}');
        const { subscription, ...data } = messageData;
        const callback = subscriptions[subscription];
        callback?.(data);
      },
      onError: (event) => {
        console.error('WebSocket error:', event);
      },
      onClose: (event) => {
        console.error('WebSocket closed:', event);
      },
    },
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const addSubscription = useCallback(
    (subscription: string, callback: (message: WebSocketMessage) => void) => {
      setSubscriptions((prev) => ({
        ...prev,
        [subscription]: callback,
      }));
    },
    [],
  );

  const removeSubscription = useCallback((subscription: string) => {
    setSubscriptions((prev) => {
      const { [subscription]: _, ...rest } = prev;
      return rest;
    });
  }, []);

  useEffect(() => {
    if (readyState === 1) {
      sendJsonMessage({
        action: 'subscriptions',
        sessionId,
        userId: me?.ID,
        clinicId: me?.selectedClinic?.ID,
        locationId: selectedLocationFull?.ID,
        subscriptions: Object.keys(subscriptions),
      });
    }
  }, [
    readyState,
    sessionId,
    subscriptions,
    me?.ID,
    me?.selectedClinic?.ID,
    selectedLocationFull?.ID,
    sendJsonMessage,
  ]);

  return (
    <WSContext.Provider
      value={{ addSubscription, removeSubscription, connectionStatus }}
    >
      {children}
    </WSContext.Provider>
  );
};

export default WebsocketContext;
