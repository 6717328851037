import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { MeContext } from '../contexts/me.context';
import userService from '../services/user.service';
import { ToastContext, ToastTypes } from '../contexts/toast.context';

type BillingProfileUser = {
  ID: number;
  billingProfileId: number;
  userId: string;
  clinicID: number;
  active: boolean;
  defaultProfile: boolean;
  description?: string;
};

const getQuery = function (clinicId: number, active: boolean) {
  return async (context: QueryFunctionContext) => {
    const userId = context.queryKey[1] as string;
    if (!userId) return [];
    return await userService.getUserBillingProfiles(userId, clinicId, active);
  };
};

type Props = {
  userId: string;
  active?: boolean;
  refetchOnMount?: boolean;
};
const useUserBillingProfiles = ({
  userId,
  refetchOnMount = true,
  active = true,
}: Props) => {
  const meContext = useContext(MeContext);
  const { createToast } = useContext(ToastContext);

  const { data, isFetching, refetch } = useQuery<BillingProfileUser[]>(
    ['userBillingProfiles', userId],
    getQuery(meContext?.me.selectedClinic?.ID || -1, active),
    {
      refetchOnWindowFocus: false,
      refetchOnMount,
      onError: (err: any) => {
        createToast({
          type: ToastTypes.Fail,
          title: 'Billing Profiles',
          description: `${err?.response?.data?.message || err?.message}`,
          duration: 5000,
        });
      },
      retry: false,
    },
  );
  return { data, isFetching, refetch };
};

export default useUserBillingProfiles;
