import { FormError } from '@chiroup/core';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { useId } from 'react';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { FieldErrors } from './FieldErrors';

type Props = {
  className?: string;
  errors?: FormError;
  label?: string | React.ReactNode;
  labelClassName?: string;
  tooltip?: string;
  tooltipClassName?: string;
  onChange?: (val: boolean) => void;
  value: boolean;
  labelOnLeft?: boolean;
  rightSide?: React.ReactNode;
  onValue?: string | number | boolean;
  hint?: string | React.ReactNode;
  disabled?: boolean;
  readonly?: boolean;
};

export const Toggle: React.FC<Props> = ({
  className,
  errors,
  label,
  labelClassName,
  tooltip,
  tooltipClassName,
  onChange,
  value,
  labelOnLeft,
  rightSide,
  onValue = true,
  hint,
  disabled = false,
  readonly = false,
}) => {
  const id = uuidv4();

  const toggle = () => {
    if (!disabled && typeof onChange === 'function') {
      onChange(!value);
    }
  };

  const tooltipIconClassName =
    'h-3 w-3 text-gray-400 inline-block ml-2 align-text-top';
  const toolTipId = useId();

  const getLabelContents = () => {
    return (
      <div>
        <span className="inline-block">{label}</span>
        {tooltip && (
          <span className="inline-block">
            <QuestionMarkCircleIcon
              className={tooltipIconClassName}
              id={toolTipId}
            />
            <Tooltip anchorId={toolTipId}>
              <div className={tooltipClassName}>{tooltip}</div>
            </Tooltip>
          </span>
        )}
      </div>
    );
  };

  return (
    <div className={`flex items-center gap-2 ${className || ''}`}>
      <div className="flex flex-row">
        <div className="flex flex-col gap-2">
          <div
            className={[
              'flex items-center gap-2',
              labelOnLeft ? 'flex-row-reverse' : 'flex-row',
            ].join(' ')}
          >
            <span
              onClick={toggle}
              role="checkbox"
              aria-checked="false"
              className={[
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring',
                disabled
                  ? 'cursor-not-allowed'
                  : readonly
                    ? 'cursor-auto'
                    : 'cursor-pointer',
                value === onValue && disabled ? 'bg-primary-300' : '',
                value === onValue && !disabled
                  ? 'bg-primary-600'
                  : 'bg-gray-300 dark:bg-darkGray-700',
              ].join(' ')}
            >
              <span
                aria-hidden="true"
                className={[
                  'inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200',
                  value === onValue ? 'translate-x-5' : 'translate-x-0',
                ].join(' ')}
              />
            </span>
            {!!label && (
              <label
                className={classNames(
                  'block text-sm leading-5',
                  errors
                    ? 'text-red-500'
                    : disabled
                      ? 'text-gray-400 dark:text-darkGray-100'
                      : 'text-gray-900 dark:text-darkGray-200',
                )}
              >
                {getLabelContents()}
              </label>
            )}
          </div>
        </div>
        {rightSide}
        {hint && (
          <svg
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            id={`tt-${id}`}
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {hint && (
          <Tooltip anchorId={`tt-${id}`} offset={-8}>
            <span>{hint}</span>
          </Tooltip>
        )}
        <div>
          <FieldErrors errors={errors} />
        </div>
      </div>
    </div>
  );
};
