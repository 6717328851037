import { Currency, TrivialTooltip } from '@chiroup/components';
import {
  AppointmentInsuranceType,
  PatientTransaction,
  PatientTransactionItemType,
  Responsibilities,
  STRING_NUMBER_HASH,
  TransactionItemSubtypeEnum,
  createDecimal,
  subtotalAndTaxFromItems,
} from '@chiroup/core';
import Decimal from 'decimal.js';
import { useContext, useMemo } from 'react';
import { MeContext } from '../../../../../../contexts/me.context';
import useProductTaxes from '../../../../../../hooks/useProductTaxes';

type Props = {
  treatmentName?: string;
  insurances?: Partial<AppointmentInsuranceType>[];
  courtesyBilling?: boolean;
  superBill?: boolean;
  items?: PatientTransactionItemType[];
  editing?: boolean;
  setWorkingTransaction?: React.Dispatch<
    React.SetStateAction<PatientTransaction | null>
  >;
  workingTransaction?: PatientTransaction;
  locationId: number;
  disciplineId: number;
  insurancesToUse: {
    payorId: number;
    payorName: string;
    billingPriority: number;
    deductible: number;
    coPay: number;
    coInsurance: number;
    allowedAmount: number;
    serviceAllowedAmounts: STRING_NUMBER_HASH;
    maxPerVisit: number;
  }[];
  responsibilities:
    | Responsibilities
    | {
        payorResponsibilities: Record<
          number,
          {
            amount: string;
            name: string;
            payorId: number;
          }
        >;
        finalPatientResponsibility: number;
      };
  payorResponsibilityTotal: any;
  patientResponsibility: any;
  balanceAllocatedToPatient: Decimal;
};
const EncounterBillingView: React.FC<Props> = ({
  treatmentName = '',
  insurances = [],
  courtesyBilling,
  superBill,
  items = [],
  editing,
  setWorkingTransaction,
  workingTransaction,
  locationId,
  disciplineId,
  insurancesToUse,
  responsibilities,
  payorResponsibilityTotal,
  patientResponsibility,
  balanceAllocatedToPatient,
}) => {
  const { me } = useContext(MeContext);
  const { data: canItBeTaxed } = useProductTaxes();

  const services = items?.filter(
    (item) => item.subtype === TransactionItemSubtypeEnum.Service,
  );

  const patientServices = items?.filter(
    (item) => item.subtype === TransactionItemSubtypeEnum.PatientService,
  );

  const savedTax = items?.find(
    (item) => item.subtype === TransactionItemSubtypeEnum.Tax,
  )?.amount;

  const hasService = !!services?.length || !!patientServices?.length;

  const itemsToUse = useMemo(() => {
    if (!hasService) {
      return items;
    }
    return items?.filter(
      (item) =>
        item.subtype === TransactionItemSubtypeEnum.Service ||
        item.subtype === TransactionItemSubtypeEnum.PatientService,
    );
  }, [items, hasService]);

  const { subtotal, tax, total, balance } = useMemo(() => {
    const locationToUse = me?.selectedClinic?.locations?.find(
      (loc) => loc.ID === locationId,
    );
    const productTaxRate = locationToUse?.productTaxRate || 0;
    const serviceTaxRate = locationToUse?.serviceTaxRate || 0;
    return subtotalAndTaxFromItems({
      items:
        (hasService && items.length
          ? items.filter(
              (item) => item.subtype !== TransactionItemSubtypeEnum.Treatment,
            )
          : items) || [],
      productTaxRate: productTaxRate,
      serviceTaxRate: serviceTaxRate,
      makeRateZero: !itemsToUse?.some((item) => item?.salesTax === true),
    });
  }, [
    me?.selectedClinic?.locations,
    hasService,
    items,
    itemsToUse,
    locationId,
  ]);

  const applyTax = itemsToUse?.some((item) => item?.salesTax) === true;

  const showTaxButton =
    editing &&
    itemsToUse?.some(
      (item) =>
        (item.subtype === TransactionItemSubtypeEnum.Supplement &&
          item.description &&
          canItBeTaxed?.supplementsItemTaxHash[item?.description]) ||
        (item?.subtype === TransactionItemSubtypeEnum.Treatment &&
          item.description &&
          canItBeTaxed?.treatmentsItemTaxHash[disciplineId]),
    );

  const figureOutIfWeCanChangeSalesTax = (
    subtype: TransactionItemSubtypeEnum | null,
    item: PatientTransactionItemType,
  ) => {
    if (subtype === TransactionItemSubtypeEnum.Supplement) {
      return item?.description &&
        canItBeTaxed?.supplementsItemTaxHash[item?.description]
        ? !item?.salesTax
        : false;
    }
    if (subtype === TransactionItemSubtypeEnum.Treatment) {
      return disciplineId && canItBeTaxed?.treatmentsItemTaxHash[disciplineId]
        ? !item?.salesTax
        : false;
    }
    return false;
  };

  const changeSalesTax = () => {
    if (!setWorkingTransaction) return;
    setWorkingTransaction((prev) => {
      if (prev === null) return null;
      //itemtaxhash lets us know if the item can be taxed or not
      return {
        ...prev,
        items: prev?.items?.map((item) => {
          return {
            ...item,
            salesTax: figureOutIfWeCanChangeSalesTax(item.subtype, item),
          };
        }),
      };
    });
  };

  const maxPerVisitByPayorId = useMemo(() => {
    return insurancesToUse?.reduce((acc, cur) => {
      acc[cur.payorId] = cur.maxPerVisit;
      return acc;
    }, {} as STRING_NUMBER_HASH);
  }, [insurancesToUse]);

  return (
    <div className="border overflow-hidden rounded-md border-gray-300 border-spacing-4 p-4">
      {!!insurancesToUse?.length && !!services.length && (
        <div className="p-4 bg-gray-100 dark:bg-darkGray-500 rounded-md">
          {Object.entries(responsibilities.payorResponsibilities ?? {})?.map(
            ([key, value]) => (
              <div key={key} className="flex justify-between mb-2">
                <span>
                  {value.name}
                  {!!maxPerVisitByPayorId[value.payorId] && (
                    <TrivialTooltip
                      iconClassName="h-3 w-3 text-gray-400 inline-block ml-1 mr-1 align-text-top"
                      text={`This payor allows a maximum charge of $${createDecimal(
                        maxPerVisitByPayorId[value.payorId] ?? 0,
                      ).toFixed(
                        2,
                      )}, encompassing both patient and payor contributions.`}
                    />
                  )}
                  :
                </span>
                <Currency value={value.amount} />
              </div>
            ),
          )}
          <div className="flex justify-between mb-2 dark:bg-darkGray-500">
            <span>Patient Responsibility:</span>
            <Currency value={patientResponsibility} />
          </div>
          <div className="flex justify-between mb-2 dark:bg-darkGray-500">
            <span>Contractual Adjustment:</span>
            <Currency
              value={createDecimal(total)
                .minus(responsibilities.finalPatientResponsibility)
                .minus(balanceAllocatedToPatient.plus(payorResponsibilityTotal))
                .toFixed(2)}
              negativeClassName="text-red-500"
            />
          </div>
        </div>
      )}
      <div className="pb-4 font-bold">{treatmentName ?? ''}</div>
      <div className="flex justify-between mb-2">
        <span>Subtotal:</span>
        <Currency value={subtotal} />
      </div>
      <div className="flex justify-between mb-2">
        <span>Tax:</span>

        {balance > 0 && changeSalesTax && showTaxButton && (
          <div className="ml-4 sm:ml-64">
            <span
              onClick={() => changeSalesTax()}
              className="cursor-pointer text-primary-500 hover:text-primary-400 text-xs mr-8"
            >
              {applyTax ? 'Remove Tax' : 'Apply Tax'}
            </span>{' '}
          </div>
        )}
        <Currency value={editing ? tax : savedTax} />
      </div>
      <div className="flex justify-between mb-2">
        <span>Total:</span>
        <Currency value={total} />
      </div>
      {!!balance && (
        <div className="flex justify-between mb-2">
          <span>Balance:</span>
          <Currency value={balance} />
        </div>
      )}
    </div>
  );
};

export default EncounterBillingView;
