import dayjs from 'dayjs';

export const getAgeInYears = (birthday: string): number => {
  return dayjs().diff(birthday, 'year');
};

export const is18OrOver = (birthday?: string): boolean => {
  if (!birthday) return true;
  return getAgeInYears(birthday) >= 18;
};

export const isRealDate = (date: string): boolean => {
  return !!date && date.length === 10 && !!Date.parse(date);
};
