import { Any } from '../types';

export const hasProperty = (obj: Any, prop: string): boolean => {
  if (!obj) {
    return false;
  }

  try {
    const hasProperty = Object.prototype.hasOwnProperty.call(obj, prop);
    return hasProperty;
  } catch (err) {
    return false;
  }
};
