import { useContext, useState } from 'react';
import Button, { ButtonColors } from './common/Button';
import { MeContext } from '../contexts/me.context';
import userService from '../services/user.service';
import Modal from './common/Modal';

type Props = {
  isOpen: boolean;
};
const EHRTermsOfUseAcceptance: React.FC<Props> = ({ isOpen }) => {
  const { me, updateMe } = useContext(MeContext);
  const [clickedTermsOfUse, setClickedTermsOfUse] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const acceptTerms = async () => {
    setIsUpdating(true);
    if (me?.ID) {
      try {
        const res = await userService.saveUser({
          acceptEHRTermsOfUse: true,
        });
        setIsUpdating(false);
        updateMe('hasAcceptedEHRTerms', res.hasAcceptedEHRTerms);
      } catch (err) {
        setIsUpdating(false);
        console.error(err);
      }
    }
  };

  return (
    isOpen && (
      <Modal
        isOpen={isOpen}
        className="print:absolute print:top-0 print:left-0 print:border-none 
        print:shadow-none inline-block align-bottom bg-white dark:bg-darkGray-900 
        rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 
        sm:align-middle sm:p-6 print:hidden w-full sm:w-3/4 md:w-1/2"
      >
        <div className="flex flex-col items-center justify-center gap-4">
          <h1>
            Please review and accept the{' '}
            <a
              href="https://help.chiroup.com/security-privacy/chiroup-ehr-terms-of-use"
              target="_blank"
              rel="noreferrer"
              className="text-primary-600 underline"
              onClick={() => setClickedTermsOfUse(true)}
            >
              Terms of Use
            </a>{' '}
            to continue using this service. By clicking "Accept," you
            acknowledge that you have read and agree to abide by the terms
            outlined.
          </h1>
          <Button
            text="Accept"
            color={ButtonColors.primary}
            onClick={acceptTerms}
            loading={isUpdating}
            disabled={!clickedTermsOfUse}
          />
        </div>
      </Modal>
    )
  );
};

export default EHRTermsOfUseAcceptance;
