import {
  Checkbox,
  Input,
  InputMasked,
  Phone,
  Select,
} from '@chiroup/components';
import SectionContainer from '../../../../layout/SectionContainer';
import SectionHeader from '../../../../layout/SectionHeader';

import {
  COUNTRY_CODES,
  Discipline,
  DisciplineInsuranceBenefit,
  Insurance,
  UserRoles,
  ValueOf,
} from '@chiroup/core';
import { useContext, useMemo, useState } from 'react';
import Accordion from '../../../../../components/common/Accordion';
import { MeContext } from '../../../../../contexts/me.context';
import DisciplineBenefitForm from './DiscipleBenefitForm';
import PatientDisciplineSelectButton from './PatientDisciplineSelectButton';
import dayjs from 'dayjs';

type Props = {
  value: Partial<Insurance>;
  patchValue: (values: Partial<Insurance>) => void;
  onChange: (
    key: keyof Partial<Insurance>,
  ) => (val: ValueOf<Insurance>) => void;
  disciplines?: Discipline[];
  setEmailError: React.Dispatch<React.SetStateAction<boolean>>;
};
const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

const setNestedValue = (
  originalValue: Partial<Insurance>,
  keyPath: string,
  newValue: any,
): Partial<Insurance> => {
  const keys = keyPath.split('.');
  const obj: Partial<Insurance> = JSON.parse(JSON.stringify(originalValue));
  let current: Partial<Insurance> | any = obj;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (i === keys.length - 1) {
      current[key] = newValue;
    } else {
      current[key] = current[key] || {};
    }
    current = current[key];
  }

  return obj;
};

const PatientInsuranceBenefitForm: React.FC<Props> = ({
  value,
  patchValue,
  onChange,
  disciplines,
  setEmailError,
}) => {
  const { hasRole } = useContext(MeContext);
  const { me } = useContext(MeContext);
  const [openAccordions, setOpenAccordions] = useState<number[]>([]);
  const [showClaimInfo, setShowClaimInfo] = useState(false);
  const [showAdjustorInfo, setShowAdjustorInfo] = useState(false);
  const handleNestedChange = (parentKey: string, key: string, e: string) => {
    const keyPath = `${parentKey}.${key}`;
    if (key === 'email') {
      if (regex.test(e as string)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
    const updatedValue = setNestedValue(value, keyPath, e);
    patchValue(updatedValue);
  };

  const formPolicyEndDate = useMemo(() => {
    if (
      (typeof value.policyEndDate === 'string' &&
        value.policyEndDate.length === 13) ||
      typeof value.policyEndDate === 'number'
    ) {
      return dayjs(value.policyEndDate).format('MM/DD/YYYY');
    }
    return value?.policyEndDate;
  }, [value?.policyEndDate]);

  const toggleAccordion = (section: number) => {
    setOpenAccordions((prev) =>
      prev.includes(section)
        ? prev.filter((item) => item !== section)
        : [...prev, section],
    );
  };

  return (
    <div className="relative col-span-4 mt-4 border rounded-md ">
      <SectionContainer parentClassName="p-0 ">
        <SectionHeader
          title={
            <p className="text-base font-light text-gray-600">
              Insurance Benefits
            </p>
          }
          rightSide={
            hasRole([UserRoles.Admin, UserRoles.Biller, UserRoles.Staff]) && (
              <PatientDisciplineSelectButton
                value={value}
                patchValue={patchValue}
                disciplines={disciplines}
              />
            )
          }
        />
        <div className="p-4 space-y-1 z-100 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
          <InputMasked
            className="col-span-2"
            value={formPolicyEndDate}
            onChange={onChange('policyEndDate')}
            name="policyEndDate"
            label="Policy End Date"
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <InputMasked
            label="Policy Maximum"
            name="policyMaximum"
            placeholder="#,###.##"
            value={value?.policyMaximum}
            onChange={onChange('policyMaximum')}
            numericOptions={{
              decimalScale: 2,
              fixedDecimalScale: true,
            }}
          />
          <div className="col-span-2">
            <InputMasked
              label="Annual deductible"
              name="annualDeductible"
              placeholder="#,###.##"
              value={value?.annualDeductible}
              onChange={onChange('annualDeductible')}
              numericOptions={{
                decimalScale: 2,
                fixedDecimalScale: true,
              }}
            />
          </div>
          <div className="col-span-2">
            <InputMasked
              label="Remaining deductible"
              name="deductible"
              placeholder="#,###.##"
              value={value?.deductible}
              onChange={onChange('deductible')}
              numericOptions={{
                decimalScale: 2,
                fixedDecimalScale: true,
              }}
            />
          </div>
          <Checkbox
            label="Accept assignment?"
            value={value?.acceptAssignment}
            onChange={onChange('acceptAssignment')}
            className="col-span-1 sm:col-span-3"
          />
          <Checkbox
            label="Signature on file"
            value={value?.signatureOnFile}
            onChange={onChange('signatureOnFile')}
            className="col-span-1 sm:col-span-3"
          />
          <Checkbox
            label="Release of patient information"
            value={value?.releaseOfPatientInformation}
            onChange={onChange('releaseOfPatientInformation')}
            className="col-span-1 sm:col-span-3"
          />
          <div className="col-span-4 w-full">
            <Accordion
              title="Property & Casualty Insurance"
              open={showClaimInfo}
              onToggle={() => setShowClaimInfo((prev) => !prev)}
              hugSides
              border
            >
              <div className="grid grid-cols-4 w-full gap-2">
                <Select
                  label="Patient ID Type"
                  name="patientIdType"
                  value={value?.propertyAndCasualtyInsurance?.patientIdType}
                  onChange={(e) =>
                    handleNestedChange(
                      'propertyAndCasualtyInsurance',
                      'patientIdType',
                      e,
                    )
                  }
                  options={[
                    { text: 'None', value: 'none' },
                    { text: 'SSN', value: 'ssn' },
                    { text: 'Member ID', value: 'memberId' },
                  ]}
                  className="col-span-2"
                  limit={1}
                />
                <Input
                  label="Claim number"
                  name="claimNumber"
                  value={value?.propertyAndCasualtyInsurance?.claimNumber}
                  onChange={(e) =>
                    handleNestedChange(
                      'propertyAndCasualtyInsurance',
                      'claimNumber',
                      e,
                    )
                  }
                  className="col-span-2"
                />
                <Input
                  label="Contact name"
                  name="contactName"
                  value={value?.propertyAndCasualtyInsurance?.contactName}
                  onChange={(e) =>
                    handleNestedChange(
                      'propertyAndCasualtyInsurance',
                      'contactName',
                      e,
                    )
                  }
                  className="col-span-4"
                />
                <Phone
                  className="col-span-2"
                  name="claimContactPhone"
                  label="Phone number"
                  value={value?.propertyAndCasualtyInsurance?.claimContactPhone}
                  onChange={(e) =>
                    handleNestedChange(
                      'propertyAndCasualtyInsurance',
                      'claimContactPhone',
                      e,
                    )
                  }
                  countryCode={
                    COUNTRY_CODES[me.selectedClinic?.country || 'USA']
                  }
                />
                <InputMasked
                  name="contactPhoneExtension"
                  label="Extension"
                  value={value?.propertyAndCasualtyInsurance?.extension}
                  onChange={(e) =>
                    handleNestedChange(
                      'propertyAndCasualtyInsurance',
                      'extension',
                      e,
                    )
                  }
                  className="col-span-2"
                  patternFormat="######"
                  numericOptions={{ numeral: true }}
                />
                {value?.propertyAndCasualtyInsurance?.patientIdType ===
                  'ssn' && (
                  <Input
                    label="SSN"
                    name="ssn"
                    type="number"
                    maxLength={9}
                    value={value?.propertyAndCasualtyInsurance?.ssn}
                    onChange={(e) =>
                      handleNestedChange(
                        'propertyAndCasualtyInsurance',
                        'ssn',
                        e,
                      )
                    }
                    className="col-span-2 px-2"
                  />
                )}
                {value?.propertyAndCasualtyInsurance?.patientIdType ===
                  'memberId' && (
                  <Input
                    label="Member Id"
                    name="memberId"
                    value={value?.propertyAndCasualtyInsurance?.memberId}
                    onChange={(e) =>
                      handleNestedChange(
                        'propertyAndCasualtyInsurance',
                        'memberId',
                        e,
                      )
                    }
                    className="col-span-2 px-2"
                  />
                )}
              </div>
            </Accordion>
          </div>
          <div className="col-span-4 w-full">
            <Accordion
              title="Adjustor Information"
              open={showAdjustorInfo}
              onToggle={() => setShowAdjustorInfo((prev) => !prev)}
              hugSides
              border
              hugTop
            >
              <div className="grid grid-cols-4 w-full gap-2">
                <Input
                  label="Name"
                  name="adjustorName"
                  value={value?.adjustorInformation?.name}
                  onChange={(e) =>
                    handleNestedChange('adjustorInformation', 'name', e)
                  }
                  className="col-span-4 px-2"
                />
                <Phone
                  className="col-span-2 pl-2"
                  name="phone"
                  label="Phone number"
                  value={value.adjustorInformation?.phone}
                  onChange={(e) =>
                    handleNestedChange('adjustorInformation', 'phone', e)
                  }
                  countryCode={
                    COUNTRY_CODES[me.selectedClinic?.country || 'USA']
                  }
                />
                <InputMasked
                  className="col-span-2 pr-2"
                  label="Extension"
                  name="extension"
                  value={value.adjustorInformation?.extension}
                  onChange={(e) =>
                    handleNestedChange('adjustorInformation', 'extension', e)
                  }
                  patternFormat="######"
                  numericOptions={{ numeral: true }}
                />
                <div className="flex flex-col col-span-2 pl-2">
                  <Input
                    label="Email address"
                    name="email"
                    onChange={(e) =>
                      handleNestedChange('adjustorInformation', 'email', e)
                    }
                    value={value.adjustorInformation?.email}
                  />
                  {value?.adjustorInformation?.email &&
                    !regex.test(value?.adjustorInformation?.email || '') && (
                      <span className="mt-2 text-sm text-red-500">
                        Please enter valid email
                      </span>
                    )}
                </div>
              </div>
            </Accordion>
          </div>
        </div>

        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-4">
            {value.disciplineBenefits?.map(
              (discipline: DisciplineInsuranceBenefit, i: number) => (
                <Accordion
                  key={discipline.disciplineId}
                  title={
                    disciplines?.find((d) => d.ID === discipline.disciplineId)
                      ?.name || ''
                  }
                  onToggle={() => {
                    toggleAccordion(discipline.disciplineId);
                  }}
                  open={openAccordions.includes(discipline.disciplineId)}
                >
                  <DisciplineBenefitForm
                    value={value}
                    patchValue={patchValue}
                    index={i}
                  />
                </Accordion>
              ),
            )}
          </div>
        </div>
      </SectionContainer>
    </div>
  );
};

export default PatientInsuranceBenefitForm;
