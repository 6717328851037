import React from 'react';

type Props = {
  minMaxTime: {
    minTime: number;
    maxTime: number;
  };
  doesHourHaveAppt?: {
    [key in number]: boolean;
  };
};

const ScheduleHoursForModal: React.FC<Props> = ({
  minMaxTime: { minTime, maxTime },
  doesHourHaveAppt = {},
}) => {
  const hourRows = () => {
    // Convert the time from 1 to 1AM, etc.
    const time = (t: number) => {
      if (t === 0) return '12AM';
      if (t === 12) return '12PM';
      if (t < 12) return `${t}AM`;
      return `${t - 12}PM`;
    };

    const rows = [];

    for (let i = minTime; i < maxTime; i++) {
      const hasAppt = doesHourHaveAppt[i];
      rows.push(
        <div
          className={`${hasAppt ? 'h-[90px]' : 'h-[30px] relative'}`}
          key={i + time(i)}
        >
          <div className={'z-20 -ml-14 w-14 pr-2 print:absolute'}>
            {time(i)}
          </div>
          {i === minTime && <div className="mt-4" />}
          {!hasAppt && i !== minTime && i !== maxTime - 1 && (
            <div className="text-center z-20 print:mt-4 print:block absolute left-0 top-0 w-full">
              {`No appointments scheduled ${time(i)} - ${time(i + 1)}`}
            </div>
          )}
        </div>,
      );
    }

    return rows;
  };

  return (
    <div className="col-start-1 row-start-1 grid w-full text-right text-xs leading-5 text-gray-400">
      {hourRows()}
    </div>
  );
};

export default ScheduleHoursForModal;
