import React from 'react';
import { Tooltip } from 'react-tooltip';

const WorkerCompLabelBadge: React.FC<{
  height?: number;
  width?: number;
}> = ({ height = 28, width = 28 }) => {
  return (
    <>
      <div
        data-tooltip-content={`Workers Compensation`}
        data-tooltip-id="workers-compensation-badge-tooltip"
        style={{ display: 'inline-block', position: 'relative' }}
      >
        <svg
          width={height}
          height={width}
          viewBox="0 0 4.2438703 4.2333174"
          version="1.1"
          id="svg1"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs id="defs1">
            <linearGradient id="swatch6">
              <stop
                style={{ stopColor: '#000000', stopOpacity: 1 }}
                offset="0"
                id="stop6"
              />
            </linearGradient>
          </defs>
          <g id="layer1" transform="translate(-114.25136,-112.62227)">
            <g
              style={{ fill: 'none', stroke: '#000000', strokeOpacity: 1 }}
              id="g1"
              transform="matrix(0.26458093,0.0011263,-0.0011263,0.26458093,113.2082,111.55044)"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M 12,4.75 4.75001,8 c 0,0 -0.75,11.25 7.24999,11.25 C 20,19.25 19.25,8 19.25,8 Z"
                id="path1"
                style={{
                  stroke: 'rgb(147 204 104)',
                  strokeOpacity: 1,
                }}
              />
            </g>
            <text
              xmlSpace="preserve"
              style={{
                fontSize: '1.87066px',
                fill: '#000000',
                stroke: 'rgb(147 204 104)',
                strokeWidth: '0.19',
                strokeOpacity: 1,
              }}
              x="179.33478"
              y="74.40258"
              id="text1"
              transform="scale(0.64350562,1.553988)"
            >
              <tspan
                id="tspan1"
                style={{
                  stroke: 'rgb(147 204 104)',
                  strokeWidth: '0.19',
                  strokeOpacity: 1,
                }}
                x="179.33478"
                y="74.40258"
              >
                WC
              </tspan>
            </text>
          </g>
        </svg>
      </div>
      <Tooltip id="workers-compensation-badge-tooltip" className="text-xs" />
    </>
  );
};

export default WorkerCompLabelBadge;
