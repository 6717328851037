import { formatCurrency, Plan } from '@chiroup/core';
import React, { useMemo } from 'react';
import { ActionTypes } from './Billing';

type Props = {
  name: string;
  value: Plan;
  description: string;
  selectPlan: (tier: Plan, status: ActionTypes) => void;
  currentPlan?: string;
  standardPrice: number;
  subscriptionPlanPrice?: number;
  status?: string;
  disabled?: boolean;
  locationsHasChanged?: boolean;
  additionalLocations?: number;
  additionalLocationsPrice?: number;
  clinicCountry?: string;
  coupon?: {
    amount_off?: number;
    percent_off?: number;
    duration?: string;
    duration_in_months?: number;
    code?: string;
    discountEnd?: string;
    freeMonth?: boolean;
  };
  appliesToEverything?: boolean;
};

const BillingSectionMobile: React.FC<Props> = ({
  name,
  value,
  description,
  selectPlan,
  currentPlan,
  standardPrice,
  subscriptionPlanPrice,
  status,
  disabled,
  locationsHasChanged,
  additionalLocations,
  additionalLocationsPrice = 99,
  clinicCountry = 'US',
  coupon,
  appliesToEverything = false,
}) => {
  const regularPrice = useMemo(() => {
    const subTotal = standardPrice;
    const locationsTotal = 99 * (additionalLocations || 0);
    return formatCurrency(subTotal + locationsTotal);
  }, [standardPrice, additionalLocations]);

  const priceToShow = useMemo(() => {
    const subTotal = subscriptionPlanPrice || standardPrice;
    const locationsTotal =
      additionalLocationsPrice * (additionalLocations || 0);
    return formatCurrency(subTotal + locationsTotal);
  }, [
    subscriptionPlanPrice,
    additionalLocations,
    standardPrice,
    additionalLocationsPrice,
  ]);

  const discountInfo = useMemo(() => {
    const saveText =
      status === 'active' || status === 'trialing' ? 'Saving' : 'Save';
    const savingsText = coupon?.amount_off
      ? `${saveText} ${formatCurrency(coupon?.amount_off / 100)}`
      : coupon?.percent_off
        ? `${saveText} ${coupon?.percent_off}%`
        : '';
    const durationText =
      coupon?.duration === 'once'
        ? 'for your first month'
        : coupon?.discountEnd
          ? `until ${coupon?.discountEnd}`
          : coupon?.duration === 'repeating'
            ? `for ${coupon?.duration_in_months} months`
            : '';
    return coupon?.freeMonth && (status !== 'active' || currentPlan === 'trial')
      ? `First month free, then ${savingsText} ${durationText}`
      : `${savingsText} ${durationText}`;
  }, [coupon, status, currentPlan]);

  return (
    <section key={name} className="pt-12">
      <div className="px-4 mb-8">
        <h2 className="text-lg leading-6 font-medium text-gray-900">
          {name}
          {currentPlan === value && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-accent-100 text-accent-500 ml-4">
              Current plan
            </span>
          )}
        </h2>
        <p className="mt-4">
          <span>
            <span className="font-extrabold text-gray-900 dark:text-darkGray-50 text-4xl">
              {priceToShow}
            </span>
            <span className="text-base font-medium text-gray-500">
              /mo{' '}
              {clinicCountry === 'CAN' && (
                <span>CAD / Canadian localized pricing</span>
              )}
            </span>
          </span>
          {regularPrice !== priceToShow && (
            <div className="flex flex-col">
              <div className="text-3xl">
                <span className="text-gray-400 dark:text-darkGray-400 text-lg line-through">
                  {regularPrice}
                </span>
                <span className="text-xs text-gray-300 dark:text-darkGray-500 line-through">
                  /mo{' '}
                  {clinicCountry === 'CAN' && (
                    <span>CAD / Canadian localized pricing</span>
                  )}
                </span>
              </div>
              {regularPrice !== priceToShow && appliesToEverything && (
                <span className="text-gray-600 text-sm mt-4 px-4 py-2 rounded-md bg-primary-50 border-primary-500 border-2">
                  {discountInfo}
                </span>
              )}
            </div>
          )}
        </p>
        <p className="mt-4 text-sm text-gray-500">{description}</p>
        <button
          onClick={() =>
            selectPlan(
              value,
              ((status === 'active' ||
                status === 'past_due' ||
                status === 'trialing') &&
                currentPlan !== 'trial') ||
                (locationsHasChanged &&
                  (status === 'active' ||
                    status === 'past_due' ||
                    status === 'trialing') &&
                  currentPlan !== 'trial')
                ? 'update'
                : 'subscribe',
            )
          }
          className={[
            'mt-6 block w-full border border-transparent rounded-md shadow py-2 text-sm font-semibold text-white text-center',
            !locationsHasChanged &&
            (((status === 'past_due' ||
              status === 'trialing' ||
              status === 'active') &&
              value === currentPlan) ||
              disabled)
              ? 'text-gray-500 bg-gray-300 cursor-not-allowed'
              : 'bg-primary-500 hover:bg-primary-400 cursor-pointer',
          ].join(' ')}
          disabled={
            !locationsHasChanged &&
            (((status === 'active' ||
              status === 'trialing' ||
              status === 'past_due') &&
              value === currentPlan) ||
              disabled)
          }
        >
          {(status === 'active' ||
            status === 'past_due' ||
            status === 'trialing') &&
          value === currentPlan
            ? locationsHasChanged
              ? 'Update locations'
              : 'Current plan'
            : `Buy ${name}`}
        </button>
      </div>
    </section>
  );
};

export default BillingSectionMobile;
