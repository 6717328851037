import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

export const useQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const removeQueryParams = (params: string[]) => {
    const queryParams = qs.parse(location.search);
    params.forEach((p) => {
      delete queryParams[p];
    });

    return {
      navigate: () => {
        navigate({
          search: qs.stringify(queryParams),
        });
      },
      link: `?${qs.stringify(queryParams)}`,
    };
  };

  const addQueryParams = (params: { [key: string]: string | number }) => {
    const queryParams = { ...qs.parse(location.search), ...params };
    return {
      navigate: () => {
        navigate({
          search: qs.stringify(queryParams),
        });
      },
      link: `?${qs.stringify(queryParams)}`,
    };
  };

  return {
    removeQueryParams,
    addQueryParams,
  };
};
