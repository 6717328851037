import { classNames } from '@chiroup/core';
import React, { useContext, useMemo } from 'react';
import { AppointmentsContext } from '../../contexts/appointments.context';

type Props = {
  top: number;
  minTime: number;
  maxTime: number;
};

const CurrentTimeLine: React.FC<Props> = ({ top, minTime, maxTime }) => {
  const { zoom } = useContext(AppointmentsContext);

  const pixelsPerHour = useMemo(() => {
    return 60 * zoom;
  }, [zoom]);

  const offset = useMemo(() => {
    return minTime * pixelsPerHour;
  }, [minTime, pixelsPerHour]);

  const realTop = useMemo(() => {
    return top - offset;
  }, [top, offset]);

  const maxTimeInPixels = useMemo(() => {
    return maxTime * pixelsPerHour - offset;
  }, [maxTime, offset, pixelsPerHour]);

  const minTimeInPixels = useMemo(() => {
    return minTime * pixelsPerHour - offset;
  }, [minTime, offset, pixelsPerHour]);

  return (
    <div
      className={classNames(
        'absolute left-0 w-full bg-gray-300 z-10 pointer-events-none ',
        realTop > maxTimeInPixels || realTop < minTimeInPixels ? 'hidden' : '',
      )}
      style={{
        height: 2,
        top: realTop,
      }}
    />
  );
};

export default CurrentTimeLine;
