import { Link } from 'react-router-dom';
import { Button, ButtonColors } from './Button';

type Props = {
  text: string;
  link?: string;
  onClick?: (e: any) => void;
  color?: ButtonColors;
  tooltip?: string;
  loading?: boolean;
  disabled?: boolean;
};

export const NewButton: React.FC<Props> = ({
  text,
  link,
  onClick,
  color,
  tooltip,
  loading,
  disabled,
}) => {
  const useThisOnClick = onClick
    ? onClick
    : (_: any) => {
        console.warn('NewButton has no onClick');
      };

  return link ? (
    <Link to={link}>
      <Button
        text={text}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="currentColor"
            className="w-7 h-7"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        }
        tooltip={tooltip}
        color={color ?? ButtonColors.primary}
        disabled={disabled}
        loading={loading}
      />
    </Link>
  ) : (
    <Button
      text={text}
      onClick={useThisOnClick}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2.5}
          stroke="currentColor"
          className="w-7 h-7"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      }
      tooltip={tooltip}
      color={color ?? ButtonColors.primary}
      disabled={disabled}
      loading={loading}
    />
  );
};
