export const clog = (
  message: any,
  {
    warn,
    error,
  }: {
    warn?: boolean;
    error?: boolean;
  } = {},
) => {
  if (warn) {
    console.warn(message);
  } else if (error) {
    console.error(message);
  } else {
    console.log(message);
  }
};
